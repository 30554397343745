import React from "react";

const topArr = [
  {
    title: "Messages sent",
    value: 43,
    desc: "+12% increase from last week",
  },
  {
    title: "Interaction",
    value: "2,182",
    desc: "+2% increase from last week",
  },
];

const CampaignManagerSMSAnalytics = () => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-4">
        {topArr.map((item: any, index: number) => (
          <div
            className="text-sm p-3 border border-gray-200 rounded space-y-3"
            key={index}
          >
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{item.title}</p>
              <p className="text-4xl font-semibold">{item.value}</p>
            </div>
            <p className="text-green-600 text-xs">{item.desc}</p>
          </div>
        ))}
      </div>
      <div className="space-y-2">
        <p className="text-[#171717] font-semibold text-sm">Recent messages</p>
        <div className="grid grid-cols-3 gap-4" >
          {[1, 2].map((index:number) => (
            <div className="border border-gray-100 shadow-sm p-2.5 rounded-md flex flex-col gap-2" key={index} >
              <div className="font-semibold text-gray-700">
                <p className="text-[13px]">+2348012345678, and 12 others</p>
              </div>
              <div>
                <p className="text-xs text-gray-700 font-medium leading-[18px]">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Perferendis similique eius delectus non laudantium. Iure,
                  debitis nulla! Architecto, dolores quis. Dolore consectetur
                  nam, officia doloribus reiciendis alias. Corrupti voluptates
                  magni...
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignManagerSMSAnalytics;
