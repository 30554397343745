import { useEffect, useState } from "react";
import BackButton from "../../../components/Auth/Forms/BackButton";
import Button from "../../../components/Auth/Forms/Button";
import Input from "../../../components/Auth/Forms/Input";
import OTPInput from "../../../components/Auth/Forms/OTPInput";
import SelectInput, {
  ISelectFields,
} from "../../../components/Auth/Forms/SelectInput";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import { Link, useNavigate } from "react-router-dom";
import UserPool from "../../../setup/userpool/index";
import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import Alert from "../../../components/Auth/Forms/Alert/indext";
import { StoreModel, UserProfileModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../../redux/User";
import { ImSpinner2 } from "react-icons/im";
import { accountSetup } from "../../../api/Auth";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
// const phoneRegExp1 = /(^[0]\d{10}$)|(^[\+]?[234]\d{12}$)/;

const initialValues = {
  userName: "",
  fullName: "",
  storeName: "",
  email: "",
  password: "",
  referral_code: "",
};

const signUpSchema = Yup.object().shape({
  userName: Yup.string().max(40).required("Username is required"),
  fullName: Yup.string().max(40).required("Fullname is required"),
  referral_code: Yup.string(),
  storeName: Yup.string().min(5, 'Must be more than 5 characters').max(20, 'Must be less than 20 characters').required("Storename is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  password: Yup.string()
    .min(8, "Minimum 8 letters")
    .max(20, "Maximum 20 letters")
    .required("Password is required"),
});

const initialValues1 = {
  phoneNumber: "",
  // storeName: "",
  address: "",
};

const completeProfile1Schema = Yup.object().shape({
  phoneNumber: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  // storeName: Yup.string().max(40).required("Store name is required"),
  address: Yup.string().max(100).required("Address is required"),
});

const initialValues2 = {
  country: "",
  totalEmployee: "",
  currency: "",
};

const completeProfile2Schema = Yup.object().shape({
  country: Yup.string(),
  totalEmployee: Yup.number(),
  currency: Yup.string().required("Currency is required"),
});

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const general: any = useAppSelector((state) => state.user);
  const store: StoreModel = useAppSelector((state) => state.user.store);

  useEffect(() => {
    if (localStorage.getItem("completeRegistration") === "1") {
      setStep(4);
      // console.log(store);
    }
  }, []);

  const option1: ISelectFields[] = [
    { value: "Uk", display: "Uk" },
    { value: "Canada", display: "Canada" },
    { value: "Nigeria", display: "Nigeria" },
  ];
  const option2: ISelectFields[] = [
    { value: "USD", display: "USD" },
    { value: "NGN", display: "NGN" },
    { value: "GBP", display: "GBP" },
  ];
  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  const handleChange = (value: string) => {
    setCode(value);
  };

  const confirmSignUp = (e: any) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const cognitoUserPool = () => UserPool;
    const userPool = cognitoUserPool();
    const userData = {
      Username: name,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          reject(err);
          setError(err.message);
          setIsLoading(false);
        } else {
          resolve(result);
          if (result) {
            const authDetails = new AuthenticationDetails({
              Username: userEmail,
              Password: userPassword,
            });
            cognitoUser.authenticateUser(authDetails, {
              onSuccess: async (data) => {
                // console.log(data);
                // console.log("signed In");
                resolve(data);
                navigate("/login")
                const jwt = data.getIdToken().getJwtToken();
                // console.log("jwt", jwt);

               
                const requestOptions = {
                  method: "POST",
                  headers: {
                    Authorization: jwt,
                  },
                  body: null,
                };

                try {
                  const response = await accountSetup(requestOptions);
                  if(response){
                    const accountId = response.Platform_Account;
                    const userRole = response.User_Role;
                    const platformSlug = response.Platform_Slug_Url;
                    var attributeList = [];
                    var updatedProfileAttribute = {
                      Name: "profile",
                      Value: accountId,
                    };
                    var updatedPlatformSlug = {
                      Name: "given_name",
                      Value: platformSlug,
                    };
                    var updatedUserRoleAttribute = {
                      Name: "nickname",
                      Value: userRole,
                    };
                    var updateProfile = new CognitoUserAttribute(
                      updatedProfileAttribute
                    );
                    var updatePlatformSlug = new CognitoUserAttribute(
                      updatedPlatformSlug
                    );
                    var updateUserRole = new CognitoUserAttribute(
                      updatedUserRoleAttribute
                    );
                    attributeList.push(updateProfile);
                    attributeList.push(updateUserRole);
                    attributeList.push(updatePlatformSlug);

                    cognitoUser.updateAttributes(
                      attributeList,
                      function (err, result) {
                        if (err) {
                          alert(err.message || JSON.stringify(err));
                          return;
                        }
                        // console.log("call result: " + result);
                      }
                    );
                    localStorage.setItem("customerEchoNewUser", "1");
                    const user = UserPool.getCurrentUser();
                    user?.signOut();
                    // console.log("User Signed Out");
                    setIsLoading(false);
                    navigate("/login");
                  }
                } catch (err) {
                  console.log(err);
                }

              },
              onFailure: (err) => {
                console.log("err fetch", err);
              },
            });
          }
        }
      });
    });
  };

  const resendCode = () => {
    // console.log("1234")
    const cognitoUserPool = () => UserPool;
    const userPool = cognitoUserPool();
    const userData = {
      Username: name,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        // console.log(err)
        alert(err.message || JSON.stringify(err));
        return;
      }
      // console.log("call result: " + result);
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      const email = values.email.toLowerCase();
      const customUserName = values.userName.trim();
      const password = values.password;
      const fullName = values.fullName.trim();
      const referral = values.referral_code.toUpperCase().trim();
      const customStoreName = values.storeName.trim();

      const user_fullName = new CognitoUserAttribute({
        Name: "name",
        Value: fullName,
      });

      const preferred_username = new CognitoUserAttribute({
        Name: "preferred_username",
        Value: customUserName,
      });

      const address = new CognitoUserAttribute({
        Name: "address",
        Value: "address",
      });

      const phoneNumber = new CognitoUserAttribute({
        Name: "phone_number",
        Value: "+11111111111",
      });

      const storeName = new CognitoUserAttribute({
        Name: "given_name",
        Value: customStoreName.replace(/\s+/g, '-').toLowerCase(),
      });

      const customerID = new CognitoUserAttribute({
        Name: "zoneinfo",
        Value: "normalUser",
      });

      var referralCode = new CognitoUserAttribute({
        Name: "picture",
        Value: referral,
      });


      const country = new CognitoUserAttribute({
        Name: "locale",
        Value: "N/A",
      });

      const currency = new CognitoUserAttribute({
        Name: "custom:currrency",
        Value: "N/A",
      });

      let attributeList: CognitoUserAttribute[] = [];
      attributeList.push(user_fullName);
      attributeList.push(preferred_username);
      attributeList.push(address);
      attributeList.push(referralCode);
      attributeList.push(phoneNumber);
      attributeList.push(storeName);
      attributeList.push(customerID);

      UserPool.signUp(email, password, attributeList, [], (err, data) => {
        if (err) {
          console.log("error", err);
          setSubmitting(false);
          setError(err.message);
        }
        if (data) {
          // console.log("SignUp Success", data);
          setSubmitting(false);
          setUserEmail(values.email);
          setUserPassword(values.password);
          setStep(2);
          setName(formik.values.email);
        }
      });
    },
  });

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: completeProfile1Schema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err: any, session: any) => {
            if (err) {
              reject();
            } else {
              const attributes: any = await new Promise((resolve, reject) => {
                user.getUserAttributes((err: any, attributes: any) => {
                  if (err) {
                    reject(err);
                    setSubmitting(false);
                    setError(err.message);
                  } else {
                    const results: any = {};
                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }
                    resolve(results);
                  }
                });
              });

              resolve({
                user,
                ...session,
                ...attributes,
              });
            }
          });
        } else {
          reject();
        }
      }).then(({ user }: any) => {
        const attributes = [
          new CognitoUserAttribute({ Name: "address", Value: values.address.trim() }),
          new CognitoUserAttribute({
            Name: "phone_number",
            Value: values.phoneNumber,
          }),
          // new CognitoUserAttribute({
          //   Name: "given_name",
          //   Value: values.storeName,
          // }),
        ];

        const storeData: StoreModel = {
          name: store.name,
          address: values.address,
          country: "",
          currency: "",
          phoneNumber: values.phoneNumber,
          employeeNumber: "",
        };

        user.updateAttributes(attributes, (err: any, result: any) => {
          if (err) {
            console.error(err);
            setSubmitting(false);
            setError(err.message);
          } else {
            // console.log(result);
            setSubmitting(false);
            setStep(5);
          }

          dispatch(SET_USER({ ...general, store: storeData }));
        });
      });
    },
  });

  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: completeProfile2Schema,
    validateOnBlur: true,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err: any, session: any) => {
            if (err) {
              reject();
              setSubmitting(false);
              setError(err.message);
            } else {
              const attributes: any = await new Promise((resolve, reject) => {
                user.getUserAttributes((err: any, attributes: any) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results: any = {};

                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }

                    resolve(results);
                  }
                });
              });

              resolve({
                user,
                ...session,
                ...attributes,
              });
            }
          });
        } else {
          reject();
        }
      }).then(({ user }: any) => {
        const attributes = [
          new CognitoUserAttribute({
            Name: "locale",
            Value: values.country,
          }),
          new CognitoUserAttribute({
            Name: "custom:employee",
            Value: values.totalEmployee.toString(),
          }),
          new CognitoUserAttribute({
            Name: "custom:currency",
            Value: values.currency,
          }),
        ];

        const storeData: StoreModel = {
          name: store.name,
          address: store.address,
          country: values.country,
          currency: values.currency,
          phoneNumber: store.phoneNumber,
          employeeNumber: values.totalEmployee.toString(),
        };

        user.updateAttributes(attributes, (err: any, result: any) => {
          if (err) {
            console.error(err);
            setSubmitting(false);
            setError(err.message);
          } else {
            // console.log(result);
            setSubmitting(false);
            if (localStorage.getItem("customerEchoNewUser") === "1") {
              navigate("/smart-feedback/customer-review");
            } else {
              navigate("/smart-feedback/customer-review");
            }
          }

          dispatch(SET_USER({ ...general, store: storeData }));
        });
      });
    },
  });

  return step === 1 ? (
    <div className="w-full h-full overflow-y-auto">
      <div className="flex flex-col justify-between w-full h-full px-4 pt-4 pb-12 " >
        <div className="flex items-center justify-between w-full" >
          <div className="">
            <Link to="https://www.cusecho.com/" target="_blank" ><img src="media/image/cusecho-onboarding-logo.png" alt="" className="w-[130px]" /></Link>
          </div>
          <div className="">
            <button className="text-sm py-2 px-5 whitespace-nowrap font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]" onClick={() => navigate("/login")}>Log In</button>
          </div>
        </div>
        <div className="flex items-center justify-center flex-1 h-full mt-3" >
          <div className="w-full max-w-[500px] mx-auto" >
            <div className="">
              <h3 className="font-semibold text-xl text-[#263C6B] mt-2" >
                Sign up
              </h3>
            </div>
            <div className="mt-4">
              {error && <Alert type="error" message={error} />}
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div className="w-full">
                    <Input
                      formik={formik}
                      name="fullName"
                      label="Full Name*"
                      type="text"
                      placeHolder="Enter your first name"
                    />
                    {formik.errors.fullName && (
                      <InputMessage message={formik.errors.fullName} />
                    )}
                  </div>
                  <div className="w-full">
                    <Input
                      formik={formik}
                      name="userName"
                      label="Username*"
                      type="text"
                      placeHolder="Enter your prefered username"
                    />
                    {formik.errors.userName && (
                      <InputMessage message={formik.errors.userName} />
                    )}
                  </div>
                </div>
                <div className="w-full mt-4">
                  <Input
                    formik={formik}
                    name="storeName"
                    label="Storename*"
                    type="text"
                    placeHolder="Enter your storeName"
                  />
                  {formik.errors.storeName && (
                    <InputMessage message={formik.errors.storeName} />
                  )}
                </div>

                <div className="w-full mt-4">
                  <Input
                    formik={formik}
                    name="email"
                    label="Email*"
                    type="text"
                    placeHolder="Enter your email"
                  />
                  {formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )}
                </div>
                <div className="w-full mt-4">
                  <Input
                    formik={formik}
                    name="referral_code"
                    label="Referral (Optional)"
                    type="text"
                    placeHolder="Enter your referral code"
                  />
                  {formik.errors.referral_code && (
                    <InputMessage message={formik.errors.referral_code} />
                  )}
                </div>
                <div className="w-full mt-4">
                  <div className="relative">
                    <div className="relative">
                      <Input
                        formik={formik}
                        name="password"
                        label="Password*"
                        type={passwordIsVisible ? "text" : "password"}
                        placeHolder="Enter your password"
                      />
                      <button
                        type="button"
                        onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                        className="absolute outline-none text-[#263C6B] right-4 inset-y-7"
                      >
                        {passwordIsVisible ? (
                          <AiOutlineEye size={25} />
                        ) : (
                          <AiOutlineEyeInvisible size={25} />
                        )}
                      </button>
                    </div>
                  </div>
                  {formik.errors.password && (
                    <InputMessage message={formik.errors.password} />
                  )}
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Your Password Should Contain
                  </p>
                  <ul className="grid grid-cols-2 ml-4 text-xs text-gray-500 list-disc">
                    <div className="mt-2">
                      <li>At least 8 characters</li>
                      <li>An Uppercase Letter</li>
                    </div>
                    <div className="mt-2">
                      <li>A Number</li>
                      <li>A special Character e.g !,.,-,?</li>
                    </div>
                  </ul>
                </div>
                <div className="w-full mt-4">
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="bg-[#263C6B] w-full py-2 text-sm font-semibold text-center text-white outline-none rounded-md"
                  >
                    {formik.isSubmitting ? (<ImSpinner2 size={28} className="mx-auto animate-spin" />) : "Sign Up"}
                  </button>
                  {/* <Button
              type="submit"
              value="Sign Up"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            /> */}
                </div>
              </form>
            </div>
            <p className="hidden mt-2 text-base font-normal text-center text-gray-800">
              Already have an account?
              <Link to="/login" className="ml-1 font-semibold text-gray-800" >
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : step === 2 ? (
    <div className="flex items-center justify-center w-full h-screen px-6 py-12">
      <div className="max-w-[500px] mx-auto max-h-full my-auto">
        <div className="absolute left-5 top-14 md:left-5 md:top-10 lg:left-8">
          <BackButton onClick={() => setStep(1)} />
        </div>
        <div className="text-center">
          <h3 className="font-semibold text-2xl text-[#263C6B]">
            Verify your email address
          </h3>
          <p className="text-sm text-gray-700">
            Enter the 6 digit verification code sent to your email address.
          </p>
        </div>
        <div className="mt-4">
          {error && <Alert type="error" message={error} />}
          <form onSubmit={confirmSignUp}>
            <div className="w-full px-3 mt-4 md:px-5">
              <OTPInput label="Enter code" onChange={handleChange} />
            </div>
            <div className="flex justify-end">
            <button type="button" className="px-3 py-1 mt-2 text-sm font-semibold text-right text-red-700 cursor-pointer md:px-5" onClick={() => resendCode()} >Resend code</button>
            </div>
            <div className="w-full mt-4">
              <button
                type="submit"
                disabled={isLoading}
                className="bg-[#263C6B] w-full py-2 text-lg font-semibold text-center text-white outline-none rounded-md"
              >
                {isLoading ? (<ImSpinner2 size={28} className="mx-auto animate-spin" />) : "Verify"}
              </button>
              {/* <Button
                type="submit"
                value="Verify"
                disabled={formik.isSubmitting}
                isLoading={isLoading}
              /> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : step === 3 ? (
    null
  ) : step === 4 ? (
    <div className="flex items-center justify-center w-full h-screen px-6 py-12">
      <div className="w-[500px] max-w-[500px] mx-auto " >
        <div className="flex items-center justify-between mb-12">
          <img src="/media/image/cusecho-onboarding-logo.png" alt="logo" className="w-[130px]" />
          <button className="text-base text-gray-400" onClick={() => setStep(5)}>
            {"Skip >"}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-8 mt-2 text-sm">
          <p className="text-center border-t-2 border-[#263C6B] text-[#263C6B]">
            Store Info
          </p>
          <p className="text-center text-gray-400 border-t">Contact Info</p>
        </div>
        <div className="mt-2">
          <h3 className="font-bold text-xl text-[#263C6B]">
            Complete Your Profile
          </h3>
          <p className="text-base font-normal text-[#8692A6]">Welcome Back</p>
        </div>
        <div className="mt-4">
          {error && <Alert type="error" message={error} />}
          <form onSubmit={formik1.handleSubmit}>
            <div className="w-full">
              <Input
                formik={formik1}
                name="phoneNumber"
                label="Phone number"
                type="text"
                placeHolder="+XXXXXXXXXXX"
              />
              {formik1.values.phoneNumber && formik1.errors.phoneNumber && (
                <InputMessage message={formik1.errors.phoneNumber} />
              )}
            </div>
            <div className="w-full mt-4">
              <Input
                formik={formik1}
                name="address"
                label="Your address"
                type="text"
                placeHolder="Please enter address"
              />
              {formik1.values.address && formik1.errors.address && (
                <InputMessage message={formik1.errors.address} />
              )}
            </div>
            <div className="w-full mt-12">
              <Button
                type="submit"
                value="Save and Continue"
                changeBgColor={true}
                disabled={formik1.isSubmitting}
                isLoading={formik1.isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-screen px-6 py-12">
      <div className="w-[500px] max-w-[500px] mx-auto ">
        <div className="flex items-center justify-between mb-12">
          <img src="/media/image/logo.png" alt="logo" className="h-[40px]" />
          <button
            className="text-base text-gray-400"
            onClick={() => {
              if (localStorage.getItem("customerEchoNewUser") === "1") {
                navigate("/smart-feedback/customer-satisfaction");
              } else {
                navigate("/smart-feedback/customer-review");
              }
            }}
          >
            {"Skip >"}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-8 mt-2 text-sm">
          <p className="text-center text-gray-400 border-t">Store Info</p>
          <p className="text-center border-t-2 border-[#263C6B] text-[#263C6B]">
            Contact Info
          </p>
        </div>
        <div className="mt-2">
          <h3 className="font-bold text-xl text-[#263C6B]">
            Complete Your Profile
          </h3>
          <p className="text-base font-normal text-[#8692A6]">Welcome Back</p>
        </div>
        <div className="mt-4">
          {error && <Alert type="error" message={error} />}
          <form onSubmit={formik2.handleSubmit}>
            <div className="w-full">
              <SelectInput
                formik={formik2}
                name="country"
                label="Country of residence"
                placeHolder="Please select"
                options={option1}
              />
              {formik2.values.country && formik2.errors.country && (
                <InputMessage message={formik2.errors.country} />
              )}
            </div>
            <div className="w-full mt-4">
              <Input
                formik={formik2}
                name="totalEmployee"
                label="Total Number Of Employees"
                type="number"
                placeHolder="Please enter Number"
              />
              {formik2.values.totalEmployee && formik2.errors.totalEmployee && (
                <InputMessage message={formik2.errors.totalEmployee} />
              )}
            </div>
            <div className="w-full mt-4">
              <SelectInput
                formik={formik2}
                name="currency"
                label="Currency"
                placeHolder="Please select"
                options={option2}
              />
              {formik2.values.currency && formik2.errors.currency && (
                <InputMessage message={formik2.errors.currency} />
              )}
            </div>
            <div className="w-full mt-12">
              <Button
                type="submit"
                value="Save and Continue"
                changeBgColor={true}
                disabled={formik2.isSubmitting}
                isLoading={formik2.isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
