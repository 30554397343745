import SatisfactionTracker from "./_components/SatisfactionTracker";
import CommentTracker from "./_components/CommentTracker";
import KPITracker from "./_components/KPITracker";
import BPTComponent from "./_components/BPTComponent";
import NewBPTComponent from "./_components/NewBPTComponent";

const CustomerSatisfactionPageNew = () => {
  return (
    <>
      <div className="flex flex-col gap-7">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 h-[250px]">
          <SatisfactionTracker />
          <CommentTracker />
          <KPITracker />
        </div>
        <NewBPTComponent />
      </div>
    </>
  );
};

export default CustomerSatisfactionPageNew;
