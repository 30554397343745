import { useState } from "react";
import { BiChevronRight, BiComment, BiSearch } from "react-icons/bi";
import { BsThreeDotsVertical, BsTrash3 } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { APP_PERMISSIONS, classNames } from "../../../../utils";
import { FaRegEdit } from "react-icons/fa";

const SingleUserAccessPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });

  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="flex bg-white rounded-md h-[85vh] space-y-4 flex-col" >
        <div className="flex items-center space-x-0.5 text-sm text-gray-400 font-medium">
          <button onClick={() => navigate(-1)}>Users</button>
          <span>
            <BiChevronRight size={14} />
          </span>
          <span className="text-black">James Okonkwo</span>
        </div>
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-semibold text-black">James Okonkwo</h4>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => {}}
              className="sm:px-4 sm:py-1.5 text-xs font-medium sm:border rounded-md text-primary border-primary"
            >
              <FaRegEdit size={15} className="inline-flex mb-1 mr-1" />
              <span className="hidden sm:inline">Edit</span>
            </button>
            <button
              onClick={() => {}}
              className="sm:px-4 sm:py-1.5 text-xs font-medium sm:border rounded-md text-primary border-primary"
            >
              <BsTrash3 size={15} className="inline-flex mb-1 mr-1" />
              <span className="hidden sm:inline">Delete</span>
            </button>
          </div>
        </div>
        <div className="px-3 py-3 space-y-2 border rounded-md border-neutral-200">
          <h4 className="text-base font-semibold text-black">Summary</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <h5 className="text-sm font-semibold text-black">E-mail</h5>
              <p className="text-[13px] font-normal text-[#303030]">
                jamesokonkwo@gmail.com
              </p>
            </div>
            <div>
              <h5 className="text-sm font-semibold text-black">
                Phone number
              </h5>
              <p className="text-[13px] font-normal text-[#303030]">
                0901 234 5678
              </p>
            </div>
            <div>
              <h5 className="text-sm font-semibold text-black">
                Date created
              </h5>
              <p className="text-[13px] font-normal text-[#303030]">
                12th January 2022
              </p>
            </div>
            <div>
              <h5 className="text-sm font-semibold text-black">
                Last active
              </h5>
              <p className="text-[13px] font-normal text-[#303030]">
                17 minutes ago
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-end max-w-[18rem] mb-6 border-b border-neutral-300">
            {[1, 2].map((item: number, index: number) => (
              <button
                key={item}
                onClick={() => setActiveTab(index)}
                className={classNames(
                  "py-1.5 px-4 border-b-2 text-sm font-semibold",
                  activeTab === index
                    ? "border-primary text-black"
                    : "border-transparent text-neutral-500"
                )}
              >
                {item === 1 ? "Permissions (12)" : "Groups (2)"}
              </button>
            ))}
          </div>
          {activeTab === 0 && (
            <div className="relative flex-1 h-full space-y-6">
              <div>
                <h4 className="mb-2 text-sm font-semibold text-black">
                  Mobile application permissions
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                  {APP_PERMISSIONS.MOBILE.map((item: string, index: number) => (
                    <div key={index}>
                      <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                        <input
                          type="checkbox"
                          checked
                          className="w-3 h-3 border border-black rounded accent-primary"
                        />
                        <span className="whitespace-nowrap">{item}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="mb-2 text-sm font-semibold text-black">
                  Feedback permissions
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                  {APP_PERMISSIONS.FEEDBACK.map(
                    (item: string, index: number) => (
                      <div key={index}>
                        <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                          <input
                            type="checkbox"
                            checked
                            className="w-3 h-3 border border-black rounded accent-primary"
                          />
                          <span className="whitespace-nowrap" >{item}</span>
                        </label>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div>
                <h4 className="mb-2 text-sm font-semibold text-black">
                  Customer reward permissions
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                  {APP_PERMISSIONS.REWARD.map((item: string, index: number) => (
                    <div key={index}>
                      <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                        <input
                          type="checkbox"
                          checked
                          className="w-3 h-3 border border-black rounded accent-primary"
                        />
                        <span className="whitespace-nowrap" >{item}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="mb-2 text-sm font-semibold text-black">
                  Message permissions
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                  {APP_PERMISSIONS.MESSAGE.map(
                    (item: string, index: number) => (
                      <div key={index}>
                        <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                          <input
                            type="checkbox"
                            checked
                            className="w-3 h-3 border border-black rounded accent-primary"
                          />
                          <span className="whitespace-nowrap" >{item}</span>
                        </label>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="relative flex-1 h-full overflow-x-hidden overflow-y-auto">
              <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
                <thead className="text-gray-800 capitalize bg-gray-50">
                  <tr className="text-xs text-left capitalize">
                    <th scope="col" className="py-3 text-center sm:px-6">
                      S/N
                    </th>
                    <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                      Group Name
                    </th>
                    <th scope="col" className="px-2 py-3 sm:px-6">
                      Users
                    </th>
                    <th scope="col" className="px-2 py-3 sm:px-6">
                      Permissions
                    </th>
                    <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                      Date Created
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-xs">
                  {[1, 2].map((review: any, index: number) => (
                    <tr className={classNames("border-b")} key={index}>
                      <td scope="row" className="px-2 py-2 sm:px-6">
                        <div className="">
                          <p className="font-medium text-center whitespace-nowrap">
                            {review}
                          </p>
                        </div>
                      </td>
                      <td className="hidden px-6 py-2 border-x sm:table-cell">
                        <p className="text-xs">{"Marketing-team"}</p>
                      </td>
                      <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                        <div className="text-xs cursor-pointer">{"15"}</div>
                        <div className="absolute top-0 z-50 hidden w-40 pt-6 origin-top-right group-hover:hidden -left-16">
                          <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2">
                            <Link
                              to="/access-manager/users/james-okonkwo"
                              className="block text-xs"
                            >
                              Stephen Joseph
                            </Link>
                            <Link
                              to="/access-manager/users/james-okonkwo"
                              className="block text-xs"
                            >
                              Joshua Ubiri
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="relative px-2 py-3 cursor-pointer sm:px-6 border-x group">
                        <div className="text-xs cursor-pointer">
                          {"edit mobile app settings, view pro..."}
                        </div>
                        <div className="absolute top-0 z-[200] hidden w-56 pt-6 origin-top-right group-hover:hidden">
                          <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2 text-xs">
                            <p className="">Can edit products & webpage</p>
                            <p className="">Can edit orders</p>
                            <p className="">Can edit wallets</p>
                            <p className="">Can edit security</p>
                            <p className="">Can edit store information</p>
                            <p className="">Can edit delivery</p>
                            <p className="">Can edit analytics</p>
                            <p className="">Can edit payment information</p>
                            <p className="">Can edit reviews</p>
                          </div>
                        </div>
                      </td>
                      <td className="hidden px-6 py-3 border-r sm:table-cell">
                        {"13/12/2021 (2 months ago)"}
                      </td>
                      <td className="hidden px-6 py-3 border-x sm:table-cell">
                        <div className="flex items-center justify-center">
                          <BsThreeDotsVertical
                            size={20}
                            onClick={() => {
                              setModalObject({
                                email: `${review}`,
                                phoneNumber: `${review}`,
                                tickets: 0,
                                reviews: 0,
                              });
                              setShowModal(true);
                            }}
                            className="text-gray-600 cursor-pointer"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleUserAccessPage;
