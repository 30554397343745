import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { BsArrowLeft } from "react-icons/bs";
import { IoImageOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CreateTiktokCampaign = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState<Date>();
  
  return (
    <>
      {step === 1 ? (
        <div className="space-y-4">
          <div className="flex items-center gap-1.5">
            <button
              onClick={() => navigate(-1)}
              className="text-[#171717] font-semibold text-sm inline-flex items-center gap-2"
            >
              <BsArrowLeft className="text-gray-700" size={18} />
              Compose a post
            </button>
          </div>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3 h-[580px] bg-gray-100 rounded p-10 shadow-sm">
              <div className="h-full bg-white rounded p-4 text-[14px] leading-[18px] space-y-4 font-roboto">
                <p>Dear customer name</p>
                <p>
                  Thank you for opening an account with us. We’re excited to
                  have you on board and look forward to providing you with the
                  best features to improve your business.
                </p>
                <p>
                  If you encounter any issues while using our features, our
                  support team is available to assist you here. You can also
                  join our vendors community by filling this form.
                </p>
                <p>
                  Thank you for choosing Cusecho. We are committed to ensuring
                  your success and can’t wait to help you take your business to
                  the next level.
                </p>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Numquam animi provident laborum quos in, voluptatibus aperiam
                  totam adipisci eveniet hic, iste labore, doloremque
                  temporibus! Voluptatum accusantium iure adipisci reiciendis
                  hic pariatur molestias at earum perspiciatis! Ex sint tempore
                  repudiandae incidunt.
                </p>
              </div>
            </div>
            <div className="col-span-2 space-y-2.5">
              <div className="flex flex-col gap-0.5">
                <label htmlFor="" className="text-xs text-gray-700">
                  Select phone number
                </label>
                <input
                  type="text"
                  className="py-1.5 px-3 rounded border border-gray-300 w-full outline-none"
                />
              </div>
              <div className="flex flex-col gap-0.5">
                <label htmlFor="" className="text-xs text-gray-700">
                  Body
                </label>
                <div className="flex items-center gap-4 text-[13px]">
                  <p className="py-1.5 px-2.5 rounded border border-gray-300 flex-1">
                    Generate with Cusecho AI
                  </p>
                  <button className="text-xs text-blue-600 py-2 px-2 border border-gray-300 rounded">
                    Generate
                  </button>
                </div>
                <textarea
                  name=""
                  id=""
                  className="h-[260px] p-2 border border-gray-200 outline-none mt-1 rounded placeholder:text-sm text-sm"
                  placeholder="E.g. Flash sales"
                ></textarea>
              </div>
              <div>
                <div className="flex flex-col gap-0.5">
                  <label htmlFor="" className="text-xs text-gray-700">
                    Add media
                  </label>

                  <div className="flex items-center gap-4">
                    <div className="flex items-center justify-center w-full">
                      <label className="flex flex-col items-center justify-center w-full h-[100px] border border-gray-300 border-dashed rounded cursor-pointer bg-gray-50">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <IoImageOutline className="text-gray-800" />
                          <p className="text-xs text-gray-700">Add Image</p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <label className="flex flex-col items-center justify-center w-full h-[100px] border border-gray-300 border-dashed rounded cursor-pointer bg-gray-50">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <IoImageOutline className="text-gray-800" />
                          <p className="text-xs text-gray-700">Add Image</p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setStep((prev) => prev + 1);
                  }}
                  className="text-xs py-1.5 px-4 bg-blue-500 text-white rounded"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : step === 2 ? (
        <div className="space-y-4">
          <div className="flex items-center gap-1.5">
            <p className="text-[#171717] font-semibold text-sm">
              Select recipient
            </p>
          </div>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3 h-[580px] bg-gray-100 rounded p-10 shadow-sm">
              <div className="h-full bg-white rounded p-4 text-[14px] leading-[18px] space-y-4 font-roboto">
                <p>Dear customer name</p>
                <p>
                  Thank you for opening an account with us. We’re excited to
                  have you on board and look forward to providing you with the
                  best features to improve your business.
                </p>
                <p>
                  If you encounter any issues while using our features, our
                  support team is available to assist you here. You can also
                  join our vendors community by filling this form.
                </p>
                <p>
                  Thank you for choosing Cusecho. We are committed to ensuring
                  your success and can’t wait to help you take your business to
                  the next level.
                </p>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Numquam animi provident laborum quos in, voluptatibus aperiam
                  totam adipisci eveniet hic, iste labore, doloremque
                  temporibus! Voluptatum accusantium iure adipisci reiciendis
                  hic pariatur molestias at earum perspiciatis! Ex sint tempore
                  repudiandae incidunt.
                </p>
              </div>
            </div>
            <div className="col-span-2 space-y-2.5">
              <div className="flex flex-col gap-0.5">
                <label htmlFor="" className="text-xs text-gray-700">
                  Recipient
                </label>
                <input
                  type="text"
                  className="py-1.5 px-3 rounded border border-gray-300 w-full outline-none"
                />
              </div>

              <div className="flex flex-col gap-0.5">
                <label htmlFor="" className="text-xs text-gray-700">
                  Schedule time
                </label>
                <div className="border border-gray-100 rounded pb-4" >
                  <div className="campaign flex justify-center">
                    <DayPicker
                      mode="single"
                      selected={selected}
                      onSelect={setSelected}
                    />
                  </div>
                  <div className="flex justify-center w-full max-w-full">
                    <input
                      type="time"
                      name="appt-time"
                      // value={time}
                      // onChange={(e) => setTime(e.target.value)}
                      className="w-[312px] px-4 py-2 border rounded-md border-gray-300 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                  className="text-xs py-1.5 px-4 bg-white text-gray-500 rounded border border-gray-200"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setStep((prev) => prev + 1);
                  }}
                  className="text-xs py-1.5 px-4 bg-blue-500 text-white rounded"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex items-center gap-1.5">
            <button
              onClick={() => setStep((prev) => prev - 1)}
              className="text-[#171717] font-semibold text-sm inline-flex items-center gap-2"
            >
              <BsArrowLeft className="text-gray-700" size={18} />
              Back
            </button>
          </div>
          <div className="max-w-[600px] mx-auto space-y-4">
            <div className="p-3 text-[13px] space-y-2 border border-gray-100 rounded">
              <p className="font-semibold text-blue-500 ">
                Scheduled for 12:30
              </p>
              <div className="flex items-center justify-between text-gray-500">
                <p className="font-semibold ">Instagram - John Doe</p>
                <p>21st November, 2022</p>
              </div>
            </div>
            <div className="h-[580px] bg-gray-100 rounded p-10 shadow-sm">
              <div className="h-full bg-white rounded p-4 text-[14px] leading-[18px] space-y-4 font-roboto">
                <p>Dear customer name</p>
                <p>
                  Thank you for opening an account with us. We’re excited to
                  have you on board and look forward to providing you with the
                  best features to improve your business.
                </p>
                <p>
                  If you encounter any issues while using our features, our
                  support team is available to assist you here. You can also
                  join our vendors community by filling this form.
                </p>
                <p>
                  Thank you for choosing Cusecho. We are committed to ensuring
                  your success and can’t wait to help you take your business to
                  the next level.
                </p>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Numquam animi provident laborum quos in, voluptatibus aperiam
                  totam adipisci eveniet hic, iste labore, doloremque
                  temporibus! Voluptatum accusantium iure adipisci reiciendis
                  hic pariatur molestias at earum perspiciatis! Ex sint tempore
                  repudiandae incidunt.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateTiktokCampaign;
