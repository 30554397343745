// @ts-nocheck

import React, { useState } from "react";
import { classNames } from "../../../../../utils";
import Modal from "../../../../../components/Dashboard/Modal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  loadPaymentDetails,
  loadSingleTransactionDetails,
  loadTransactionsLogs,
  loadWalletBalance,
} from "../../../../../api/Menu";
import { useAppSelector } from "../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../models";
import moment from "moment";
import { ImSpinner3 } from "react-icons/im";

const WalletPageNew = () => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const apiKey: string = useAppSelector((state) => state.auth.apiKey);

  const store: StoreModel = useAppSelector((state) => state.user.store);
  // console.log(apiKey);
  const storeKey = store.name || "";
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const platformKey1 = `${user.platformAccountId}%26${user.email}`;
  // const apiKey = "dGJULPls824mIU52d3pXXt_Ztt3Xx7Av_L_5obrQpoY";
  let transactionLogs = [];
  let paymentDetails: any = [];
  let pendingBalance;
  let walletBalance;
  let singleTransaction: any = {};
  const [selectedTransaction, setSelectedTransaction] = useState({
    transactionLogId: "",
    creationTimestamp: "",
  });

  const handleLoadWalletBalance = useQuery<any, Error>({
    queryKey: ["loadWalletBalance"],
    queryFn: async () => {
      const response = await loadWalletBalance(platformKey1, apiKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const handleLoadTransactionsLogs = useQuery<any, Error>({
    queryKey: ["getTransactionLogs"],
    // queryFn: loadTransactions3,
    queryFn: async () => {
      const response = await loadTransactionsLogs(platformKey1, apiKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const handleLoadSingleTransaction = useQuery<any, Error>({
    queryKey: ["getSingleTransaction", showModal],
    // queryFn: loadTransactions3,
    queryFn: async () => {
      const response = await loadSingleTransactionDetails(
        selectedTransaction?.transactionLogId,
        selectedTransaction?.creationTimestamp,
        apiKey
      );
      return response.json();
    },
    enabled: !!selectedTransaction?.transactionLogId,
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const handlePaymentDetails = useQuery<any, Error>({
    queryKey: ["getPaymentDetails"],
    // queryFn: loadTransactions3,
    queryFn: async () => {
      const response = await loadPaymentDetails(storeKey, apiKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  if (handleLoadTransactionsLogs.data) {
    transactionLogs = handleLoadTransactionsLogs.data.transactionHistory;
    // console.log(
    //   "logiing -----",
    //   handleLoadTransactionsLogs.data.transactionHistory
    // );
  }

  if (handleLoadWalletBalance.data) {
    // console.log(handleLoadWalletBalance.data);
    pendingBalance = handleLoadWalletBalance.data.pending_balance;
    walletBalance = handleLoadWalletBalance.data.wallet_balance;
    // console.log(transactionLogs);
  }

  if (handlePaymentDetails.data) {
    paymentDetails = handlePaymentDetails.data.Payment_Details || [];
  }

  if (handleLoadSingleTransaction.data) {
    singleTransaction = handleLoadSingleTransaction.data.transactionReceipt;
    // console.log(singleTransaction)
  }

  // console.log("logiing -----", handleLoadTransactionsLogs?.error?.message);

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          // queryClient.removeQueries(["getSingleTransaction"]);
          // queryClient.resetQueries(["getSingleTransaction"]);
          setSelectedTransaction({
            transactionLogId: "",
            creationTimestamp: "",
          });
          setShowModal(false);
          // setShowModal((prevState) => !prevState);
        }}
      >
        <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8 spce-y-3 sm:space-y-6">
          <div>
            <p className="font-semibold text-xl">Transaction details</p>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Transaction number</p>
              <p>
                {singleTransaction?.transactionNumber?.substring(0, 30) || ""}
              </p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Transaction date</p>
              <p>
                {moment(selectedTransaction.transactionCreationTime).format(
                  "MMM Do, YYYY"
                )}
              </p>
            </div>
            {/* <div className="flex items-center justify-between text-sm">
              <p className="">Transaction number</p>
              <p>{singleTransaction?.paymentReferenceId || ""}</p>
            </div> */}
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Recipient</p>
              <p>{singleTransaction?.recipient || ""}</p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">From</p>
              <p>
                {singleTransaction?.customerInformation?.fullName ||
                  "Self Debit"}
              </p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Amount received</p>
              <p>{singleTransaction?.amountReceived || ""} NGN</p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Nararative</p>
              <p>{singleTransaction?.narrative || ""}</p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Payment source</p>
              <p>{singleTransaction?.paymentSource || ""}</p>
            </div>
            <div className="flex items-center justify-between text-sm">
              <p className="text-gray-600">Reference ID</p>
              <p>{singleTransaction?.paymentReferenceId || ""}</p>
            </div>
          </div>
          <div className="mt-8">
            <button className="bg-blue-600 w-full py-2.5 text-white rounded-md text-sm">
              Share transaction
            </button>
          </div>
        </div>
      </Modal>

      <div className="h-[87vh] bg-white">
        <div className="grid grid-cols-7 gap-4">
          <div className="col-span-2 h-full sticky">
            <p className="font-semibold mb-2">Wallet</p>
            <div className="flex flex-col justify-between h-[80vh]">
              <div className="space-y-6">
                <div className="p-6 border border-gray-300 rounded flex items-center justify-center">
                  <div className="space-y-1.5">
                    <p className="text-[12.5px]">Balance after pending</p>
                    {handleLoadWalletBalance.status === "loading" ? (
                      <p className="text-xs">loading</p>
                    ) : handleLoadWalletBalance.status === "error" ? (
                      <p className="text-xs">An error occured</p>
                    ) : handleLoadWalletBalance.data.message ===
                      "Limit Exceeded" ? (
                      <p className="text-xs">Limit exceeded: error</p>
                    ) : (
                      <p className="text-3xl">
                        ₦{walletBalance.toLocaleString("en")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="p-6 border border-gray-300 rounded flex items-center justify-center bg-[#FFFDF1]">
                  <div className="space-y-1.5">
                    <p className="text-[12.5px] text-center">Pending</p>
                    {handleLoadWalletBalance.status === "loading" ? (
                      <p className="text-xs">loading</p>
                    ) : handleLoadWalletBalance.status === "error" ? (
                      <p className="text-xs">An error occured</p>
                    ) : handleLoadWalletBalance.data.message ===
                      "Limit Exceeded" ? (
                      <p className="text-xs">Limit exceeded: error</p>
                    ) : (
                      <p className="text-3xl">
                        ₦{pendingBalance === 0 ? "0.00" : pendingBalance}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <button className="px-3 py-3 text-[12.5px] bg-[#2960BF] text-white w-full rounded-md">
                    Send to personal account
                  </button>
                </div>
              </div>
              <div className="p-3 border border-gray-300 rounded text-[13px]" >
                <div className="flex items-center justify-between mb-4">
                  <p className="text-sm font-semibold">Personal account</p>
                </div>
                <div className="space-y-1.5">
                  <div className="flex gap-1 justify-between">
                    <p>Name:</p>
                    {paymentDetails.length > 0 && (
                      <p className="text-right">
                        {paymentDetails[0]?.accountName || ""}
                      </p>
                    )}
                  </div>
                  <div className="flex gap-1 items-center justify-between">
                    <p>Bank:</p>
                    {paymentDetails.length > 0 && (
                      <p>{paymentDetails[0]?.bankName || ""}</p>
                    )}
                    {/* <p>{paymentDetails[0]?.bankName || ""}</p> */}
                  </div>
                  <div className="flex gap-1 items-center justify-between">
                    <p>Account number:</p>
                    {paymentDetails.length > 0 && (
                      <p>{paymentDetails[0]?.accountNumber || ""}</p>
                    )}
                    {/* <p>{paymentDetails[0]?.accountNumber || ""}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {handleLoadTransactionsLogs?.status === "loading" ? (
            <div className="col-span-5 h-[87vh] pl-4 border-l-[1px] border-l-gray-300 flex items-center justify-center">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : handleLoadTransactionsLogs?.status === "error" ? (
            <div className="col-span-5 h-[87vh] pl-4 border-l-[1px] border-l-gray-300 flex items-center justify-center">
              {/* <p>{handleLoadTransactionsLogs.error.message}</p> */}
              <p className="text-center text-xs">
                We encountered an unexpected error. Please try again later or
                contact our support team
              </p>
            </div>
          ) : handleLoadTransactionsLogs.data?.message === "Limit Exceeded" ? (
            <div className="col-span-5 h-[87vh] pl-4 border-l-[1px] border-l-gray-300 flex items-center justify-center">
              <p className="text-sm text-center max-w-[400px] mx-auto">
                Looks like you've made a lot of requests recently. To keep
                things running smoothly, please wait a bit before trying again
              </p>
            </div>
          ) : transactionLogs?.length > 0 ? (
            <div className="col-span-5 h-[87vh] pl-4 border-l-[1px] border-l-gray-300 ">
              <p className="font-semibold mb-2">Transaction</p>
              <div>
                <table className="hidden w-full overflow-auto text-xs text-left text-gray-500 border rounded-md sm:table">
                  <thead className="text-gray-700 capitalize bg-gray-50">
                    <tr className="text-[11px] text-center capitalize">
                      <th
                        scope="col"
                        className="py-4 sm:px-6 whitespace-nowrap "
                      >
                        S/N
                      </th>
                      <th
                        scope="col"
                        className="py-4 sm:px-4 whitespace-nowrap"
                      >
                        AMOUNT
                      </th>
                      <th
                        scope="col"
                        className="hidden px-6 py-4 sm:table-cell"
                      >
                        SENDER
                      </th>
                      <th scope="col" className="px-2 py-4 sm:px-6">
                        DESCRIPTION
                      </th>
                      <th scope="col" className="px-2 py-4 sm:px-6">
                        DATE
                      </th>
                      <th scope="col" className="px-2 py-4 sm:px-6"></th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {transactionLogs.map((transaction: any, index: number) => (
                      <tr
                        className={classNames("border-b")}
                        key={transaction.transactionLogId}
                      >
                        <td className="px-2 py-2 border-x">
                          <p className="text-center whitespace-nowrap">
                            {index + 1}
                          </p>
                        </td>
                        <td className="px-4 py-2 border-x">
                          <p
                            className={classNames(
                              "text-center whitespace-nowrap",
                              transaction.transaction_type === "wallet-debit"
                                ? "text-red-500"
                                : "text-green-600"
                            )}
                          >
                            {transaction.transaction_type === "wallet-debit"
                              ? "-"
                              : "+"}
                            &nbsp;₦{Number(transaction?.amount_received).toLocaleString("en")}
                          </p>
                        </td>
                        <td className="px-4 py-2 border-x">
                          <p className="text-center">
                            {transaction?.customer_information
                              ? transaction?.customer_information
                              : "Self debit"}
                          </p>
                        </td>
                        <td className="px-6 py-2 border-x">
                          <p className="text-center">
                            {transaction?.orderNumber
                              ? transaction?.orderNumber
                              : "Debit#" + transaction.transactionLogId}
                          </p>
                        </td>
                        <td className="px-6 py-2 border-x">
                          <p className={classNames("text-center")}>
                            {moment(
                              transaction?.transactionCreationTime
                            ).format("L")}
                          </p>
                        </td>
                        <td className="px-6 py-2 border-x">
                          <p
                            className={classNames(
                              "text-center py-1.5 cursor-pointer underline"
                            )}
                            onClick={() => {
                              setSelectedTransaction(transaction);
                              setShowModal(true);
                            }}
                          >
                            Details
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-span-5 h-[87vh] pl-4 border-l-[1px] border-l-gray-300 flex items-center justify-center">
              <p className="text-sm text-center max-w-[400px] mx-auto">
                There are no new transactions. When you receive money from an
                order, it will show here.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WalletPageNew;
