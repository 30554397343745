"use client";

import React, { useState } from "react";
import SMSCampaignManager from "./_components/SMSCampaignManager";
import { Menu } from "@headlessui/react";
import { classNames } from "../../../utils";
import { MdOutlineMoreVert } from "react-icons/md";
import InstagramCampaignManager from "./_components/InstagramCampaignManager";
import TiktokCampaignManager from "./_components/TiktokCampaignManager";
import FacebookCampaignManager from "./_components/FacebookCampaignManager";
import TwitterCampaignManager from "./_components/TwitterCampaignManager";
import LinkedInCampaignManager from "./_components/LinkedInCampaignManager";
import EmailCampaignManager from "./_components/EmailCampaignManager";
import { Link, useNavigate } from "react-router-dom";

const CampaignManager = () => {
  const [activeTab, setActiveTab] = useState("Email");
  const navigate = useNavigate();
  return (
    <div className=" overflow-y-auto overflow-x-hidden relative">
      <div className="space-y-4 relative">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-[#171717] font-semibold text-sm">
              Campaign manager
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button className="bg-blue-600 text-xs text-white rounded">
              <Link
                to={
                  activeTab === "Email"
                    ? "/smart-feedback/campaign-manager/create-email"
                    : activeTab === "SMS"
                    ? "/smart-feedback/campaign-manager/create-sms"
                    : activeTab === "instagram"
                    ? "/smart-feedback/campaign-manager/create-instagram-post"
                    : activeTab === "X/Twitter"
                    ? "/smart-feedback/campaign-manager/create-twitter-post"
                    : activeTab === "Tiktok"
                    ? "/smart-feedback/campaign-manager/create-tiktok-post"
                    : activeTab === "Facebook"
                    ? "/smart-feedback/campaign-manager/create-facebook-post"
                    : "/smart-feedback/campaign-manager/create-linkedin-post"
                }
                className="block py-2 px-4"
              >
                {" "}
                New campaign
              </Link>
            </button>
            <div className="relative">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button>
                  <MdOutlineMoreVert
                    className="text-gray-800 m-0 p-0"
                    size={22}
                  />
                </Menu.Button>
                <Menu.Items className="absolute right-0 text-gray-800 mt-3 w-[180px] rounded-md bg-gray-50 shadow flex flex-col text-[12.5px]">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "text-left px-3 py-2.5 hover:bg-blue-100"
                        )}
                        onClick={() =>
                          navigate(
                            "/smart-feedback/campaign-manager/account-settings"
                          )
                        }
                      >
                        Settings
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "text-left px-3 py-2.5 hover:bg-blue-100"
                        )}
                        onClick={() =>
                          navigate(
                            "/smart-feedback/campaign-manager/analytics"
                          )
                        }
                      >
                        Analytics
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "text-left px-3 py-2.5 hover:bg-blue-100"
                        )}
                        onClick={() =>
                          navigate(
                            "/smart-feedback/campaign-manager/audience-manager"
                          )
                        }
                      >
                        Audience management
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex justify-between text-[13px]">
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Email"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("Email")}
            >
              Email
            </div>
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "SMS"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("SMS")}
            >
              SMS
            </div>
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "instagram"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("instagram")}
            >
              Instagram
            </div>{" "}
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "X/Twitter"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("X/Twitter")}
            >
              X/Twitter
            </div>{" "}
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Tiktok"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("Tiktok")}
            >
              Tiktok
            </div>{" "}
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Facebook"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("Facebook")}
            >
              Facebook
            </div>{" "}
            <div
              className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Linkedin"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
              onClick={() => setActiveTab("Linkedin")}
            >
              Linkedin
            </div>{" "}
          </div>

          <div className="flex gap-6 hidden">
            <button className="flex justify-between gap-2 font-normal py-1.5 px-3 text-sm text-[#3B3B3B] border border-[#3B3B3B] rounded-lg">
              {/* <ChevronDown size={16} />  */}
              <p>Card view</p>
            </button>
            <button className="flex text-[#3B3B3B] gap-2 py-1.5 px-3 text-sm border border-[#3B3B3B] rounded-lg">
              {/* <Archive size={16} /> */}
              <p>Archived</p>
            </button>
          </div>
        </div>
        {/* <CampaignManagerBody /> */}
        <div>
          {activeTab === "Email" && <EmailCampaignManager />}
          {activeTab === "SMS" && <SMSCampaignManager />}
          {activeTab === "instagram" && <InstagramCampaignManager />}
          {activeTab === "X/Twitter" && <TwitterCampaignManager />}{" "}
          {activeTab === "Tiktok" && <TiktokCampaignManager />}
          {activeTab === "Facebook" && <FacebookCampaignManager />}
          {activeTab === "Linkedin" && <LinkedInCampaignManager />}
        </div>
      </div>
    </div>
  );
};

export default CampaignManager;
