//@ts-nocheck

import { HiStar } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import { classNames, ratingsToNumber } from "../../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getCustomerReviewHistory,
  getReviewersHistory,
} from "../../../../api/Reviews";
import { UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SkeletonLoader from "../../../../components/Dashboard/SkeletonLoader";
import { ImSpinner3 } from "react-icons/im";
import { BsCircleFill, BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import Modal from "../../../../components/Dashboard/Modal";
import {
  getCustomerTicketHistory,
  updateTicketStatus,
} from "../../../../api/Tickets";
import { BiChevronLeft } from "react-icons/bi";

interface ReviewStarsProps {
  reviews?: number;
  size?: number;
}

const DirectTicket = () => {
  const queryClient = useQueryClient();
  const { email } = useParams();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const queryKey = user.queryKey;
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [ticketStatusDropdown, setTicketStatusDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState();
  const navigate = useNavigate();
  let customerDetails: any = {};
  let body: any = {};
  const defaultCommentList = ["jtjjfgfg", "tyfgysfy"];
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    summary: "",
    resolved: "",
  });

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getReviewersHistory"],
    queryFn: async () => {
      const paginationKey = "";
      const response = await getReviewersHistory(queryKey, paginationKey);
      return response.data;
    },
    select(data) {
      const customers = "Customers" in data ? data.Customers : [];
      delete customers.customer_vault;
      delete customers.customer_id;
      delete customers.reference_link;

      const result = Object.values(customers[0]);
      // const result = Object.values(customers);
      return result;
    },
  });

  if (data) {
    customerDetails = data ? data?.find((a) => a.customer_email === email) : {};

    body = {
      platformKey: {
        platformKey: { S: queryKey },
      },
      commentList: defaultCommentList,
    };
    if ("ticket_history" in customerDetails?.cusecho_ticketing[0]) {
      body = {
        ...body,
        commentList:
          customerDetails?.cusecho_ticketing[0].ticket_history.length > 0
            ? customerDetails.cusecho_ticketing[0].ticket_history
            : defaultCommentList,
      };
      // console.log(body);
    }
  }

  const history = useQuery<any, Error>({
    queryKey: ["getCustomerTicketHistory", email],
    queryFn: async () => {
      const response = await getCustomerTicketHistory(body);
      return response.data;
    },
    select(data) {
      // console.log(data);
      const items = "Item" in data.Response ? data.Response.Item : [];
      const result = items ? Object.values(items) : [];
      return result;
    },
    enabled: !!customerDetails?.cusecho_ticketing,
  });

  const handleUpdateTicketStatus = useMutation({
    mutationFn: async (data) => {
      const body = {
        platformKey,
        ticketId: data.ticketId,
        customerId: data.customerId,
      };
      return await updateTicketStatus(body);
    },
    onSuccess: () => {
      // setEdit(false);
      queryClient.invalidateQueries("getCustomerTicketHistory");
      // setShowAttentionModal(true);
    },
  });

  if (status === "loading")
    return <SkeletonLoader className="rounded-md h-[80vh]" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  return (
    <>
      <Modal
        show={showSendMessageModal}
        onToggle={() => setShowSendMessageModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <h1 className="font-medium text-gray-800 md:text-xl">
              This Customer has provided a phone number. Would You like to send an SMS?
            </h1>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 mt-6 md:mt-10">
            <div>
              <button
                className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-primary"
                onClick={() =>
                  navigate(`/smart-feedback/messages/sms/${email}`)
                }
              >
                Yes Send SMS
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 font-bold text-center text-gray-800 bg-transparent border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => navigate(`/smart-feedback/messages/new-message/${email}`)}
              >
                No, Send Email
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal((prevState) => !prevState);
        }}
      >
        <div className="relative flex flex-col p-8 space-y-4 text-sm text-black sm:py-12 sm:px-8 sm:space-y-6">
          <div>
            <p className="text-lg font-semibold">Ticket summary</p>
            <p>{modalObject.summary}</p>
          </div>
          <div className="">
            <p className="text-lg font-semibold">Resolved</p>
            <p>{modalObject.resolved}</p>
          </div>
        </div>
      </Modal>
      <div className="bg-white rounded-md h-[80vh] p-3 space-y-5 sm:space-y-4 overflow-hidden flex flex-col">
        <div className="flex items-center justify-between">
          <Link to={-1}>
            <p
              className="flex items-center text-sm font-medium text-black rounded-lg space-x-"
            >
              <BiChevronLeft size={24} />
              <span className="font-semibold">{"Back"}</span>
            </p>
          </Link>
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/80"
            onClick={() => setShowSendMessageModal(true)}
          >
            Send message
          </button>
        </div>
        <div className="hidden w-full text-base text-center uppercase">
          <h5>KPI</h5>
        </div>
        <div className="flex flex-col space-y-2 sm:items-center sm:flex-row sm:space-y-0 sm:space-x-4">
          <h6>Email: <span className="font-semibold">{customerDetails.customer_email}</span></h6>
          <h6>Phone: <span className="font-semibold">{customerDetails.customer_phone === "Null" ? "No Phone Number" : customerDetails.customer_phone}</span></h6>
        </div>
        <div className="flex-1 h-full overflow-auto">
          {history.status === "loading" || history.isRefetching ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : history.status === "error" ? (
            <span>Error: {history.error.message}</span>
          ) : history.data.length > 0 ? (
            <table className="w-full overflow-auto text-sm text-left text-gray-500 border rounded-md">
              <thead className="text-gray-700 capitalize bg-gray-50">
                <tr className="text-sm text-center">
                  <th scope="col" className="px-6 py-3">
                    Issue ID
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Ticket summary
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Resolved
                  </th>
                  <th scope="col" className="table-cell sm:hidden"></th>
                </tr>
              </thead>
              <tbody>
                {history.data.map((history: any, index: number) => (
                  <tr className="text-center bg-white border-b" key={index}>
                    <th
                      scope="row"
                      className="p-3 font-medium text-gray-900 max-w-[350px]"
                    >
                      <div className="text-center">
                        <p>{history.M.ticketNumber.S}</p>
                      </div>
                    </th>
                    <td className="hidden px-6 py-4 border-x sm:table-cell">
                      <div className="text-left">
                        <p>{history.M.complaint.S}</p>
                      </div>
                    </td>
                    <td className="px-6 py-4 border-x sm:border-r">
                      <div
                        className={classNames(
                          "border p-1 rounded-md text-white text-center w-20 mx-auto",
                          history.M.ticketStatus.S === "Resolved"
                            ? "bg-green-800"
                            : "bg-[#ECB22E]"
                        )}
                      >
                        <span>{history.M.ticketStatus.S}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 sm:border-r">
                      <p>
                        {moment(history.M.uploadTimestamp.S).format(
                          "Do MMMM YYYY"
                        )}
                      </p>
                    </td>
                    <td className="hidden px-6 py-4 sm:table-cell">
                      <p>
                        {history.M.resolvedDate.S === "ACTIVE"
                          ? "ACTIVE"
                          : moment(history.M.resolvedDate.S).format(
                              "Do MMMM YYYY"
                            )}
                        {}
                      </p>
                    </td>
                    <td className="table-cell sm:hidden">
                      <BsThreeDotsVertical
                        size={22}
                        onClick={() => {
                          setModalObject({
                            summary: history.M.complaint.S,
                            resolved: history.M.resolvedDate.S === "ACTIVE"
                            ? "ACTIVE"
                            : moment(history.M.resolvedDate.S).format(
                                "Do MMMM YYYY"
                              ),
                          });
                          setShowModal(true);
                        }}
                        className="text-gray-600 cursor-pointer sm:hidden"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-base text-center text-gray-400">No complaints from this customer</p>
          )}
        </div>
      </div>
    </>
  );
};

// const DirectTicket1 = () => {
//   const queryClient = useQueryClient();
//   const { email } = useParams();
//   const user: UserProfileModel = useAppSelector((state) => state.user.profile);
//   const platformKey = `${user.platformAccountId}&${user.email}`;
//   const queryKey = user.queryKey;
//   const [categoryDropdown, setCategoryDropdown] = useState(false);
//   const [showSendMessageModal, setShowSendMessageModal] = useState(false);
//   const [ticketStatusDropdown, setTicketStatusDropdown] = useState(false);
//   const [activeDropdown, setActiveDropdown] = useState();
//   const navigate = useNavigate();
//   let customerDetails: any = {};
//   let body: any = {};
//   const defaultCommentList = ["jtjjfgfg", "tyfgysfy"];

//   const { status, data, error } = useQuery<any, Error>({
//     queryKey: ["getReviewersHistory"],
//     queryFn: async () => {
//       const paginationKey = "";
//       const response = await getReviewersHistory(queryKey, paginationKey);
//       return response.data;
//     },
//     select(data) {
//       const customers = "Customers" in data ? data.Customers : [];
//       delete customers.customer_vault;
//       delete customers.customer_id;
//       delete customers.reference_link;

//       const result = Object.values(customers[0]);
//       // const result = Object.values(customers);
//       return result;
//     },
//   });

//   if (data) {
//     customerDetails = data ? data?.find((a) => a.customer_email === email) : {};

//     body = {
//       platformKey: {
//         platformKey: { S: queryKey },
//       },
//       commentList: defaultCommentList,
//     };
//     if ("ticket_history" in customerDetails?.cusecho_ticketing[0]) {
//       body = {
//         ...body,
//         commentList:
//           customerDetails?.cusecho_ticketing[0].ticket_history.length > 0
//             ? customerDetails.cusecho_ticketing[0].ticket_history
//             : defaultCommentList,
//       };
//       // console.log(body);
//     }
//   }

//   const history = useQuery<any, Error>({
//     queryKey: ["getCustomerTicketHistory", email],
//     queryFn: async () => {
//       const response = await getCustomerTicketHistory(body);
//       return response.data;
//     },
//     select(data) {
//       // console.log(data);
//       const items = "Item" in data.Response ? data.Response.Item : [];
//       const result = items ? Object.values(items) : [];
//       return result;
//     },
//     enabled: !!customerDetails?.cusecho_ticketing,
//   });

//   const handleUpdateTicketStatus = useMutation({
//     mutationFn: async (data) => {
//       const body = {
//         platformKey,
//         ticketId: data.ticketId,
//         customerId: data.customerId,
//       };
//       return await updateTicketStatus(body);
//     },
//     onSuccess: () => {
//       // setEdit(false);
//       queryClient.invalidateQueries("getCustomerTicketHistory");
//       // setShowAttentionModal(true);
//     },
//   });

//   if (status === "loading")
//     return <SkeletonLoader className="rounded-md h-[80vh]" />;

//   if (status === "error") return <span>Error: {error.message}</span>;

//   return (
//     <>
//       <Modal
//         show={showSendMessageModal}
//         onToggle={() => setShowSendMessageModal(false)}
//       >
//         <div className="relative flex flex-col p-6 md:py-12 md:px-16">
//           <div className="mt-5 text-center md:mt-8">
//             <h1 className="font-medium text-gray-800 md:text-xl">
//               This Customer has provided a phone number.
//             </h1>
//             <h1 className="font-medium text-gray-800 md:text-xl">
//               Would You like to send an SMS?
//             </h1>
//           </div>
//           <div className="flex justify-center gap-2 mt-6 md:mt-10">
//             <div>
//               <button
//                 className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-primary"
//                 onClick={() =>
//                   navigate(`/smart-feedback/messages/sms/${email}`)
//                 }
//               >
//                 Yes Send SMS
//               </button>
//             </div>
//             <div>
//               <button
//                 type="button"
//                 className="px-4 py-2 font-bold text-center text-gray-800 bg-transparent border rounded-md w-44 md:px-6 lg:px-8"
//                 onClick={() => navigate(`/smart-feedback/messages/new-message/${email}`)}
//               >
//                 No, Send Email
//               </button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <div className="">
//         <div className="flex items-center justify-between p-3 bg-white h-15vh">
//           <div className="relative">
//             <button
//               className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-600 bg-transparent border rounded-md"
//               onClick={() => setCategoryDropdown((prevState) => !prevState)}
//               type="button"
//             >
//               QR Scanner
//               <svg
//                 className="w-4 h-4 ml-4"
//                 aria-hidden="true"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M19 9l-7 7-7-7"
//                 ></path>
//               </svg>
//             </button>
//             {categoryDropdown && (
//               <div
//                 id="dropdown"
//                 className={`absolute top-6 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
//               >
//                 <ul
//                   className="py-2 text-sm text-gray-700"
//                   aria-labelledby="dropdownDefaultButton"
//                 >
//                   <li>
//                     <a href="#" className="block px-4 py-2 hover:bg-gray-100">
//                       Facebook
//                     </a>
//                   </li>
//                   <li>
//                     <a href="#" className="block px-4 py-2 hover:bg-gray-100">
//                       LinkedIn
//                     </a>
//                   </li>
//                   <li>
//                     <a href="#" className="block px-4 py-2 hover:bg-gray-100">
//                       Twiiter
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             )}
//           </div>
//           <Link to="/smart-feedback/customer-log">
//             <span
//               type="button"
//               className="px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/80"
//             >
//               Back
//             </span>
//           </Link>
//         </div>
//         <div className="grid content-center justify-center grid-cols-8">
//           <div className="h-[70vh] bg-white col-span-2 overflow-y-auto striped border-r-2 border-r-gray-20">
//             {data.map((customer: any, index: number) => (
//               <Link
//                 key={index}
//                 to={`/smart-feedback/direct-ticket/${customer.customer_email}`}
//                 className={classNames(
//                   "flex items-center gap-3 px-4 py-4 text-sm font-medium odd:bg-gray-50 even:bg-gray-100 border-l-4",
//                   customer.customer_email === email
//                     ? "border-primary"
//                     : "border-transparent"
//                 )}
//               >
//                 <p>{customer.customer_email}</p>
//               </Link>
//             ))}
//           </div>
//           <div className="h-[70vh] bg-white col-span-6 px-6">
//             <div className="flex gap-3">
//               <div className="w-[30px] h-[30px] bg-gray-800 rounded-md"></div>
//               <div className="text-sm leading-4 ">
//                 <p>{customerDetails.customer_email}</p>
//                 <p>{customerDetails.customer_phone}</p>
//                 <p className="text-xs font-normal mt-1.5">
//                   <span className="pr-2 text-primary">Scanner</span>
//                   <span className="px-2 text-[#ECB22E] border-slate-400 border-x">
//                     <span className="border px-2 py-0.5 rounded">
//                       {customerDetails.cusecho_ticketing[0].pending} Pending
//                     </span>
//                   </span>
//                   <span className="px-2 text-green-600">
//                     <span className="border px-2 py-0.5 rounded">
//                       {customerDetails.cusecho_ticketing[0].resolved} Resolved
//                     </span>
//                   </span>
//                 </p>
//               </div>
//             </div>
//             <br />
//             <div className="relative overflow-x-auto overflow-y-auto h-1/2">
//               {history.status === "loading" || history.isRefetching ? (
//                 <div className="flex items-center justify-start py-5">
//                   <ImSpinner3
//                     size={18}
//                     className="mx-auto animate-spin fill-primary"
//                   />
//                 </div>
//               ) : history.status === "error" ? (
//                 <span>Error: {history.error.message}</span>
//               ) : history.data.length > 0 ? (
//                 <table className="w-full text-sm text-left text-gray-500 border rounded-lg">
//                   <thead className="text-gray-700 capitalize bg-gray-50">
//                     <tr className="text-base">
//                       <th scope="col" className="px-6 py-3">
//                         Issue ID
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Ticket Summary
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Status
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Time Creation
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Time Resolved
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {history.data.map((history: any, index: number) => (
//                       <tr className="bg-white border-b" key={index}>
//                         <th
//                           scope="row"
//                           className="p-3 pr-1 font-medium text-gray-900 max-w-[350px]"
//                         >
//                           <div className="flex items-center gap-4">
//                             <p className="font-semibold">{index + 1}</p>
//                             <p>{history.M.ticketNumber.S}</p>
//                           </div>
//                         </th>
//                         <td className="px-6 py-4 border-x">
//                           {history.M.complaint.S}
//                         </td>
//                         <td className="px-6 py-4 border-r">
//                           <div
//                             className={classNames(
//                               "border py-2 px-2 rounded cursor-pointer text-white text-center",
//                               history.M.ticketStatus.S === "Resolved"
//                                 ? "bg-green-800"
//                                 : "bg-[#ECB22E]"
//                             )}
//                           >
//                             <span>{history.M.ticketStatus.S}</span>
//                           </div>
//                         </td>
//                         <td className="px-6 py-4 border-x">
//                           <p>
//                             {moment(history.M.uploadTimestamp.S).format(
//                               "MMMM Do, YYYY"
//                             )}
//                           </p>
//                         </td>
//                         <td className="px-6 py-4">
//                           <p>
//                             {history.M.resolvedDate.S === "ACTIVE"
//                               ? "ACTIVE"
//                               : moment(history.M.resolvedDate.S).format(
//                                   "MMMM Do, YYYY"
//                                 )}
//                             {}
//                           </p>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <p className="text-sm text-gray-400">No complaints from this customer</p>
//               )}
//             </div>
//             <div className="bg-gray-50 p-3 flex gap-4 mt-6 max-w-[320px]">
//               <AiOutlineMail size={30} />
//               <div>
//                 <p className="text-sm">
//                   Would you like to send this customer a message?
//                 </p>
//                 <button
//                   type="button"
//                   className="px-4 py-2 mt-3 text-sm font-medium text-left text-white rounded-lg bg-primary hover:bg-primary/80"
//                   onClick={() => setShowSendMessageModal(true)}
//                 >
//                   Send Message
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

export default DirectTicket;
