import { getSvg } from "../../../../utils";

interface SocialButtonProps {
  type?: any;
  icon: string;
  value: string;
  onClick?: () => void;
  disabled?: boolean;
}

const SocialButton = ({
  type = "button",
  icon,
  value,
  onClick,
  disabled = false,
}: SocialButtonProps) => {
  return (
    <button
      type={type}
      className="text-sm rounded-md w-full outline-none bg-transparent border text-secondary py-2.5 inline-flex items-center justify-center disabled:bg-gray-50"
      onClick={onClick}
      disabled={disabled}
    >
      <span className="mr-[8px]">
        <img src={getSvg(icon)} alt="img" className="w-[20px]" />
      </span>
      <span>{value}</span>
    </button>
  );
};

export default SocialButton;
