import { useState } from "react";
import { BiComment, BiSearch } from "react-icons/bi";
import { classNames } from "../../../utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const UserAccessPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });
  const navigate = useNavigate();

  return (
    <>
      <div className="flex bg-white rounded-md h-[85vh] space-y-4 overflow-hidden flex-col">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-bold text-black">Users</h4>
          <Link
            to="/access-manager/users/create"
            className="block px-5 py-2 text-xs font-semibold text-white rounded-md bg-primary"
          >
            Create new user
          </Link>
        </div>
        <div>
          <div className="relative w-full sm:w-3/5 lg:w-2/5">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                // if (e.target.value.length > 3) {
                setSearchQuery(e.target.value);
                // }
              }}
              placeholder="Search"
              className="w-full px-4 py-2 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={22}
              className="absolute top-1.5 right-2.5 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
        </div>
        <div>
          <div className="relative flex-1 h-full overflow-y-auto overflow-x-hiddeon">
            <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
              <thead className="text-gray-800 capitalize bg-gray-50">
                <tr className="text-xs text-left capitalize">
                  <th scope="col" className="py-3 text-center sm:px-6">
                    S/N
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    User Name
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Email
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Phone
                  </th>
                  <th scope="col" className="px-2 py-3 normal-case sm:px-6">
                    {"Group(s)"}
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Last Active
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {[1, 2, 3, 4, 5, 6].map((review: any, index: number) => (
                  <tr className={classNames("border-b")} key={index}>
                    <td scope="row" className="px-2 py-2 sm:px-6">
                      <div className="">
                        <p className="font-medium text-center whitespace-nowrap">
                          {review}
                        </p>
                      </div>
                    </td>
                    <td className="hidden px-6 py-3 border-x sm:table-cell">
                      <p className="text-xs">{"Chidinma Okonkwo"}</p>
                    </td>
                    <td className="hidden px-6 py-3 border-x sm:table-cell">
                      <p className="text-xs">{"chidinmaokonkwo@gmail.com"}</p>
                    </td>
                    <td className="hidden px-6 py-3 border-x sm:table-cell">
                      <p className="text-xs">{"0801 234 567"}</p>
                    </td>
                    <td className="hidden px-6 py-3 border-x sm:table-cell">
                      <p className="text-xs">{"1"}</p>
                    </td>
                    <td className="hidden px-6 py-3 border-r sm:table-cell">
                      {"13/12/2021 (2 months ago)"}
                    </td>
                    <td className="hidden px-6 py-3 border-x sm:table-cell">
                      <div className="flex items-center justify-center">
                        <BsThreeDotsVertical
                          size={20}
                          onClick={() => {
                            navigate('/access-manager/users/chidinma-okonkwo');
                            setModalObject({
                              email: `${review}`,
                              phoneNumber: `${review}`,
                              tickets: 0,
                              reviews: 0,
                            });
                            setShowModal(true);
                          }}
                          className="text-gray-600 cursor-pointer"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2.5">
          <button
            onClick={() => {}}
            disabled={true}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            prev
          </button>
          <button
            onClick={() => {}}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            next
          </button>
        </div>
      </div>
    </>
  );
};

export default UserAccessPage;
