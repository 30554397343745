import React from "react";
import { IoLogoLinkedin } from "react-icons/io";
import { MdOutlineMoreVert, MdOutlineSchedule } from "react-icons/md";


const CampaignManagerBody = () => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className="border border-gray-100 shadow-sm p-2.5 rounded-md flex flex-col gap-2">
      <div className="text-sm flex justify-between">
        <div className="text-green-700 flex items-center gap-0.5">
          <MdOutlineSchedule size={18} className="text-gray-600" />
          <p className="text-xs">12:30</p>
        </div>
        <div className="flex justify-between items-center gap-2">
          <p className="text-[#8F8F8F] text-xs">11/10/2024</p>
          <MdOutlineMoreVert size={20} className="text-gray-500" />
        </div>
      </div>
      <div className="font-semibold text-gray-700">
        <div>
          <p className="text-sm">johndoe@gmail.com, and 20 others</p>
        </div>
        <p className="text-[13px]">Flash Sale</p>
      </div>
      <div>
        <p className="text-xs text-gray-700 font-medium leading-[18px]">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis
          similique eius delectus non laudantium. Iure, debitis nulla!
          Architecto, dolores quis. Dolore consectetur nam, officia doloribus
          reiciendis alias. Corrupti voluptates magni...
        </p>
      </div>
      <div className="flex gap-2">
        <img
          src="/media/image/defaultStoreHeroImage.jpg"
          alt="image"
          className="object-cover rounded h-[50px] w-[50px]"
        />
        <img
          src="/media/image/defaultStoreHeroImage.jpg"
          alt="image"
          className="object-cover rounded h-[50px] w-[50px]"
        />
      </div>
      <div className="flex items-center justify-between mt-3">
        <button className="bg-neutral-100 text-gray-600 text-xs font-medium rounded p-2 px-4">
          Scheduled
        </button>
        <IoLogoLinkedin className="text-blue-600" size={22} />
      </div>
     
    </div>
  );
};

const EmailCampaignManager = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {Array.from({ length: 6 }).map((_, index) => (
        <CampaignManagerBody key={index} />
      ))}
    </div>
  );
};

export default EmailCampaignManager;
