import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import CampaignManagerEmailAnalytics from "./CampaignManagerEmailAnalytics";
import CampaignManagerInstagramAnalytics from "./CampaignManagerInstagramAnalytics";
import CampaignManagerSMSAnalytics from "./CampaignManagerSMSAnalytics";
import CampaignManagerTwitterAnalytics from "./CampaignManagerTwitterAnalytics";
import CampaignManagerTiktokAnalytics from "./CampaignManagerTiktokAnalytics";
import CampaignManagerFacebookAnalytics from "./CampaignManagerFacebookAnalytics";
import CampaignManagerLinkedinAnalytics from "./CampaignManagerLinkedinAnalytics";

const CampaignManagerAnalytics = () => {
  const [activeTab, setActiveTab] = useState("Email");
  const navigate = useNavigate();
  return (
    <div className=" overflow-y-auto overflow-x-hidden relative space-y-4 ">
      <div className="flex items-center gap-1">
        <button
          onClick={() => navigate(-1)}
          className="text-[#171717] font-semibold text-sm inline-flex items-center gap-1"
        >
          <BsArrowLeft className="text-gray-700" size={18} />
          Analytics
        </button>
      </div>
      <div className="flex justify-between">
        <div className="flex justify-between text-[13px]">
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Email"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("Email")}
          >
            Email
          </div>
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "SMS"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("SMS")}
          >
            SMS
          </div>
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "instagram"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("instagram")}
          >
            Instagram
          </div>{" "}
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "X/Twitter"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("X/Twitter")}
          >
            X/Twitter
          </div>{" "}
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Tiktok"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("Tiktok")}
          >
            Tiktok
          </div>{" "}
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Facebook"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("Facebook")}
          >
            Facebook
          </div>{" "}
          <div
            className={`cursor-pointer pt-0 py-1 px-3 transition duration-300 
                ${
                  activeTab === "Linkedin"
                    ? "border-b-4 border-[#106BBA] text-#000"
                    : "text-[#757575]"
                }`}
            onClick={() => setActiveTab("Linkedin")}
          >
            Linkedin
          </div>{" "}
        </div>

        <div className="flex items-center gap-2">
          <button className="bg-white border border-gray-200 py-1.5 px-4 text-sm text-gray-700 rounded">
            Filter
          </button>
        </div>
      </div>
      <div>
        {activeTab === "Email" && <CampaignManagerEmailAnalytics />}
        {activeTab === "SMS" && <CampaignManagerSMSAnalytics />}
        {activeTab === "instagram" && <CampaignManagerInstagramAnalytics />}
        {activeTab === "X/Twitter" && <CampaignManagerTwitterAnalytics />}
        {activeTab === "Tiktok" && <CampaignManagerTiktokAnalytics />}
        {activeTab === "Facebook" && <CampaignManagerFacebookAnalytics />}
        {activeTab === "Linkedin" && <CampaignManagerLinkedinAnalytics />}
      </div>
    </div>
  );
};

export default CampaignManagerAnalytics;
