import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../../../components/Dashboard/Modal";
import { classNames, getImage } from "../../../../../utils";
import { useFormik } from "formik";
import { MdOutlineCancel } from "react-icons/md";
import { UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import { BsPlus } from "react-icons/bs";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <>
      <label className="block mb-1 text-xs font-normal text-gray-800">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
        )}
      />
    </>
  );
};

const initialValues = {
  name: "",
  phoneNumber: "",
};

const initialValues1 = {
  name: "",
  phoneNumber: "",
};

const CouponTable = (props : { onEdit: () => void, onDelete: () => void }) => {
  return (
    <table className="w-full overflow-auto text-xs text-left text-gray-500 border">
      <thead className="text-gray-700 capitalize bg-gray-50">
        <tr className="text-xs text-center uppercase">
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Name
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Code
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Discount
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Type
          </th>
          <th scope="col" className="px-6 py-3">
            validity date
          </th>
          <th scope="col" className="px-6 py-3">
            date created
          </th>
          <th scope="col" className="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody className="">
        {/* {
          [1,2,3,4,5,6,7,8,9].map((item: any, index: number) => {
            return (
              <tr key={index} className={classNames("border-b")}>
                <td className="hidden px-6 py-2 uppercase sm:table-cell border-x">Christmas Sale</td>
                <td className="hidden px-6 py-2 uppercase sm:table-cell border-x">#212856</td>
                <td className="hidden px-6 py-2 uppercase sm:table-cell border-x">{"10% off"}</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">All products</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">8th june 2023</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">8th june 2023</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">
                  <div className="flex items-center space-x-5">
                    <button onClick={props.onEdit}><AiOutlineEdit size={20} /></button>
                    <button onClick={props.onDelete}><BsTrash3 size={20} /></button>
                  </div>
                </td>
              </tr>
            );
          })
        } */}
        <tr className={classNames("border-b")}>
          <td colSpan={7} className="px-6 py-2 text-center border-x">No discounts have been set yet</td>
        </tr>
      </tbody>
    </table>
  );
};

const LoyaltyTable = () => {
  return (
    <table className="w-full overflow-auto text-xs text-left text-gray-500 border">
      <thead className="text-gray-700 capitalize bg-gray-50">
        <tr className="text-xs text-center uppercase">
          <th scope="col" className="px-6 py-3">
            Email
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Phone
          </th>
          <th scope="col" className="px-6 py-3">
            Loyalty Id
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell"></th>
        </tr>
      </thead>
      <tbody className="">
        {/* {
          [1,2,3,4].map((item: any, index: number) => {
            return (<tr key={index} className={classNames("border-b")}>
              <td className="hidden px-6 py-2 capitalize sm:table-cell border-x">example@example.com</td>
              <td className="hidden px-6 py-2 text-center sm:table-cell border-x">+234986754647</td>
              <td className="hidden px-6 py-2 text-center sm:table-cell border-x">+234986754647</td>
              <td className="hidden px-6 py-2 text-center sm:table-cell border-x">
                <Link to={`/smart-feedback/customer-loyalty/1`} className="text-black underline underline-offset-2">Details</Link>
              </td>
            </tr>);
          })
        } */}
        <tr className={classNames("border-b")}>
          <td colSpan={4} className="px-6 py-2 text-center border-x">No rewards have been set yet</td>
        </tr>
      </tbody>
    </table>
  );
};

const CustomerLoyaltyPage = () => {
  const user: UserProfileModel = useAppSelector((state: any) => state.user.profile);
  const queryKey = user.queryKey;
  const [option, setOption] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  let couponList : any[] = [];
  let loyaltyList : any[] = [];

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, formikHelpers) => {
      console.log(values);
    },
  });

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-5 md:py-6 md:px-6">
          <h4 className="text=gray-800 font-semibold mb-2 text-xl">
            {option === 0 ?  "New coupon" : " New loyalty card"}
          </h4>
          <button onClick={() => {
          setShowModal(false);
          }} className="absolute top-4 right-2.5 text-gray-500 hidden md:block"><MdOutlineCancel size={28} /></button>
          {
            option === 0 ? (
              <form onSubmit={formik.handleSubmit} className="">
                <div className="w-full my-4">
                    <Input
                      formik={formik}
                      name="reward"
                      label="Name"
                      type="text"
                      placeHolder="Halloween sale"
                    />
                    {/* {formik.values.email && formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )} */}
                </div>
                <div className="w-full my-4 space-y-2">
                    <Input
                      formik={formik}
                      name="discount"
                      label="Discount"
                      type="text"
                      placeHolder="15% off"
                    />
                    {/* {formik.values.email && formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )} */}
                    <div className="flex items-center w-full space-x-6 text-center">
                      <>
                        <hr className="w-full border border-gray-300 rounded-md" />
                        <h3 className="text-xl font-semibold text-gray-400">Or</h3>
                        <hr className="w-full border border-gray-300 rounded-md" />
                      </>
                    </div>
                    <Input
                      formik={formik}
                      name="amount"
                      label="Amount"
                      type="text"
                      placeHolder="N2000 off"
                    />
                </div>
                <div className="w-full my-4 space-y-3">
                    <Input
                      formik={formik}
                      name="reward"
                      label="Validity date"
                      type="date"
                      placeHolder=""
                    />
                    {/* {formik.values.email && formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )} */}
                  <div>
                    <label className="block mb-1 text-xs font-normal text-gray-800">Type</label>
                    <select className="w-full px-5 py-2 text-gray-900 uppercase bg-white border border-gray-300 rounded-md outline-none placeholder:text-xs">
                      <option value="all">All products</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-1 text-xs font-normal text-gray-800">Product</label>
                    <select className="w-full px-5 py-2 text-gray-900 uppercase bg-white border border-gray-300 rounded-md outline-none placeholder:text-xs">
                      <option value="all">Choose product --</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-10">
                  <button
                    type="submit"
                    className="px-16 py-2 text-xs font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 "
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setShowModal(true);
                    // }}
                  >
                    Create Coupon
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={formik.handleSubmit} className="">
                <div className="w-full my-4 space-y-2">
                    <Input
                      formik={formik}
                      name="email"
                      label="Email"
                      type="text"
                      placeHolder="example@example.com"
                    />
                    {/* {formik.values.email && formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )} */}
                    <div className="flex items-center w-full space-x-6 text-center">
                      <>
                        <hr className="w-full border border-gray-300 rounded-md" />
                        <h3 className="text-xl font-semibold text-gray-400">Or</h3>
                        <hr className="w-full border border-gray-300 rounded-md" />
                      </>
                    </div>
                    <Input
                      formik={formik}
                      name="customer"
                      label="Choose from existing customers"
                      type="text"
                      placeHolder="example@example.com"
                    />
                </div>
                <div className="w-full my-4 space-y-3">
                    <Input
                      formik={formik}
                      name="phone"
                      label="Phone number"
                      type="text"
                      placeHolder="+234678905768"
                    />
                    {/* {formik.values.email && formik.errors.email && (
                    <InputMessage message={formik.errors.email} />
                  )} */}
                  <div>
                    <label className="block mb-2 text-xs font-normal text-gray-800">Select coupons available</label>
                    <div className="flex flex-col space-y-1.5">
                      <div className="flex items-center space-x-1 text-xs font-medium">
                        <input type="checkbox" className="w-3 h-3 rounded-full" />
                        <span>Halloween sale</span>
                      </div>
                      <div className="flex items-center space-x-1 text-xs font-medium">
                        <input type="checkbox" className="w-3 h-3 rounded-full" />
                        <span>Christmas sale</span>
                      </div>
                      <div className="flex items-center space-x-1 text-xs font-medium">
                        <input type="checkbox" className="w-3 h-3 rounded-full" />
                        <span>Valentine sale</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-10">
                  <button
                    type="submit"
                    className="px-16 py-2 text-xs font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 "
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setShowModal(true);
                    // }}
                  >
                    Create loyalty card
                  </button>
                </div>
              </form>
            )
          }
        </div>
      </Modal>
      <div className="flex bg-white rounded-md h-[85vh] p-3 space-y-5 sm:space-y-8 overflow-hidden flex-col">
        <div className="space-y-4 md:space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-semibold text-black">Customer reward</h4>
            <button
              onClick={() => {
                //  setShowModal(true)
              }
                }
              className="flex items-center px-4 py-2 space-x-3 text-xs font-medium text-white rounded-md cursor-not-allowed ouline-none bg-primary"
            >
              <BsPlus size={20} />
              {option === 0 ? "New coupon" : "New loyalty card"}
            </button>
          </div>
          <div className="relative w-full sm:w-3/4 lg:w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                // if (e.target.value.length > 3) {
                  setSearchQuery(e.target.value);
                // }
              }}
              placeholder="Search..."
              className="w-full px-4 py-2 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={24}
              className="absolute top-2 right-4 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-6">
          <div className="flex items-center space-x-5">
            {[0,1].map((opt: number, index: number) => {
              return (<button key={index} onClick={() => setOption(opt)} className={classNames("capitalize px-2 py-1 font-semibold border-b-2 outline-none", option === opt ? "text-black border-primary" : "text-slate-500 border-transparent")}>{opt === 0 ? "coupon" : "loyalty"}</button>);
            })}
          </div>
          <div className="relative flex-1 h-full overflow-x-auto overflow-y-auto rounded-md">
            {
              option === 0 ? (
                <CouponTable onEdit={() => setShowModal(true)} onDelete={() => {}} />
              ) : (<LoyaltyTable />)
            }
          </div>
        </div>
      </div>
      <div className="space-y-1 hidden">
        <div className="overflow-y-hidden relative">
          <div className="w-full">
            <div className="h-full mx-auto">
              <img
                src={getImage("coming-soon-bg")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
            <h4 className="text-6xl font-bold uppercase ">Coming soon</h4>
            <p className="mt-2 text-lg font-medium max-w-[480px]">
              We’re working hard and fast to bring you features that will help
              scale your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerLoyaltyPage;
