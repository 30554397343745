import { Base64 } from "js-base64";
import { UserProfileModel } from "../models";
import { useState, useEffect } from "react";
import { HiStar } from "react-icons/hi";
import JSEncrypt from "jsencrypt";
import { CartProps } from "../redux/Utils";

const PUBLIC_URL = process.env.PUBLIC_URL;

export const SESSION_TIME_KEY = "loginTime";

export const LAST_INTERACTION_TIME_KEY = "lastInteractionTime";

export const getSvg = (name: string) => {
  return `/media/svg/${name}.svg`;
};

export const getImage = (name: string) => {
  return `/media/image/${name}.png`;
};

export const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(" ");
};

export const randomNumberInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const API_BASE_URL =
  "https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/v1";

export const API_BASE_URL_PUBLIC =
  "https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1";

export const ratingsToString = (rate: number, capitalize: boolean = false) => {
  let value;

  switch (rate) {
    case 1:
      value = "one";
      break;
    case 2:
      value = "two";
      break;
    case 3:
      value = "three";
      break;
    case 4:
      value = "four";
      break;
    case 5:
      value = "five";
      break;

    default:
      value = "Invalid rating";
      break;
  }

  if (capitalize) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
  }

  return value;
};
export const ratingsToNumber = (rate: string) => {
  let value;

  switch (rate) {
    case "one":
      value = 1;
      break;
    case "two":
      value = 2;
      break;
    case "three":
      value = 3;
      break;
    case "four":
      value = 4;
      break;
    case "five":
      value = 5;
      break;

    default:
      value = "Invalid rating";
      break;
  }
  return value;
};

export const formatAcctID = (id: string) => {
  let f_val = id.replace(/\D[^\.]/g, "");
  id = f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  return id;
};

export const calcPercentage = (num1: number, num2: number) => {
  if (num1 === 0 && num2 === 0) {
    return 0;
  }

  if (num1 <= num2) {
    return (num1 / num2) * 100;
  }

  return 0;
};

export const METRICS_COLORS: string[] = [
  "#008ffb",
  "#00e396",
  "#feb019",
  "#ff4560",
  "#775dd0",
];

export const base64Encode = (value: string, urlSafe: boolean = true) => {
  const result = Base64.encode(value, urlSafe);
  return result;
};

export const base64Decode = (value: string) => {
  const result = Base64.decode(value);
  return result;
};

export const isAdmin = (email: string) => {
  const adminEmails = [
    "admin@cloudpactai.com",
    "joshuaubiri@gmail.com",
    "chopsyfillz@gmail.com",
    "georgenwokoro@cloudpactai.com"
  ];
  return adminEmails.includes(email);
  // return false;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const getEmailTemplate = (subject: string, bodyText: string, storeName: string) => {
const emailTemplate = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Roboto:wght@300;400;500&display=swap" rel="stylesheet">
<style>
  * {
  margin: 0;
  padding: 0;
}

a {
  color: white !important;
}

body {
  background: whitesmoke;
}

.container {
  max-width: 614px;
  margin: 0 auto;
  background-color: white;
}

.header {
  padding: 3rem 1rem;
  color: white;
  background: #2960bf;
  font-family: "Roboto", sans-serif;
}

.store_name {
  font-size: 32px;
  text-align: center;
}

.cusecho_logo {
  width: 180px;
  height: 39px;
  margin-bottom: 1rem;
}

.powered_by {
  font-size: 0.8rem;
  font-style: italic;
  margin-right: 10px;
}

.body {
  font-family: "Inter", sans-serif;
  background: rgb(252,253,252);
  padding: 2rem 1.5rem;
  color: black;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.body > p {
  margin-bottom: 0.8rem;
  color: black;
}

.footer {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  padding: 2rem 1rem;
  text-align: center;
  color: #eaf0fb;
  background: #2960BF;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.footer > p {
  margin: 0 auto;
  line-height: 18px;
  max-width: 450px;
}

.footer > a {
  color: white !important;
}

.visit_us {
  margin-top: 6px !important;
}

.thanks {
  margin-top: 0.8rem;
}

.thanks p {
  font-size: 16px;
  color: black;
}

</style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <h1 class="store_name">${storeName}</h1>
      </div>
      <div class="body">
        <p>Hi,</p>
        <p>${bodyText}</p>
        <div class="thanks">
          <p>Thanks,</p>
          <p>${storeName}</p>
        </div>
      </div> 
       <div class="footer">
        <div>
          <p class="powered_by">Powered by</p>
          <img class="cusecho_logo" src="https://www.cusecho.com/image/email_light_logo.png" alt="cusecho">
        </div>
        <p>
          Cusecho provides with data driven information to help you grow and connect better with your customers. Try Cusecho today to stay on top of what your customers think.
        </p>
        
        <p class="visit_us">
          Visit us at
          <a href="https://www.cusecho.com/" target="_blank"
            >Cusecho</a
          >
        </p>  
        <p class="follow_us">Follow us on <a href="https://twitter.com/Cusecho_" target="_blank">
          twitter
        </a>, <a
        href="https://instagram.com/cusecho_?igshid=NTc4MTIwNjQ2YQ=="
        target="_blank"
      >Instragram
      </a>, <a
      href="https://www.linkedin.com/company/cloudpact-ai"
      target="_blank"
    >linkedIn
    </a></p>
      </div>
  </body>
</html>
`
  return emailTemplate;
};

export const CUSTOMER_ECHO_DATA_SOURCES = [
  { key: "qr_code", name: "Qr Code" },
  { key: "web", name: "Web" },
  { key: "facebook", name: "Facebook" },
  { key: "twitter", name: "Twitter" },
  { key: "instagram", name: "Instagram" },
  { key: "voice", name: "Voice" },
  { key: "sms", name: "SMS" },
];

export const getGeneratedID = () => {
  return Math.floor(1000000000 + Math.random() * 9000000000);
};

export const CUSECHO_DATA_INJESTION_SOURCES = [
  {
    title: "Qr Scanner",
    desc: "Using the printable QR scanner, we gather customer reviews.",
    img: "qr-scanner.png",
    link: "scan-qr-code",
    active: true,
  },
  {
    title: "Website",
    desc: "Easily embed our code into your website and let us handle reviews seamlessly.",
    img: "website.png",
    link: "website",
    active: true,
  },
  {
    title: "SMS",
    desc: "We gather reviews from SMS interactions, ensuring no feedback goes unnoticed.",
    img: "sms.png",
    link: "sms",
    active: false,
  },
  {
    title: "Voice",
    desc: "By capturing reviews from calls, we gather insights to assess and enhance customer satisfaction.",
    img: "IVoice.png",
    link: "scan-qr-code",
    active: false,
  },
  {
    title: "Twitter",
    desc: "Through Twitter integrations, we collect reviews to determine satisfaction levels",
    img: "ITwitter.png",
    link: "scan-qr-code",
    active: false,
  },
  {
    title: "Facebook",
    desc: "We display a comprehensive view of feedback gotten from facebook.",
    img: "IFacebook.png",
    link: "scan-qr-code",
    active: false,
  },
  {
    title: "Instagram",
    desc: "We gather reviews from instagram and display them on our platform.",
    img: "IInstagram.png",
    link: "scan-qr-code",
    active: false,
  },
  {
    title: "LinkedIn",
    desc: "We gather customer reviews from LinkedIn, offering valuable insights and fostering trust within the business community.",
    img: "ILinkedIn.png",
    link: "scan-qr-code",
    active: false,
  },
];

export const ratingsImage = (rating: any) => {
  let img = (
    <img src="/media/image/straight-face.png" alt="" className="w-[15px]" />
  );

  switch (rating) {
    case "one":
      img = (
        <img src="/media/image/face-vomiting.png" alt="" className="w-[15px]" />
      );
      break;

    case "two":
      img = (
        <img src="/media/image/thumbs-down.png" alt="" className="w-[15px]" />
      );
      break;

    case "three":
      img = (
        <img src="/media/image/straight-face.png" alt="" className="w-[15px]" />
      );
      break;

    case "four":
      img = (
        <img src="/media/image/thumbs-up.png" alt="" className="w-[15px]" />
      );
      break;

    case "five":
      img = (
        <img src="/media/image/red-heart.png" alt="" className="w-[15px]" />
      );
      break;

    default:
      break;
  }

  return img;
};

interface ReviewStarsProps {
  reviews?: number;
  size?: number;
}

export const ReviewStars = ({ reviews = 5, size = 20 }: ReviewStarsProps) => {
  return (
    <div className="flex items-center justify-between">
      {[0, 1, 2, 3, 4].map((review, index) => {
        return (
          <HiStar
            key={index}
            size={size}
            className={classNames(
              index < reviews ? "text-yellow-500" : "text-gray-400"
            )}
          />
        );
      })}
    </div>
  );
};

export const GOOGLE_API_KEY = "AIzaSyBSFAsX476gxWAkIjsE6V3syvqZBfwwi30";

export const maskEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const { length: len } = name;
  const maskedName = name[0] + '...' + name[len - 1];
  const maskedEmail = maskedName + '@' + domain;
  return maskedEmail;
};

export const encryptMessage = (message: string, publicKey: string) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
 
  return jsEncrypt.encrypt(message);
}

export const decryptMessage = (message: string, privateKey: string) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPrivateKey(privateKey);
 
  return jsEncrypt.decrypt(message);
}

export const QRCODE_COLORS: string[] = [
  "#008000",
  "#0000FF",
  "#FF0000",
  "#800080",
  "#FFC0CB",
  "#808080",
];

export const formatStoreName = (value: string) => {
  let str;

  str = value.split("/");
  var newstore = str[str.length - 1];
  newstore = newstore.replaceAll("-", " ");
  var newStore1 = newstore.split(" ");

  for (var i = 0; i < newStore1.length; i++) {
    newStore1[i] = newStore1[i].charAt(0).toUpperCase() + newStore1[i].slice(1);
  }

  const newStore2 = newStore1.join(" ");

  return newStore2;
};

export const DASHBOARD_HELP_ARRAY = [
  {
    href: "/smart-feedback/customer-review",
    title: "All your reviews in one place",
    description:
      "Easily see all the reviews you get and know what is on the mind of your customers.",
  },
  {
    href: "/settings",
    title: "Manage your settings",
    description:
      "Tailor general settings and notifications to your needs.",
  },
  {
    href: "/smart-feedback/customer-satisfaction",
    title: "See how much your customers love you",
    description:
      "Stay updated with social media mentions and sentiments, and know what your customers think about your brand.",
  },
  {
    href: "/smart-feedback/business-metrics",
    title: "Achieve business goals and KPIs",
    description:
      "Set your business goals and see how well you hit it based on customer feedback.",
  },
  {
    href: "/smart-feedback/update-business-metrics",
    title: "Achieve business goals and KPIs",
    description:
      "Set your business goals and see how well you hit it based on customer feedback.",
  },
  {
    href: "/smart-feedback/help-desk",
    title: "Faster customer support",
    description:
      "Collect and respond to customer complaints about your products and services.",
  },
  {
    href: "/smart-feedback/customer-log",
    title: "All your data in one place",
    description:
      "See all the reviews, support tickets, and customer data in one place.",
  },
  {
    href: "/smart-feedback/ingestion-library",
    title: "Receive feedback from various channels.",
    description:
      "Collect feedback from multiple channels. QR code, Social media, or website, it’s all up to you.",
  },
  {
    href: "/smart-feedback/messages",
    title: "An easier way to communicate",
    description:
      "Send emails or SMS to your customers for any purpose. All their data has been organized for you.",
  },
  {
    href: "/smart-feedback/messages/new-message",
    title: "An easier way to communicate",
    description:
      "Send emails to your customers for any purpose. All their data has been organized for you.",
  },
  {
    href: "/smart-feedback/messages/new-message/sms",
    title: "An easier way to communicate",
    description:
      "Send SMS to your customers for any purpose. All their data has been organized for you.",
  },
  {
    href: "/home/echo-webpage",
    title: "Start selling",
    description:
      "Create an online store to sell your products and services or link your store to Cusecho so you can manage business operations from one place.",
  },
  {
    href: "/home/echo-webpage/upload-url",
    title: "Start selling",
    description:
      "Create an online store to sell your products and services or link your store to Cusecho so you can manage business operations from one place.",
  },
  {
    href: "/home/echo-webpage/customizer",
    title: "Start selling",
    description:
      "Create an online store to sell your products and services or link your store to Cusecho so you can manage business operations from one place.",
  },
  {
    href: "/orders",
    title: "View all your customers orders",
    description:
      "See all your customer's orders at a glance and easily cater to all of them.",
  },
  {
    href: "/home/payments",
    title: "All your earnings in one place",
    description:
      "View all transactions you’ve made and how much you’ve earned from your products and services. Collect feedback from multiple channels. QR code, Social media, or website, it’s all up to you.",
  },
  {
    href: "/home/wallet",
    title: "All your earnings in one place",
    description:
      "View all transactions you’ve made and how much you’ve earned from your products and services. Collect feedback from multiple channels. QR code, Social media, or website, it’s all up to you.",
  },
  {
    href: "/home/payout",
    title: "All your earnings in one place",
    description:
      "View all transactions you’ve made and how much you’ve earned from your products and services. Collect feedback from multiple channels. QR code, Social media, or website, it’s all up to you.",
  },
  {
    href: "/smart-feedback/customer-loyalty",
    title: "Reward your loyal customers",
    description:
      "Create coupon codes or loyalty cards for your loyal customers. Track each offer and its purpose.",
  },
  {
    href: "/task-wizard",
    title: "Get more organized",
    description:
      "Your work, your way. Stay ahead of your operations and easily organize them into tasks for you and your team. Save more time by being more efficient.",
  },
];

export const formatSocialUrl = (value: string) => {
  let result = value;

  if(value !== null && value !== "" && (!value.startsWith("http"))) {
    result = `https://${value}`;
  }

  return result;
};

export const getRelativeTime = (time: any) => {
  const date = new Date();
  const timestamp = date.getTime();
  const seconds = Math.floor(timestamp / 1000);
  const difference = seconds - time;
  let relativeTime = ``;

  if (difference < 3600) {
    // Less than an hour has passed:
    relativeTime = Math.floor(difference / 60) > 1 ? `${Math.floor(difference / 60)} minutes ago` : `${Math.floor(difference / 60)} minute ago`;
    // relativeTime = `${Math.floor(difference / 60)} minutes ago`;
} else if (difference < 86400) {
    // Less than a day has passed:
    relativeTime = Math.floor(difference / 3600) > 1 ? `${Math.floor(difference / 3600)} hours ago` : `${Math.floor(difference / 3600)} hour ago`;
    // relativeTime = `${Math.floor(difference / 3600)} hours ago`;
} else if (difference < 2620800) {
  relativeTime = Math.floor(difference / 86400) > 1 ? `${Math.floor(difference / 86400)} days ago` : `${Math.floor(difference / 86400)} day ago`;
    // Less than a month has passed:
    // relativeTime = `${Math.floor(difference / 86400)} days ago`;
} else if (difference < 31449600) {
    // Less than a year has passed:
    relativeTime = `${Math.floor(difference / 2620800)} months ago`;
} else {
    // More than a year has passed:
    relativeTime = `${Math.floor(difference / 31449600)} years ago`;
}
return relativeTime;
}

export const APP_PERMISSIONS = {
  MOBILE: ["Can edit wallet", "Can edit e-kiosk / e-store", "Can edit orders", "Can edit mobile app settings"],
  FEEDBACK: ["Can view tickets", "Can resolve tickets", "Can view feedback sources", "Can edit feedback sources", "Can edit feedback sources", "Can view KPI", "Can add KPI", "Can view website", "Can edit feedback to website", "Can set ticket priority",],
  REWARD: ["Can view loyalty card", "Can add loyalty card", "Can view coupon", "Can add coupon"],
  MESSAGE: ["Can view message", "Can send message", "Can delete message"],
};

export const SERVICE_SLIDE_SETTINGS = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};