import axios from "axios";
import { API_BASE_URL, API_BASE_URL_PUBLIC } from "../../utils";

const REVIEW_URL = API_BASE_URL;

export const uploadKPI = async (data: any) =>
  axios.post(`${REVIEW_URL}/upload_kpi`, data);

export const editKPISimilarityScore = async (data: any) =>
  axios.post(
    `https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/cusecho_web/sbrp_service/api/external/v1/minimum_similarity_score_edit`,
    data
  );

export const switchKPIMode = async (
  switchMode: any,
  queryKey: any,
  kpiTagName: any,
  questionTag: any,
  questionTagStatus: any
) =>
  axios.get(
    `${REVIEW_URL}/kpi_switch?switchMode=${switchMode}&queryKey=${queryKey}&tagName=${kpiTagName}&questionTag=${questionTag}&questionTagStatus=${questionTagStatus}`
  );

export const getKPIs = async (queryKey: any) =>
  axios.get(`${REVIEW_URL}/download_kpis?queryKey=${queryKey}`);

export const getActiveKPIs = async (data: any) =>
  axios.post(`${REVIEW_URL}/load_active_kpi`, data);

export const getTraceBack = async (data: any) =>
  axios.post(`${REVIEW_URL}/smart-feedback/kpi_traceback`, data);

export const getActiveFeedbackQuestions = async (queryKey: any) =>
  axios.get(
    `${API_BASE_URL_PUBLIC.replace(
      "api/",
      ""
    )}/active_feedback_questions?queryKey=${queryKey}`
  );

export const deleteKPI = async (data: any) =>
  axios.post(`${REVIEW_URL}/delete_kpi_metric`, data);

export const embedKPI = async (data: any) =>
  axios.post(
    `https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/api/v1/client_kpi_embed_request`,
    data
  );

export const getKPIEmbedStatus = async (queryKey: any) =>
  axios.get(
    `https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/api/v1/business_metric_upload_status?queryKey=${queryKey}`
  );
