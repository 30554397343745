import { useNavigate } from "react-router-dom";
import {
  CUSTOMER_ECHO_DATA_SOURCES,
  QRCODE_COLORS,
  classNames,
  formatStoreName,
  getImage,
} from "../../../utils";
import { useRef, useState, Fragment } from "react";
import { StoreModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import {
  ButtonActive,
  RatingActive,
  SupportedImageFormat,
} from "../../../enums";
import * as htmlToImage from "html-to-image";
import { QRCodeSVG } from "qrcode.react";
import { HiOutlineTrash } from "react-icons/hi";
import { useCopyToClipboard } from "react-use";
import { FiInfo } from "react-icons/fi";
import StarsRating from "react-star-rate";
import { Switch } from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import { MdStar } from "react-icons/md";
import { getSiteTitleAndTagLine } from "../../../api/Menu";
import { useQuery } from "@tanstack/react-query";

const ReviewToggle = ({
  checked = false,
  ...props
}: {
  checked?: boolean;
  onChange: (value: any) => void;
}) => {
  return (
    <Switch checked={checked} onChange={props.onChange} as={Fragment}>
      {({ checked }) => (
        <button
          className={`${
            checked ? "bg-[#3DB832]" : "bg-[#D9D9D9]"
          } relative inline-flex h-8 w-28 items-center rounded-full ring-[#D9D9D9] ring-2 ring-offset-2 scale-100`}
        >
          <span className="sr-only"></span>
          <span
            style={{
              backgroundImage: `url("/media/image/${
                checked ? "beaming-face-with-smiling-eyes" : "face-vomiting"
              }.png")`,
            }}
            className={`${
              checked ? "translate-x-20" : "translate-x-2"
            } inline-block h-6 w-6 transform rounded-full bg-cover bg-center bg-no-repeat transition`}
          />
        </button>
      )}
    </Switch>
  );
};

const QRSVGAsset = ({ type = 0 }: { type: number }) => {
  return (
    <>
      {type === 0 ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0761 1.09766C10.524 3.64113 7.73579 8.72809 1 8.72809"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11.0755 19C11.4668 16.4891 13.4429 11.4674 18.2168 11.4674"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11.0755 1C11.4668 4.48913 13.4429 11.4674 18.2168 11.4674"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11.0761 19C10.524 15.5761 7.73579 8.72826 1 8.72826"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="27"
          viewBox="0 0 26 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.9209 11.2772C11.5037 4.62734 3.3358 22.4582 8.08913 25.1009C14.3117 28.5605 19.3002 8.70813 9.60924 3.32015M9.60924 3.32015L10.1881 6.9935M9.60924 3.32015L12.5012 1.89038"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

const QRCodeTab = () => {
  const navigate = useNavigate();
  // const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const [format, setFormat] = useState<SupportedImageFormat>(
    SupportedImageFormat.PNG
  );
  const qrElement = useRef<HTMLDivElement>(HTMLDivElement.prototype);
  const [text, setText] = useState("");
  const [color, setColor] = useState(1);
  const [option, setOption] = useState(0);

  const storeName = formatStoreName(`${store.name}`);

  const queryKey = store.name;

  const [heroDetails, setHeroDetails] = useState({
    siteTitle: storeName,
    tagLine: "",
  });

  const DOWNLOAD_OPTIONS = ["Qr Code", "Qr Card"];

  const DOWNLOAD_FORMATS: SupportedImageFormat[] = [
    SupportedImageFormat.PNG,
    SupportedImageFormat.JPG,
    SupportedImageFormat.SVG,
  ];

  const url = `${window.location.host}/feedback${store.name}`;

  const handleQRCodeDownload = async () => {
    let dataUrl;

    switch (format) {
      case SupportedImageFormat.JPG:
        dataUrl = await htmlToImage.toJpeg(qrElement.current);
        break;
      case SupportedImageFormat.SVG:
        dataUrl = await htmlToImage.toSvg(qrElement.current);
        break;
      default:
        dataUrl = await htmlToImage.toPng(qrElement.current);
        break;
    }

    const link = document.createElement("a");
    link.download = `qr_code.${format}`;
    link.href = dataUrl;
    link.click();
  };

  const handleTextChange = (e: any) => {
    setText(e.target.value);
  };

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(`${queryKey}`);
      return response.data;
    },
    onSuccess: (data: any) => {
      if (data.Site_Title !== "" || data.Tagline !== "") {
        setHeroDetails({
          siteTitle: data.Site_Title,
          tagLine: data.Tagline,
        });
      }
    },
  });

  return (
    <div className="grid content-center justify-center grid-cols-1 gap-5 md:grid-cols-2">
      <div className="px-4 py-5 space-y-4 bg-white border rounded">
        <div className="flex flex-col space-y-1">
          <label className="text-sm font-semibold">Customize text</label>
          <input
            type="text"
            className="w-full xl:w-10/12 py-2.5 px-2 text-xs text-gray-400 bg-white border rounded outline-none placeholder:text-gray-400"
            value={text}
            onChange={handleTextChange}
            maxLength={25}
            placeholder={
              "Scan the QR Code and tell us what you think of our services"
            }
          />
        </div>
        <div className="flex-col hidden space-y-2">
          <label className="text-sm font-semibold">Background image</label>
          <div className="flex items-center justify-between w-full xl:w-10/12">
            <p className="text-xs text-gray-800 underline underline-offset-2">
              default.jpg
            </p>
            <button>
              <HiOutlineTrash size={18} />
            </button>
          </div>
          <input
            type="file"
            className="block w-full text-xs text-gray-900 xl:w-10/12 file:mr-4 file:px-4 file:py-2 file:text-xs file:border-0 file:rounded file:font-semibold file:text-gray-900 file:bg-gray-300 hover:file:bg-gray-300 hover:file:cursor-pointer"
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label className="text-sm font-semibold">Choose color</label>
          <div className="flex space-x-2 overflow-x-auto lg:space-x-2.5">
            {QRCODE_COLORS.map((clr: any, index: number) => {
              const newIndex = index + 1;
              return (
                <button
                  key={index}
                  onClick={() => setColor(newIndex)}
                  className={classNames(
                    "p-3 lg:p-4 border rounded-md border-gray-50",
                    color === newIndex ? "shadow-sm shadow-black" : ""
                  )}
                  style={{ backgroundColor: clr }}
                ></button>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <label className="text-sm font-semibold">Download options</label>
          <div className="flex space-x-3">
            {DOWNLOAD_OPTIONS.map((opt: string, index: number) => {
              return (
                <button
                  key={index}
                  onClick={() => setOption(index)}
                  className={classNames(
                    "px-2 py-1.5 lg:px-3 lg:py-1.5 text-xs rounded font-semibold",
                    option === index
                      ? "bg-blue-600 text-white "
                      : "text-primary bg-transparent border border-primary"
                  )}
                >
                  {opt}
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <label className="text-sm font-semibold">Download format</label>
          <div className="flex space-x-3">
            {DOWNLOAD_FORMATS.map(
              (fmt: SupportedImageFormat, index: number) => {
                return (
                  <button
                    key={index}
                    onClick={() => setFormat(fmt)}
                    className={classNames(
                      "px-2 py-1.5 lg:px-3 lg:py-1.5 text-xs rounded font-semibold uppercase",
                      format === fmt
                        ? "bg-blue-600 text-white "
                        : "text-primary bg-transparent border border-primary"
                    )}
                  >
                    {fmt}
                  </button>
                );
              }
            )}
          </div>
        </div>
        <div className="flex justify-center pt-3 lg:pt-8">
          <button
            onClick={handleQRCodeDownload}
            className="px-8 py-2 text-sm font-semibold text-white rounded-md bg-blue-600"
          >
            Download
          </button>
        </div>
      </div>
      <div className="bg-white rounded py-3 px-5 border flex flex-col space-y-2.5">
        <div
          className={classNames(
            "flex-1 flex flex-col items-center 2xl:h-[70%] h-[90%] justify-center"
          )}
        >
          <div className="w-full mx-auto mb-1 text-left lg:w-3/4 xl:w-2/3">
            <h3 className="font-semibold text-sm text-[#111827]">Preview</h3>
          </div>
          {option === 0 && (
            <div className="w-full lg:w-3/4 xl:w-2/3 min-h-[28rem] lg:min-h-[27rem] mx-auto flex justify-start">
              <div>
                <div
                  id="qrElement"
                  ref={qrElement}
                  className="relative z-10 w-48 p-4 border rounded-md bg-opacity-80 bg-gray-50"
                  style={{ borderColor: QRCODE_COLORS[color - 1] }}
                >
                  <QRCodeSVG
                    id="qrCode"
                    // value={`${window.location.host}/feedback/${base64Encode(
                    //   `${user.platformAccountId}+${user.email}+${user.queryKey}`
                    // )}`}
                    value={url}
                    size={160}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                  />
                </div>
              </div>
            </div>
          )}
          {option === 1 && (
            <div className="w-full lg:w-3/4 xl:w-2/3 min-h-[28rem] lg:min-h-[27rem] mx-auto border border-black rounded overflow-hidden">
              <div
                id="qrElement"
                ref={qrElement}
                className="w-full h-full p-4 overflow-hidden bg-center bg-no-repeat bg-cover bg-gray-100/50 bg-blend-overlay"
                // style={{ backgroundImage: `url("/media/image/qr_bg.png")` }}
              >
                <h4
                  className="text-xl font-semibold text-center"
                  style={{ color: QRCODE_COLORS[color - 1] }}
                >
                  {heroDetails.siteTitle}
                </h4>
                <h6 className="mt-2 text-sm font-semibold text-center text-gray-900 w-[90%] mx-auto">
                  {text !== "" ? (
                    text
                  ) : (
                    <span>
                      Scan the <span className="text-[#096C2B]">QR Code</span>{" "}
                      and tell us what you think about our services
                    </span>
                  )}
                </h6>
                <div className="my-2.5 text-center">
                  <div className="flex justify-center space-x-2">
                    <span
                      className="mt-4"
                      style={{ stroke: QRCODE_COLORS[color - 1] }}
                    >
                      <QRSVGAsset type={0} />
                    </span>
                    <div
                      className="relative z-10 p-2.5 mx-auto border-2 rounded-md w-32 bg-opacity-80 bg-gray-50"
                      style={{ borderColor: QRCODE_COLORS[color - 1] }}
                    >
                      <QRCodeSVG
                        id="qrCode"
                        // value={`${window.location.host}/feedback/${base64Encode(
                        //   `${user.platformAccountId}+${user.email}+${user.queryKey}`
                        // )}`}
                        value={url}
                        size={100}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                      />
                    </div>
                    <span
                      className="mt-24"
                      style={{ stroke: QRCODE_COLORS[color - 1] }}
                    >
                      <QRSVGAsset type={1} />
                    </span>
                  </div>
                  <div>
                    <p className="mr-2.5 text-[10px] italic font-medium text-gray-900">
                      Powered by{" "}
                      <span
                        className="text-sm not-italic font-semibold uppercase"
                        style={{ color: QRCODE_COLORS[color - 1] }}
                      >
                        Cusecho
                      </span>
                    </p>
                  </div>
                </div>
                <h5 className="mt-2.5 text-sm font-semibold text-center text-gray-900">
                  Use the QR code in 3 easy steps
                </h5>
                <div className="flex items-start justify-center space-x-2.5 mt-2">
                  <p className="text-xs font-semibold leading-[18px] text-center">
                    Point your phone camera at your QR Code
                  </p>
                  <p className="text-xs font-semibold text-center leading-[18px]">
                    Tap on the notification that appears
                  </p>
                  <p className="text-xs font-semibold text-center leading-[18px]">
                    Follow the instruction on the screen
                  </p>
                </div>
                <p className="mt-3 text-[10px] italic font-medium text-center text-gray-900">
                  If your android phone does not support QR Code scanning,
                  download a QR Code scanner from the google play store
                </p>
              </div>
            </div>
          )}
          {option === 2 && (
            <div className="w-full lg:w-3/4 2xl:w-1/2 min-h-[28rem] lg:min-h-[27rem] mx-auto border-2 border-black rounded-md overflow-hidden">
              <div
                id="qrElement"
                ref={qrElement}
                className="relative w-full h-full overflow-hidden bg-white bg-no-repeat bg-cover"
                style={{ backgroundImage: `url("/media/image/qr_bg.png")` }}
              >
                <div className="absolute w-[90%] px-4 space-y-4 top-12">
                  <div className="space-y-3 text-xl font-semibold text-gray-900 uppercase">
                    <h6>
                      Hi There,
                      <br />
                      <span style={{ color: QRCODE_COLORS[color - 1] }}>
                        {text !== "" ? text : "How did we do today?"}
                      </span>
                    </h6>
                    <h6 className="text-[#FE9418]">
                      SCAN AND
                      <br />
                      SPEAK YOUR MIND
                    </h6>
                  </div>
                  <div className="">
                    <div className="relative z-10 w-48 p-4 border border-gray-200 rounded-md bg-opacity-80 bg-gray-50">
                      <QRCodeSVG
                        id="qrCode"
                        // value={`${window.location.host}/feedback/${base64Encode(
                        //   `${user.platformAccountId}+${user.email}+${user.queryKey}`
                        // )}`}
                        value={url}
                        size={160}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="absolute top-0 left-0 w-[48%] py-4 rounded-br-3xl"
                  style={{ backgroundColor: QRCODE_COLORS[color - 1] }}
                ></div>
                <div
                  className="absolute bottom-0 right-0 px-4 rounded-tl-3xl h-1/3"
                  style={{ backgroundColor: QRCODE_COLORS[color - 1] }}
                ></div>
                <img
                  src="/media/svg/arrow.svg"
                  alt=""
                  className="absolute w-12 inset-y-1/2 left-56 top-44"
                />
                <div className="absolute left-0 flex items-center w-1/2 gap-2 px-3 py-2 bg-white bottom-1">
                  <span className="text-xs font-semibold rounded-r-md">
                    POWERED BY
                  </span>
                  <img
                    src="/media/image/logo.png"
                    alt=""
                    className="w-[50px]"
                  />{" "}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const WebTab = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState<ButtonActive>(ButtonActive.Floating);
  const [toggle, setToggle] = useState(false);
  const toggleActive = (value: ButtonActive) => {
    setActive(value);
  };
  const [step, setStep] = useState(1);

  const [activeRating, setActiveRating] = useState<RatingActive>(
    RatingActive.Emoji
  );
  const toggleActiveRating = (value: RatingActive) => {
    setActiveRating(value);
  };

  const toggleStep = (value: any) => {
    setStep(value);
  };

  const [clipboard, copyToClipboard] = useCopyToClipboard();
  const codeRef = useRef(HTMLTextAreaElement.prototype);
  const [textFormValues, setTextFormValues] = useState({
    QUESTION: "How would you rate our service?",
    MESSAGE: "",
    // MESSAGE2: "",
  });

  let config: any = {
    API_KEY: "<API_KEY_HERE>",
    FORMAT: {
      LAYOUT: active,
      RATING: activeRating,
    },
    TEXTS: textFormValues,
  };

  if (active === ButtonActive.Inline) {
    config["EMBED_ID"] = "cse-review-widget";
  }

  const code = `
  <script src="${window.location.origin}/widgets/review/js/review.js"></script>
  <script>
    const cseConfig = ${JSON.stringify(config)};
    cseReviewWidgetInit(cseConfig);
  </script>
  `;

  const STEPS = ["Choose Format", "Customise Text", "Get Code"];

  return (
    <div className="grid content-center justify-center grid-cols-1 gap-5 md:grid-cols-2">
      <div className="px-4 py-5 space-y-6 bg-white border-2 rounded">
        <div className="flex items-center w-full space-x-5 overflow-x-auto xl:w-10/12">
          {STEPS.map((stp: string, index: number) => {
            const newIndex = index + 1;
            return (
              <button
                key={index}
                className={classNames(
                  "px-2 pb-1 border-b-[2px]  whitespace-nowrap cursor-pointer text-xs lg:text-sm font-semibold w-full",
                  step >= newIndex
                    ? "border-primary text-gray-900"
                    : "border-gray-300 text-gray-400"
                )}
                onClick={() => toggleStep(newIndex)}
              >
                {stp}
              </button>
            );
          })}
        </div>

        {step === 1 && (
          <div className="py-2 border-gray-200 ">
            <div className="mb-3">
              <p className="flex items-center gap-1 mb-2 text-xs font-semibold fill-gray-400">
                <span>Layout</span>
                <FiInfo size={8} className="mt-0" />
              </p>
              <div className="flex items-center gap-3">
                <button
                  onClick={() => toggleActive(ButtonActive.Inline)}
                  className={classNames(
                    "font-semibold",
                    active === ButtonActive.Inline
                      ? "text-xs py-1.5 px-2.5 border rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-xs py-1.5 px-2.5 border border-primary text-primary rounded-md cursor-pointer"
                  )}
                >
                  Inline
                </button>
                <button
                  onClick={() => toggleActive(ButtonActive.PopUp)}
                  className={classNames(
                    "font-semibold",
                    active === ButtonActive.PopUp
                      ? "text-xs py-1.5 px-2.5 border rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-xs py-1.5 px-2.5 border border-primary text-primary rounded-md cursor-pointer"
                  )}
                >
                  Pop Up
                </button>
                <button
                  onClick={() => toggleActive(ButtonActive.Floating)}
                  className={classNames(
                    "font-semibold",
                    active === ButtonActive.Floating
                      ? "text-xs py-1.5 px-2.5 border rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-xs py-1.5 px-2.5 border border-primary text-primary rounded-md cursor-pointer"
                  )}
                >
                  Floating Button
                </button>
              </div>
            </div>
            <div className="mb-3">
              <p className="flex items-center gap-1 mb-2 text-xs font-semibold fill-gray-400">
                <span>Rating</span>
                <FiInfo size={8} className="mt-0" />
              </p>
              <div className="flex items-center gap-3">
                <button
                  onClick={() => toggleActiveRating(RatingActive.Emoji)}
                  className={classNames(
                    "font-semibold",
                    activeRating === RatingActive.Emoji
                      ? "text-xs py-1.5 px-2.5 border rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-xs py-1.5 px-2.5 border border-primary text-primary rounded-md cursor-pointer"
                  )}
                >
                  Emoji
                </button>
                <button
                  onClick={() => toggleActiveRating(RatingActive.Stars)}
                  className={classNames(
                    "font-semibold",
                    activeRating === RatingActive.Stars
                      ? "text-xs py-1.5 px-2.5 border rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-xs py-1.5 px-2.5 border border-primary text-primary rounded-md cursor-pointer"
                  )}
                >
                  Stars
                </button>
                {/* <p
                  onClick={() => toggleActiveRating(RatingActive.Toggle)}
                  className={
                    activeRating === RatingActive.Toggle
                      ? "text-sm py-2 px-3 border  rounded-md bg-blue-600 text-white cursor-pointer"
                      : "text-sm py-2 px-3 border border-primary text-primary rounded-md cursor-pointer"
                  }
                >
                  Toggle
                </p> */}
              </div>
            </div>
            <div className="flex items-center justify-end mb-4">
              <button
                type="button"
                onClick={() => toggleStep((prev: number) => prev + 1)}
                className="text-white bg-blue-600 font-semibold rounded-md text-xs px-4 py-1.5"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="py-2 border-gray-200">
            <p className="text-sm font-semibold mb-3">Text Customisation</p>
            <div className="mb-4 space-y-0.5">
              <p className="text-[10px]">Customize Review Question</p>

              <input
                type="text"
                value={textFormValues.QUESTION}
                onChange={(e) =>
                  setTextFormValues((prev: any) => {
                    return { ...prev, QUESTION: e.target.value };
                  })
                }
                className="block w-full p-2 py-2.5 text-xs text-gray-900 border border-gray-300 rounded-md outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4 space-y-0.5">
              <p className="text-[10px]">Feedback Response Message</p>
              <input
                type="text"
                value={textFormValues.MESSAGE}
                onChange={(e) =>
                  setTextFormValues((prev: any) => {
                    return { ...prev, MESSAGE: e.target.value };
                  })
                }
                className="block w-full p-2 py-2.5 text-xs text-gray-900 border border-gray-300 rounded-md outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            {/* <div className="mb-3 space-y-1">
              <p className="text-xs">
                Negative Feedback Appreciation Message
              </p>
              <input
                type="text"
                value={textFormValues.MESSAGE2}
                onChange={(e) =>
                  setTextFormValues((prev: any) => {
                    return { ...prev, MESSAGE2: e.target.value };
                  })
                }
                className="border border-gray-300 text-gray-900 text-sm rounded-md outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div> */}
            <div className="flex items-center justify-between mb-4">
              <button
                type="button"
                onClick={() => toggleStep((prev: number) => prev - 1)}
                className="text-primary bg-white border-primary border font-semibold rounded-md text-xs px-4 py-1.5"
              >
                Back
              </button>
              <button
                type="button"
                onClick={() => toggleStep((prev: number) => prev + 1)}
                className="text-white bg-blue-600 font-semibold rounded-md text-xs px-4 py-1.5"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="py-2">
            <p className="mb-2 text-sm font-semibold">Generated Code</p>
            <textarea
              ref={codeRef}
              rows={7}
              className="block px-2.5 py-2.5 w-full text-xs text-left text-gray-900 rounded-md border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500 overflow-hidden read-only:bg-gray-50"
              value={code.trim()}
              readOnly
            />
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => copyToClipboard(codeRef.current.value)}
                className="w-20 py-2 my-3 text-xs font-semibold text-center text-white rounded-md bg-blue-600"
              >
                {clipboard.value ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white rounded py-3 px-5 border-2 flex flex-col justify-center space-y-2.5o">
        <p className="font-semibold text-sm text-[#111827] xl:w-2/3 mx-auto">
          Preview
        </p>
        {active === ButtonActive.Inline && (
          <div className="border border-gray-600 rounded w-full xl:w-2/3 mx-auto mt-1.5">
            <div className="flex items-center justify-center px-2 py-3 text-white bg-blue-600">
              <div className="space-y-2 text-center 2xl:space-y-4">
                <div className="flex items-center justify-center">
                  <img
                    src="/media/image/hat-face.png"
                    alt=""
                    className="w-9 lg:w-12"
                  />
                </div>
                <p className="text-lg lg:text-xl">Leave Us Feedback</p>
                <p className="text-xs">We are always looking to improve</p>
              </div>
            </div>
            <div className="px-3 py-4 space-y-3 2xl:space-y-6">
              <p className="text-xs text-center text-primary">
                {textFormValues.QUESTION}
              </p>
              {activeRating === RatingActive.Stars && (
                <div className="flex items-center justify-center">
                  <StarsRating
                    allowHalf={false}
                    // value={rate}
                    // onChange={(rate) => {
                    //   setRate(rate);
                    // }}
                  />
                </div>
              )}

              {activeRating === RatingActive.Emoji && (
                <div className="flex items-center justify-evenly">
                  <img
                    src="/media/image/thumbs-up.png"
                    alt=""
                    className="w-6"
                  />
                  <img
                    src="/media/image/thumbs-down.png"
                    alt=""
                    className="w-6"
                  />
                  <img
                    src="/media/image/red-heart.png"
                    alt=""
                    className="w-6"
                  />
                  <img
                    src="/media/image/face-vomiting.png"
                    alt=""
                    className="w-6"
                  />
                  <img
                    src="/media/image/straight-face.png"
                    alt=""
                    className="w-6"
                  />
                </div>
              )}

              {activeRating === RatingActive.Toggle && (
                <div className="flex items-center justify-center">
                  <ReviewToggle
                    checked={toggle}
                    onChange={() => setToggle(!toggle)}
                  />
                </div>
              )}

              <p className="text-xs text-center text-primary">
                Leave an additional message if you want to
              </p>
              <textarea
                id="message"
                rows={3}
                className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded-md outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Type Your Message Here"
              ></textarea>
              <button
                type="button"
                className="text-center w-[40%] mx-auto block text-white bg-blue-600 font-medium rounded-md text-xs py-2"
              >
                Send
              </button>
            </div>
          </div>
        )}

        {active === ButtonActive.PopUp && (
          // <div className="border border-gray-600 rounded">
          //   <div className="flex items-center justify-center px-2 py-3 text-white bg-blue-600">
          //     <div className="space-y-2 text-center">
          //       <div className="flex items-center justify-center">
          //         <img src="/media/image/hat-face.png" alt="" className="" />
          //       </div>
          //       <p className="text-2xl">Leave Us Pop</p>
          //       <p>We are always looking to improve</p>
          //     </div>
          //   </div>
          //   <div className="px-3 py-4 space-y-3">
          //     <p className="text-center text-primary">
          //       How Would You Rate Our Service?
          //     </p>
          //     {activeRating === RatingActive.Stars && (
          //       <div className="flex items-center justify-center">
          //         <StarsRating
          //           allowHalf={false}
          //           // value={rate}
          //           // onChange={(rate) => {
          //           //   setRate(rate);
          //           // }}
          //         />
          //       </div>
          //     )}

          //     {activeRating === RatingActive.Emoji && (
          //       <div className="flex items-center justify-evenly">
          //         <img src="/media/image/thumbs-up.png" alt="" />
          //         <img src="/media/image/thumbs-down.png" alt="" />
          //         <img src="/media/image/red-heart.png" alt="" />
          //         <img src="/media/image/face-vomiting.png" alt="" />
          //         <img src="/media/image/straight-face.png" alt="" />
          //       </div>
          //     )}
          //     <p className="text-center text-primary">
          //       Leave An Additional Message If You Want To
          //     </p>
          //     <textarea
          //       id="message"
          //       rows={4}
          //       className="block p-2.5 w-full text-sm text-gray-900 rounded-md border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500"
          //       placeholder="Type Your Message Here"
          //     ></textarea>
          //     <div className="flex justify-center">
          //       <button
          //         type="button"
          //         className="text-center max-w-[100px] mx-auto text-white bg-blue-600  font-medium rounded-md text-sm px-10 py-2.5"
          //       >
          //         Send
          //       </button>
          //     </div>
          //   </div>
          // </div>
          <div className="w-full mx-auto space-y-4 xl:w-2/3">
            <div>
              <p className="text-xs font-semibold">Ratings</p>
              <div className="px-3 py-4 space-y-3 border mt-1.5 rounded-md">
                <div className="flex justify-end">
                  <IoClose size={20} className="" />
                </div>
                <p className="text-sm text-center text-primary">
                  {textFormValues.QUESTION}
                </p>
                {/* <div className="flex items-center justify-evenly">
                  <img src="/media/image/thumbs-up.png" alt="" />
                  <img src="/media/image/thumbs-down.png" alt="" />
                  <img src="/media/image/red-heart.png" alt="" />
                  <img src="/media/image/face-vomiting.png" alt="" />
                  <img src="/media/image/straight-face.png" alt="" />
                </div> */}
                {activeRating === RatingActive.Stars && (
                  <div className="flex items-center justify-center">
                    <StarsRating
                      allowHalf={false}
                      // value={rate}
                      // onChange={(rate) => {
                      //   setRate(rate);
                      // }}
                    />
                  </div>
                )}

                {activeRating === RatingActive.Emoji && (
                  <div className="flex items-center justify-evenly">
                    <img
                      src="/media/image/thumbs-up.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/thumbs-down.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/red-heart.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/face-vomiting.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/straight-face.png"
                      alt=""
                      className="w-6"
                    />
                  </div>
                )}

                {activeRating === RatingActive.Toggle && (
                  <div className="flex items-center justify-center">
                    <ReviewToggle
                      checked={toggle}
                      onChange={() => setToggle(!toggle)}
                    />
                  </div>
                )}
                <div />
              </div>
            </div>
            <div>
              <p className="text-xs font-semibold">Additional Message</p>
              <div className="px-3 py-4 space-y-3 border mt-1.5 rounded-md">
                <div className="flex justify-end">
                  <IoClose size={20} className="" />
                </div>
                {/* <div className="flex items-center opacity-50 justify-evenly">
                  <img src="/media/image/thumbs-up.png" alt="" />
                  <img src="/media/image/thumbs-down.png" alt="" />
                  <img src="/media/image/red-heart.png" alt="" />
                  <img src="/media/image/face-vomiting.png" alt="" />
                  <img src="/media/image/straight-face.png" alt="" />
                </div> */}
                {activeRating === RatingActive.Stars && (
                  <div className="flex items-center justify-center">
                    <StarsRating
                      allowHalf={false}
                      // value={rate}
                      // onChange={(rate) => {
                      //   setRate(rate);
                      // }}
                    />
                  </div>
                )}

                {activeRating === RatingActive.Emoji && (
                  <div className="flex items-center justify-evenly">
                    <img
                      src="/media/image/thumbs-up.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/thumbs-down.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/red-heart.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/face-vomiting.png"
                      alt=""
                      className="w-6"
                    />
                    <img
                      src="/media/image/straight-face.png"
                      alt=""
                      className="w-6"
                    />
                  </div>
                )}

                {activeRating === RatingActive.Toggle && (
                  <div className="flex items-center justify-center">
                    <ReviewToggle
                      checked={toggle}
                      onChange={() => setToggle(!toggle)}
                    />
                  </div>
                )}
                <p className="text-xs text-center text-primary">
                  Leave An Additional Message
                </p>
                <textarea
                  id="message"
                  rows={3}
                  className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded-md outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Type Your Message Here"
                ></textarea>
                <button
                  type="button"
                  className="text-center max-w-[100px] mx-auto block text-white bg-blue-600 font-medium rounded-md text-xs px-6 py-2"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}

        {active === ButtonActive.Floating && (
          <div className="w-full mx-auto space-y-4 xl:w-2/3">
            <div>
              {/* <p className="font-semibold">Button Preview</p> */}
              <button className="text-center text-white bg-blue-600 font-medium rounded-md text-xs px-6 py-2.5 mt-1.5 flex items-center gap-2">
                <MdStar className="text-yellow-500" />{" "}
                <span>Leave A Review</span>{" "}
                <MdStar className="text-yellow-500" />
              </button>
            </div>
            <div>
              <div className="border border-gray-600 rounded">
                <div className="flex items-center justify-center px-2 py-3 text-white bg-blue-600">
                  <div className="space-y-2 text-center">
                    <div className="flex items-center justify-center">
                      <img
                        src="/media/image/hat-face.png"
                        alt=""
                        className="w-8 lg:w-10"
                      />
                    </div>
                    <p className="text-xl">Leave Us Feedback</p>
                    <p className="text-xs">We are always looking to improve</p>
                  </div>
                </div>
                <div className={classNames("px-3 py-4 space-y-3")}>
                  <p className="text-xs text-center text-primary">
                    {textFormValues.QUESTION}
                  </p>
                  {activeRating === RatingActive.Stars && (
                    <div className="flex items-center justify-center">
                      <StarsRating
                        allowHalf={false}
                        // value={rate}
                        // onChange={(rate) => {
                        //   setRate(rate);
                        // }}
                      />
                    </div>
                  )}

                  {activeRating === RatingActive.Emoji && (
                    <div className="flex items-center mx-auto max-w-[200px] justify-evenly">
                      <img
                        src="/media/image/thumbs-up.png"
                        alt=""
                        className="w-6"
                      />
                      <img
                        src="/media/image/thumbs-down.png"
                        alt=""
                        className="w-6"
                      />
                      <img
                        src="/media/image/red-heart.png"
                        alt=""
                        className="w-6"
                      />
                      <img
                        src="/media/image/face-vomiting.png"
                        alt=""
                        className="w-6"
                      />
                      <img
                        src="/media/image/straight-face.png"
                        alt=""
                        className="w-6"
                      />
                    </div>
                  )}

                  {activeRating === RatingActive.Toggle && (
                    <div className="flex items-center justify-center">
                      <ReviewToggle
                        checked={toggle}
                        onChange={() => setToggle(!toggle)}
                      />
                    </div>
                  )}
                  <p className="text-xs text-center text-primary">
                    Leave an additional message if you want to
                  </p>
                  <textarea
                    id="message"
                    rows={3}
                    className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded-md outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Type Your Message Here"
                  ></textarea>
                  <button
                    type="button"
                    className="block py-2 mx-auto text-xs font-medium text-center text-white rounded-md w-28 bg-blue-600"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const UnavailableTab = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col items-center justify-center space-y-1">
        <img
          src="/media/svg/source_coming_soon.svg"
          alt=""
          className="w-24 h-24 mr-5 sm:w-32 sm:h-32"
        />
        <h4 className="text-sm italic font-medium text-center text-gray-400 sm:text-lg">
          This feature is currently unavailable, <br />
          but it's on its way!
        </h4>
      </div>
    </div>
  );
};

const IngestionLibraryPage = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // nextArrow: <BsFillArrowRightCircleFill />,
    // prevArrow: <BsFillArrowRightCircleFill />,
  };

  // return (
  //   <div className="space-y-1">
  //     {/* <PageHeader title="Ingestion Library" /> */}
  //     <div className="h-[85vh] 2xl:h-[70vh] py-3 px-5 bg-white overflow-y-auto rounded shadow-md overflow-x-hidden flex flex-col relative">
  //       <div>
  //         <div className="max-w-[500px] mx-auto flex items-center justify-between">
  //           <img
  //             src="/media/image/IL1.png"
  //             alt=""
  //             className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //             onClick={() =>
  //               navigate("/smart-feedback/ingestion-library/website")
  //             }
  //           />
  //           <img
  //             src="/media/image/IL2.png"
  //             alt=""
  //             className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //           />
  //         </div>
  //         <div className="max-w-[700px] mx-auto flex items-center justify-between">
  //           <img
  //             src="/media/image/IL3.png"
  //             alt=""
  //             className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //           />
  //           <img
  //             src="/media/image/ILM.png"
  //             alt=""
  //             className="hover:scale-125 transition-all duration-500 cursor-pointer w-[110px]"
  //             onClick={() =>
  //               navigate("/smart-feedback/customer-satisfaction")
  //             }
  //           />
  //           <img
  //             src="/media/image/IL4.png"
  //             alt=""
  //             className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //           />
  //         </div>
  //         <div className="max-w-[1000px] mx-auto flex items-center justify-between">
  //           <div className="w-[350px] flex items-center justify-between">
  //             <img
  //               src="/media/image/IL5.png"
  //               alt=""
  //               className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //             />
  //             <img
  //               src="/media/image/IL6.png"
  //               alt=""
  //               className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //               onClick={() =>
  //                 navigate("/smart-feedback/ingestion-library/scan-qr-code")
  //               }
  //             />
  //           </div>
  //           <div className="w-[350px] flex items-center justify-between">
  //             <img
  //               src="/media/image/IL7.png"
  //               alt=""
  //               className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //             />
  //             <img
  //               src="/media/image/IL8.png"
  //               alt=""
  //               className="hover:scale-125 transition-all duration-500 cursor-pointer w-[70px]"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="flex-1">
  //         <div className="max-w-[400px] mx-auto text-center mt-6 mb-2 2xl:mt-24">
  //           <p className="text-2xl font-semibold">Feedback Sources</p>
  //           <p className="text-sm font-medium leading-tight text-gray-700">
  //             You can receive feedback from different ingestion points using
  //             Cusecho
  //           </p>
  //         </div>

  //         <div className="px-3 mt-8">
  //           <Slider {...settings}>
  //             {CUSECHO_DATA_INJESTION_SOURCES.map(
  //               (source: any, index: number) => {
  //                 return (
  //                   <div
  //                     key={index}
  //                     className={classNames(
  //                       "border rounded",
  //                       !source.active ? "bg-slate-50 opacity-80" : ""
  //                     )}
  //                   >
  //                     <div className="grid w-full grid-flow-col justify-items-stretch 2xl:justify-items-center">
  //                       <div className="p-2">
  //                         <img
  //                           src={`/media/image/${source.img}`}
  //                           alt=""
  //                           className="w-full"
  //                         />
  //                       </div>
  //                       <div className="flex flex-col justify-center p-3 space-y-2 text-left">
  //                         <h1 className="text-lg font-semibold text-gray-800">
  //                           {source.title}
  //                         </h1>
  //                         <p className="text-xs">{source.desc}</p>
  //                         <div className="flex">
  //                           <button
  //                             type="button"
  //                             className="px-4 py-1.5 text-sm font-medium text-white rounded bg-blue-600"
  //                             disabled={!source.active}
  //                             onClick={() =>
  //                               navigate(
  //                                 `/smart-feedback/ingestion-library/${source.link}`
  //                               )
  //                             }
  //                           >
  //                             View More
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 );
  //               }
  //             )}
  //           </Slider>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="space-y-1 hidden">
        <div className="overflow-y-hidden relative">
          <div className="w-full">
            <div className="h-full mx-auto">
              <img
                src={getImage("updating-bg")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
            <h4 className="text-6xl font-semibold uppercase ">Updating</h4>
            <p className="mt-2 text-lg font-medium max-w-[480px]">
              This feature is temporarily unavailable. We’re improving to give
              you a better experience.
            </p>
          </div>
        </div>
      </div>
      
      <div className="bg-white rounded h-[85vh] space-y-4 overflow-hidden flex flex-col">
        <div className="space-y-4 md:space-y-2">
          <div className="">
            <h4 className="text-base font-semibold text-gray-900">
              Data streams
            </h4>
          </div>
        </div>
        <div className="flex flex-col flex-1 w-full space-y-4 overflow-hidden">
          <div className="flex items-stretch w-full space-x-5 overflow-x-auto md:items-center">
            {CUSTOMER_ECHO_DATA_SOURCES.map((source: any, index: number) => (
              <button
                key={index}
                onClick={() => setTab(index)}
                className={classNames(
                  "capitalize px-2 py-1 font-semibold border-b-2 outline-none text-sm whitespace-nowrap",
                  tab === index
                    ? "text-gray-900 border-primary"
                    : "text-slate-500 border-transparent"
                )}
              >
                {source.name}
              </button>
            ))}
          </div>
          <div className="flex-1 px-1 overflow-y-auto">
            {
              {
                0: <QRCodeTab />,
                1: <WebTab />,
                2: <UnavailableTab />,
                3: <UnavailableTab />,
                4: <UnavailableTab />,
                5: <UnavailableTab />,
                6: <UnavailableTab />,
                7: <UnavailableTab />,
              }[tab]
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default IngestionLibraryPage;
