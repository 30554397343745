import { Fragment, useRef, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { FiInfo } from "react-icons/fi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Disclosure, Switch } from "@headlessui/react";
import StarsRating from "react-star-rate";
import { IoClose } from "react-icons/io5";
import { MdStar } from "react-icons/md";
import { useCopyToClipboard } from "react-use";
import { classNames, getImage } from "../../../../utils";
import { ButtonActive, RatingActive } from "../../../../enums";



const ReviewToggle = ({
  checked = false,
  ...props
}: {
  checked?: boolean;
  onChange: (value: any) => void;
}) => {
  return (
    <Switch checked={checked} onChange={props.onChange} as={Fragment}>
      {({ checked }) => (
        <button
          className={`${
            checked ? "bg-[#3DB832]" : "bg-[#D9D9D9]"
          } relative inline-flex h-8 w-28 items-center rounded-full ring-[#D9D9D9] ring-2 ring-offset-2 scale-100`}
        >
          <span className="sr-only"></span>
          <span
            style={{
              backgroundImage: `url("/media/image/${
                checked ? "beaming-face-with-smiling-eyes" : "face-vomiting"
              }.png")`,
            }}
            className={`${
              checked ? "translate-x-20" : "translate-x-2"
            } inline-block h-6 w-6 transform rounded-full bg-cover bg-center bg-no-repeat transition`}
          />
        </button>
      )}
    </Switch>
  );
};

const IngestionLibraryWebsite = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState<ButtonActive>(ButtonActive.Floating);
  const [toggle, setToggle] = useState(false);
  const toggleActive = (value: ButtonActive) => {
    setActive(value);
  };
  const [step, setStep] = useState(1);

  const [activeRating, setActiveRating] = useState<RatingActive>(
    RatingActive.Emoji
  );
  const toggleActiveRating = (value: RatingActive) => {
    setActiveRating(value);
  };

  const toggleStep = (value: any) => {
    setStep(value);
  };

  const [clipboard, copyToClipboard] = useCopyToClipboard();
  const codeRef = useRef(HTMLTextAreaElement.prototype);
  const [textFormValues, setTextFormValues] = useState({
    QUESTION: "How would you rate our service?",
    MESSAGE1: "",
    MESSAGE2: "",
  });

  let config: any = {
    API_KEY: "<API_KEY_HERE>",
    FORMAT: {
      LAYOUT: active,
      RATING: activeRating,
    },
    TEXTS: textFormValues,
  };

  if (active === ButtonActive.Inline) {
    config["EMBED_ID"] = "cse-review-widget";
  }

  const code = `
  <script src="${window.location.origin}/widgets/review/js/review.js"></script>
  <script>
    const cseConfig = ${JSON.stringify(config)};
    cseReviewWidgetInit(cseConfig);
  </script>
  `;

  return (
    <div className="space-y-1">
      <div className="flex items-center gap-3 py-1">
        <div
          className="p-2 text-gray-800 bg-white rounded cursor-pointer"
          onClick={() => navigate("/smart-feedback/ingestion-library")}
        >
          <BiChevronLeft />
        </div>
        <h4 className="text-xl text-gray-400 capitalize">Website</h4>
      </div>
      <div className="grid content-center justify-center grid-cols-2 gap-5 lg:grid-cols-5">
        <div className="h-[80vh] bg-white rounded-md shadow-md col-span-1 lg:col-span-3 overflow-y-auto">
          <div className="py-3 px-5 border-b-[1px] border-gray-200">
            <p className="font-semibold ">Customise Website Review</p>
            <p className="text-sm text-gray-600">
              Customize your Website review to fit your brand
            </p>
          </div>

          <div>
            <div className="flex items-center space-x-5 max-w-[90%] lg:max-w-[80%] py-3 px-5">
              <p
                className={classNames(
                  "rounded px-2 py-1 border-t-4 font-medium cursor-pointer text-sm lg:text-base",
                  step >= 1 ? "border-purple-700" : "border-gray-300"
                )}
                onClick={() => toggleStep(1)}
              >
                Customise Format
              </p>
              <p
                className={classNames(
                  "rounded px-2 py-1 border-t-4 font-medium cursor-pointer text-sm lg:text-base",
                  step >= 2 ? "border-purple-700" : "border-gray-300"
                )}
                onClick={() => toggleStep(2)}
              >
                Customise Text
              </p>
              <p
                className={classNames(
                  "rounded px-2 py-1 border-t-4 font-medium cursor-pointer text-sm lg:text-base",
                  step >= 3 ? "border-purple-700" : "border-gray-300"
                )}
                onClick={() => toggleStep(3)}
              >
                Get Code
              </p>
            </div>
          </div>

          {step === 1 && (
            <div className="px-5 py-3 border-gray-200 ">
              <p className="font-semibold">Customise Format</p>
              <div className="mb-3">
                <p className="flex items-center gap-1 mb-2 text-sm fill-gray-400">
                  <span>Layout</span>
                  <FiInfo size={11} className="mt-0.5" />
                </p>
                <div className="flex items-center gap-3">
                  <p
                    onClick={() => toggleActive(ButtonActive.Inline)}
                    className={
                      active === ButtonActive.Inline
                        ? "text-sm py-2 px-3 border rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Inline
                  </p>
                  <p
                    onClick={() => toggleActive(ButtonActive.PopUp)}
                    className={
                      active === ButtonActive.PopUp
                        ? "text-sm py-2 px-3 border rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Pop Up
                  </p>
                  <p
                    onClick={() => toggleActive(ButtonActive.Floating)}
                    className={
                      active === ButtonActive.Floating
                        ? "text-sm py-2 px-3 border rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Floating Button
                  </p>
                </div>
              </div>
              <div className="mb-3">
                <p className="flex items-center gap-1 mb-2 text-sm fill-gray-400">
                  <span>Rating</span>
                  <FiInfo size={11} className="mt-0.5" />
                </p>
                <div className="flex items-center gap-3">
                  <p
                    onClick={() => toggleActiveRating(RatingActive.Emoji)}
                    className={
                      activeRating === RatingActive.Emoji
                        ? "text-sm py-2 px-3 border  rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Emoji
                  </p>
                  <p
                    onClick={() => toggleActiveRating(RatingActive.Stars)}
                    className={
                      activeRating === RatingActive.Stars
                        ? "text-sm py-2 px-3 border  rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Stars
                  </p>
                  <p
                    onClick={() => toggleActiveRating(RatingActive.Toggle)}
                    className={
                      activeRating === RatingActive.Toggle
                        ? "text-sm py-2 px-3 border  rounded-lg bg-primary text-white cursor-pointer"
                        : "text-sm py-2 px-3 border border-purple-500 rounded-lg cursor-pointer"
                    }
                  >
                    Toggle
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-end mb-4">
                <button
                  type="button"
                  onClick={() => toggleStep((prev: number) => prev + 1)}
                  className="text-white bg-primary font-medium rounded text-sm px-5 py-2.5"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="px-5 py-3 border-gray-200">
              <p className="font-semibold">Text Customisation</p>
              <div className="mb-3 space-y-1">
                <p className="text-xs">Customize Review Question</p>

                <input
                  type="text"
                  value={textFormValues.QUESTION}
                  onChange={(e) =>
                    setTextFormValues((prev: any) => {
                      return { ...prev, QUESTION: e.target.value };
                    })
                  }
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div className="mb-3 space-y-1">
                <p className="text-xs">
                  Positive Feedback Appreciation Message
                </p>
                <input
                  type="text"
                  value={textFormValues.MESSAGE1}
                  onChange={(e) =>
                    setTextFormValues((prev: any) => {
                      return { ...prev, MESSAGE1: e.target.value };
                    })
                  }
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div className="mb-3 space-y-1">
                <p className="text-xs">
                  Negative Feedback Appreciation Message
                </p>
                <input
                  type="text"
                  value={textFormValues.MESSAGE2}
                  onChange={(e) =>
                    setTextFormValues((prev: any) => {
                      return { ...prev, MESSAGE2: e.target.value };
                    })
                  }
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => toggleStep((prev: number) => prev - 1)}
                  className="text-white bg-primary font-medium rounded text-sm px-5 py-2.5"
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={() => toggleStep((prev: number) => prev + 1)}
                  className="text-white bg-primary font-medium rounded text-sm px-5 py-2.5"
                >
                  View Code
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="px-5 py-3">
              <p className="mb-2 font-semibold">Generated Code</p>
              <textarea
                ref={codeRef}
                rows={7}
                className="block px-2.5 py-2.5 w-full text-sm text-left text-gray-900 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500 overflow-hidden read-only:bg-gray-50"
                value={code.trim()}
                readOnly
              />
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => copyToClipboard(codeRef.current.value)}
                  className="text-white bg-primary text-center font-medium rounded-lg text-sm w-24 py-2.5 my-3"
                >
                  {clipboard.value ? "Copied!" : "Copy"}
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="h-[80vh] bg-white rgounded-md shadow-md py-3 px-5 col-span-1 lg:col-span-2 overflow-y-auto">
          <p className="font-semibold">Preview</p>
          <p className="mb-3 text-sm text-gray-600">
            Here is A Preview of Your Website Review
          </p>

          {active === ButtonActive.Inline && (
            <div className="border-2 border-gray-800 rounded 2xl:h-3/4">
              <div className="flex items-center justify-center px-2 py-3 text-white bg-primary">
                <div className="space-y-2 text-center 2xl:space-y-4">
                  <div className="flex items-center justify-center">
                    <img
                      src="/media/image/hat-face.png"
                      alt=""
                      className="w-11 lg:w-14"
                    />
                  </div>
                  <p className="text-xl lg:text-2xl">Leave Us Feedback</p>
                  <p className="text-sm lg:text-base">
                    We are always looking to improve
                  </p>
                </div>
              </div>
              <div className="px-3 py-4 space-y-3 2xl:space-y-6">
                <p className="text-sm text-center text-primary lg:text-base">
                  {textFormValues.QUESTION}
                </p>
                {activeRating === RatingActive.Stars && (
                  <div className="flex items-center justify-center">
                    <StarsRating
                      allowHalf={false}
                      // value={rate}
                      // onChange={(rate) => {
                      //   setRate(rate);
                      // }}
                    />
                  </div>
                )}

                {activeRating === RatingActive.Emoji && (
                  <div className="flex items-center justify-evenly">
                    <img
                      src="/media/image/thumbs-up.png"
                      alt=""
                      className="w-8 lg:w-10"
                    />
                    <img
                      src="/media/image/thumbs-down.png"
                      alt=""
                      className="w-8 lg:w-10"
                    />
                    <img
                      src="/media/image/red-heart.png"
                      alt=""
                      className="w-8 lg:w-10"
                    />
                    <img
                      src="/media/image/face-vomiting.png"
                      alt=""
                      className="w-8 lg:w-10"
                    />
                    <img
                      src="/media/image/straight-face.png"
                      alt=""
                      className="w-8 lg:w-10"
                    />
                  </div>
                )}

                {activeRating === RatingActive.Toggle && (
                  <div className="flex items-center justify-center">
                    <ReviewToggle
                      checked={toggle}
                      onChange={() => setToggle(!toggle)}
                    />
                  </div>
                )}

                <p className="text-sm text-center text-primary lg:text-base">
                  Leave an additional message if you want to
                </p>
                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Type Your Message Here"
                ></textarea>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="text-center w-[40%] mx-auto text-white bg-primary font-medium rounded-lg text-sm py-2.5"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}

          {active === ButtonActive.PopUp && (
            // <div className="border-2 border-gray-800 rounded">
            //   <div className="flex items-center justify-center px-2 py-3 text-white bg-primary">
            //     <div className="space-y-2 text-center">
            //       <div className="flex items-center justify-center">
            //         <img src="/media/image/hat-face.png" alt="" className="" />
            //       </div>
            //       <p className="text-2xl">Leave Us Pop</p>
            //       <p>We are always looking to improve</p>
            //     </div>
            //   </div>
            //   <div className="px-3 py-4 space-y-3">
            //     <p className="text-center text-primary">
            //       How Would You Rate Our Service?
            //     </p>
            //     {activeRating === RatingActive.Stars && (
            //       <div className="flex items-center justify-center">
            //         <StarsRating
            //           allowHalf={false}
            //           // value={rate}
            //           // onChange={(rate) => {
            //           //   setRate(rate);
            //           // }}
            //         />
            //       </div>
            //     )}

            //     {activeRating === RatingActive.Emoji && (
            //       <div className="flex items-center justify-evenly">
            //         <img src="/media/image/thumbs-up.png" alt="" />
            //         <img src="/media/image/thumbs-down.png" alt="" />
            //         <img src="/media/image/red-heart.png" alt="" />
            //         <img src="/media/image/face-vomiting.png" alt="" />
            //         <img src="/media/image/straight-face.png" alt="" />
            //       </div>
            //     )}
            //     <p className="text-center text-primary">
            //       Leave An Additional Message If You Want To
            //     </p>
            //     <textarea
            //       id="message"
            //       rows={4}
            //       className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500"
            //       placeholder="Type Your Message Here"
            //     ></textarea>
            //     <div className="flex justify-center">
            //       <button
            //         type="button"
            //         className="text-center max-w-[100px] mx-auto text-white bg-primary  font-medium rounded-lg text-sm px-10 py-2.5"
            //       >
            //         Send
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <div className="space-y-4">
              <div>
                <p className="font-semibold">Ratings</p>
                <div className="px-3 py-4 space-y-3 border mt-1.5 rounded-lg">
                  <div className="flex justify-end">
                    <IoClose size={24} className="" />
                  </div>
                  <p className="text-center text-primary">
                    {textFormValues.QUESTION}
                  </p>
                  {/* <div className="flex items-center justify-evenly">
                    <img src="/media/image/thumbs-up.png" alt="" />
                    <img src="/media/image/thumbs-down.png" alt="" />
                    <img src="/media/image/red-heart.png" alt="" />
                    <img src="/media/image/face-vomiting.png" alt="" />
                    <img src="/media/image/straight-face.png" alt="" />
                  </div> */}
                  {activeRating === RatingActive.Stars && (
                    <div className="flex items-center justify-center">
                      <StarsRating
                        allowHalf={false}
                        // value={rate}
                        // onChange={(rate) => {
                        //   setRate(rate);
                        // }}
                      />
                    </div>
                  )}

                  {activeRating === RatingActive.Emoji && (
                    <div className="flex items-center justify-evenly">
                      <img src="/media/image/thumbs-up.png" alt="" />
                      <img src="/media/image/thumbs-down.png" alt="" />
                      <img src="/media/image/red-heart.png" alt="" />
                      <img src="/media/image/face-vomiting.png" alt="" />
                      <img src="/media/image/straight-face.png" alt="" />
                    </div>
                  )}

                  {activeRating === RatingActive.Toggle && (
                    <div className="flex items-center justify-center">
                      <ReviewToggle
                        checked={toggle}
                        onChange={() => setToggle(!toggle)}
                      />
                    </div>
                  )}
                  <div />
                </div>
              </div>
              <div>
                <p className="font-semibold">Additional Message</p>
                <div className="px-3 py-4 space-y-3 border mt-1.5 rounded-lg">
                  <div className="flex justify-end">
                    <IoClose size={24} className="" />
                  </div>
                  {/* <div className="flex items-center opacity-50 justify-evenly">
                    <img src="/media/image/thumbs-up.png" alt="" />
                    <img src="/media/image/thumbs-down.png" alt="" />
                    <img src="/media/image/red-heart.png" alt="" />
                    <img src="/media/image/face-vomiting.png" alt="" />
                    <img src="/media/image/straight-face.png" alt="" />
                  </div> */}
                  {activeRating === RatingActive.Stars && (
                    <div className="flex items-center justify-center">
                      <StarsRating
                        allowHalf={false}
                        // value={rate}
                        // onChange={(rate) => {
                        //   setRate(rate);
                        // }}
                      />
                    </div>
                  )}

                  {activeRating === RatingActive.Emoji && (
                    <div className="flex items-center justify-evenly">
                      <img src="/media/image/thumbs-up.png" alt="" />
                      <img src="/media/image/thumbs-down.png" alt="" />
                      <img src="/media/image/red-heart.png" alt="" />
                      <img src="/media/image/face-vomiting.png" alt="" />
                      <img src="/media/image/straight-face.png" alt="" />
                    </div>
                  )}

                  {activeRating === RatingActive.Toggle && (
                    <div className="flex items-center justify-center">
                      <ReviewToggle
                        checked={toggle}
                        onChange={() => setToggle(!toggle)}
                      />
                    </div>
                  )}
                  <p className="text-center text-primary">
                    Leave An Additional Message
                  </p>
                  <textarea
                    id="message"
                    rows={4}
                    className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Type Your Message Here"
                  ></textarea>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="text-center max-w-[100px] mx-auto text-white bg-primary font-medium rounded-lg text-sm px-10 py-2.5"
                    >
                      Send
                    </button>
                  </div>
                  <div />
                </div>
              </div>
            </div>
          )}

          {active === ButtonActive.Floating && (
            <div className="space-y-4">
              <div>
                <p className="font-semibold">Button Preview</p>
                <button className="text-center text-white bg-primary font-medium rounded-lg text-sm px-10 py-2.5 mt-1.5 flex items-center gap-2">
                  <MdStar className="text-yellow-500" />{" "}
                  <span>Leave A Review</span>{" "}
                  <MdStar className="text-yellow-500" />
                </button>
              </div>
              <div>
                <div className="border-2 border-gray-800 rounded">
                  <div className="flex items-center justify-center px-2 py-3 text-white bg-primary">
                    <div className="space-y-2 text-center">
                      <div className="flex items-center justify-center">
                        <img
                          src="/media/image/hat-face.png"
                          alt=""
                          className=""
                        />
                      </div>
                      <p className="text-2xl">Leave Us Feedback</p>
                      <p>We are always looking to improve</p>
                    </div>
                  </div>
                  <div className={classNames("px-3 py-4 space-y-3")}>
                    <p className="text-center text-primary">
                      {textFormValues.QUESTION}
                    </p>
                    {activeRating === RatingActive.Stars && (
                      <div className="flex items-center justify-center">
                        <StarsRating
                          allowHalf={false}
                          // value={rate}
                          // onChange={(rate) => {
                          //   setRate(rate);
                          // }}
                        />
                      </div>
                    )}

                    {activeRating === RatingActive.Emoji && (
                      <div className="flex items-center justify-evenly">
                        <img src="/media/image/thumbs-up.png" alt="" />
                        <img src="/media/image/thumbs-down.png" alt="" />
                        <img src="/media/image/red-heart.png" alt="" />
                        <img src="/media/image/face-vomiting.png" alt="" />
                        <img src="/media/image/straight-face.png" alt="" />
                      </div>
                    )}

                    {activeRating === RatingActive.Toggle && (
                      <div className="flex items-center justify-center">
                        <ReviewToggle
                          checked={toggle}
                          onChange={() => setToggle(!toggle)}
                        />
                      </div>
                    )}
                    <p className="text-center text-primary">
                      Leave an additional message if you want to
                    </p>
                    <textarea
                      id="message"
                      rows={4}
                      className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Type Your Message Here"
                    ></textarea>
                    <div className="flex justify-center">
                      <button
                        type="button"
                        className="text-center max-w-[100px] mx-auto text-white bg-primary  font-medium rounded-lg text-sm px-10 py-2.5"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IngestionLibraryWebsite;
