/* eslint-disable array-callback-return */
//@ts-nocheck

import { HiStar } from "react-icons/hi";
import { TbChevronRight } from "react-icons/tb";
import {
  CUSTOMER_ECHO_DATA_SOURCES,
  calcPercentage,
  classNames,
  ratingsImage,
  ratingsToNumber,
} from "../../../../utils";
import { useEffect, useRef, useState } from "react";
import { getReviewTracker } from "../../../../api/Reviews";
import { getPlatformRatings } from "../../../../api/Ratings";
import {
  PlatformRatingModel,
  ReviewClassModel,
  UserProfileModel,
} from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import SkeletonLoader from "../../SkeletonLoader";
import { getCustomerSatisfactionScore } from "../../../../api/Utils";
import moment from "moment";
import SourceIcon from "../../SourceIcon";
import { Link } from "react-router-dom";
import { ImSpinner3 } from "react-icons/im";

interface ReviewStarsProps {
  reviews?: number;
  size?: number;
}

export const ReviewStars = ({ reviews = 5, size = 12 }: ReviewStarsProps) => {
  return (
    <div className="flex items-center justify-between">
      {[0, 1, 2, 3, 4].map((review, index) => {
        return (
          <HiStar
            key={index}
            size={size}
            className={classNames(
              index < reviews ? "text-yellow-500" : "text-gray-400"
            )}
          />
        );
      })}
    </div>
  );
};

interface RatingProgressBarProps {
  percentage: number;
}

const RatingProgressBar = ({ percentage = 100 }: RatingProgressBarProps) => {
  return (
    <div className="w-[180px] h-1 bg-gray-300 rounded-full">
      <div
        className="h-1 bg-yellow-500 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

interface RatingChartProps {
  total: number;
  rating: ReviewClassModel;
}

const RatingChart = ({ total, rating }: RatingChartProps) => {
  const one = calcPercentage(rating.one ?? 0, total);
  const two = calcPercentage(rating.two ?? 0, total);
  const three = calcPercentage(rating.three ?? 0, total);
  const four = calcPercentage(rating.four ?? 0, total);
  const five = calcPercentage(rating.five ?? 0, total);

  useEffect(() => {}, [total, rating]);

  return (
    <div className="space-y-0.5">
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/red-heart.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={100} />
        <span>
          {rating.five ?? 0}{" "}
          <span className="hidden xl:inline-block">responses</span>
        </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/thumbs-up.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={80} />
        <span>
          {rating.four ?? 0}{" "}
          <span className="hidden xl:inline-block">responses</span>
        </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/straight-face.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={60} />
        <span>
          {rating.three ?? 0}{" "}
          <span className="hidden xl:inline-block">responses</span>
        </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/thumbs-down.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={40} />
        <span>
          {rating.two ?? 0}{" "}
          <span className="hidden xl:inline-block">responses</span>
        </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/face-vomiting.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={20} />
        <span>
          {rating.one ?? 0}{" "}
          <span className="hidden xl:inline-block">responses</span>
        </span>
      </span>
    </div>
  );
};

const ReviewUpdates = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  let comments: any[] = [];
  let dateRange: string = `February 2023 - ${moment().format("MMM YYYY")}`;
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const [commentLabel, setCommentLabel] = useState("No Data");
  const [commentSource, setCommentSource] = useState("NONE");

  const handlefilterCommentLabelChange = (event) => {
    setCommentLabel(event.target.value);
  };

  const handlefilterCommentSourceSelect = (value) => {
    if (commentSource === "NONE") {
      setCommentSource(value + ",");
      return;
    }

    if (commentSource.includes(value)) {
      setCommentSource((prevState) => prevState.replace(value + ",", ""));
      return;
    }

    setCommentSource((prev) => `${prev + value + ","}`);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setCommentSource("NONE");
    setCommentLabel("No Data");
    setStartDateTimeStamp("0.000");
    setEndDateTimeStamp(`${new Date(Date.now()).getTime() / 1000}`);
    if (filter === 1) {
      setFilter(0);
      queryclient.refetchQueries(["getCustomerSatisfactionScore"]);
      return;
    }
    setFilter(0);
  };

  const ratings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getPlatformRatings"],
    queryFn: async () => {
      const response = await getPlatformRatings(queryKey);
      return response.data;
    },
  });

  const satisfaction = useInfiniteQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore", filter],
    queryFn: async ({ pageParam = "" }) => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: pageParam,
        timestamp: [startDateTimeStamp, endDateTimeStamp],
        commentSource: commentSource,
        commentLabel: commentLabel,
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
    onSuccess: (data) => {
      console.log({ data });
      if (data === null || !data) {
        comments = [];
      } else {
        comments = data;
      }
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  if (ratings.status === "loading")
    return (
      <SkeletonLoader className="rounded-md h-[calc(60vh_-_1.25rem)] w-full" />
    );

  if (ratings.status === "error")
    return <span>Error: {ratings.error.message}</span>;

  // if (satisfaction.status === "loading")
  //   return (
  //     <SkeletonLoader className="rounded-md h-[calc(60vh_-_1.25rem)] w-full" />
  //   );

  // if (satisfaction.status === "error")
  //   return <span>Error: {satisfaction.error.message}</span>;

  if (satisfaction.data) {
    if ("pages" in satisfaction.data) {
      console.log(satisfaction.data);
      satisfaction.data.pages.forEach((page) => {
        if (page !== null) {
          if ("Reviewers_Board" in page) {
            comments = [...comments, ...page.Reviewers_Board];
          }
        }

        dateRange = `${moment(comments[0]?.uploadTime).format(
          "MMMM YYYY"
        )} - ${moment(comments[comments.length - 1]?.uploadTime).format(
          "MMMM YYYY"
        )}`;
      });
      // if ("Reviewers_Board" in satisfaction.data) {

      //   const newComments = Array.isArray(satisfaction.data.Reviewers_Board) ? satisfaction.data.Reviewers_Board as any[] : [];

      //   if (newComments.length > 0) {
      //     comments = [...comments, ...newComments];
      //     console.log(comments)
      //     dateRange = `${moment(comments[0]?.uploadTime).format(
      //       "MMMM YYYY"
      //     )} - ${moment(comments[comments.length - 1]?.uploadTime).format(
      //       "MMMM YYYY"
      //     )}`;
      //   }
      // }
    }
  }

  return (
    <div className="flex flex-col h-[calc(60vh_-_1.25rem)] bg-white rounded-md shadow-md pb-0 pt-2 overflow-hidden relative">
      <div className="sticky flex items-center justify-between px-4 py-2 mb-4 bg-white -top-2 ">
        <p className="text-base font-semibold text-gray-500 lg:text-lg">
          Feedback Summary
        </p>
        <div className="relative flex items-center gap-3">
          <button className="px-2 py-0.5 text-xs font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md lg:px-3 lg:py-2">
            {dateRange}
          </button>

          <div className="pb-1">
            <button
              onClick={() => setShowDropdown((prevState) => !prevState)}
              className="inline-flex items-center py-0.5 px-2 mt-1 lg:py-1 font-medium truncate border rounded-md shadow-sm lg:px-3 border-primary text-primary"
            >
              Filter by <TbChevronRight className="mt-1 ml-5" strokeWidth={3} />
            </button>
            {showDropdown && (
              <div
                id="dropdown"
                className="absolute right-0 mt-1 z-20 bg-white rounded-lg shadow w-[280px] h-[320px] overflow-x-hidden overflow-y-scroll"
              >
                <div className="p-2 py-1 bg-[#eff4fe] text-gray-800">Date</div>
                <div className="flex items-center p-2 space-x-2 bg-white">
                  <div date-rangepicker="true">
                    <span className="text-gray-500">Start Date</span>
                    <div className="relative">
                      <input
                        name="start"
                        type="date"
                        className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Select date"
                        onChange={(e) =>
                          setStartDateTimeStamp(
                            new Date(e.target.value).getTime() / 1000
                          )
                        }
                      />
                    </div>
                  </div>
                  <div date-rangepicker="true">
                    <span className="text-gray-500">End Date</span>
                    <div className="relative">
                      <input
                        name="end"
                        type="date"
                        className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Select date"
                        onChange={(e) =>
                          setEndDateTimeStamp(
                            new Date(e.target.value).getTime() / 1000
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="p-2 py-1 bg-[#eff4fe] text-gray-800">
                  Review Type
                </div>
                <div className="flex items-center gap-2 p-2 text-gray-400 bg-white">
                  <div className="flex">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="NEG"
                        checked={commentLabel === "NEG"}
                        onChange={handlefilterCommentLabelChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-sm">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-600"
                      >
                        Positive
                      </label>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="POS"
                        checked={commentLabel === "POS"}
                        onChange={handlefilterCommentLabelChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-sm">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-600"
                      >
                        Negative
                      </label>
                    </div>
                  </div>
                </div>
                <div className="p-2 py-1 bg-[#eff4fe] text-gray-800">
                  Data Source
                </div>
                <div className="flex flex-col gap-2 pl-3 text-gray-400 bg-white">
                  {CUSTOMER_ECHO_DATA_SOURCES.map(
                    (source: any, index: number) => {
                      return (
                        <div key={index} className="h-5">
                          <label>
                            <input
                              type="checkbox"
                              value={source.key}
                              className="w-4 h-4 font-medium text-gray-900 bg-gray-100 border-gray-300 accent-blue-600"
                              checked={commentSource.includes(source.key)}
                              onChange={() =>
                                handlefilterCommentSourceSelect(source.key)
                              }
                              disabled={
                                source.key === "web"
                                  ? false
                                  : source.key === "qr_code"
                                  ? false
                                  : true
                              }
                            />{" "}
                            {source.name}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="bg-[#eff4fe] flex items-center justify-center p-2 text-sm space-x-2">
                  <button
                    className="w-1/2 px-4 py-2 font-bold text-gray-700 truncate bg-transparent border rounded"
                    onClick={(e) => {
                      handleClearFilter(e);
                      setShowDropdown(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 font-bold text-white truncate rounded bg-primary"
                    onClick={() => {
                      if (filter === 0) {
                        setFilter(1);
                      } else {
                        setFilter(0);
                      }
                      setShowDropdown(false);
                    }}
                  >
                    Show Results
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-1 px-4 pb-3 text-sm bg-white divide-x divide-gray-200 2xl:grid-cols-3 lg:gap-3 top-10 justify-items-stretch">
        <div className="flex flex-col">
          <div className="">
            <p className="text-base font-semibold text-gray-800 lg:text-xl">
              Total Ratings
            </p>
            <p className="text-lg font-semibold text-black">
              {ratings.data.total_reviews ?? 0}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="ml-2 lg:ml-5">
            <p className="mt-1.5 mb-0.5 lg:mb-0 text-xs font-semibold text-gray-400 lg:mt-0">
              Average Rating
            </p>
            <div className="inline-flex items-center">
              <p className="text-lg font-semibold text-black">
                {ratings.data.average_ratings ?? "0"}
              </p>
              {/* <span className="ml-1"><ReviewStars reviews={parseInt(ratings.data.average_ratings ?? "0")} /></span> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-2 2xl:col-span-1">
          <div className="ml-5">
            <RatingChart
              total={ratings.data.total_reviews ?? 0}
              rating={ratings.data.review_by_class ?? {}}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 h-full px-4 overflow-y-auto">
        {satisfaction.status === "loading" || satisfaction.isRefetching ? (
          <div className="flex items-center justify-start h-full py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : satisfaction.status === "error" ? (
          <span>Error: {satisfaction.error.message}</span>
        ) : (
          comments.length > 0 &&
          comments.map((comment: any, index: number) => {
            const similarity_score: string = "0.00";
            const source = comment.commentSource;
            const sentiment_tag: string = comment.commentLabel;

            return (
              <div
                key={index}
                className={classNames(
                  "flex gap-2",
                  index !== 0 ? "pt-3 mt-3 border-t border-gray-200" : ""
                )}
              >
                <div className="w-[230px]">
                  <div className="flex items-center space-x-4">
                    <div className="w-[35px] h-[35px] rounded-full border border-gray-300 flex flex-col items-center justify-center text-primary">
                      {/* {ratingsImage(comment.userRatings)} */}
                      <SourceIcon source={source} />
                    </div>
                    <div className="text-xs text-gray-500">
                      <p className="w-1/2 font-semibold text-black truncate">
                        {comment.customer !== ""
                          ? comment.customer
                          : "Anonymous"}
                      </p>
                      <div className="flex flex-col items-center lg:space-x-1 lg:flex-row">
                        {/* <ReviewStars
                          reviews={
                            ratingsToNumber(comment.userRatings) as number
                          }
                          size={10}
                        /> */}
                        {ratingsImage(comment.userRatings)}
                        <p>{moment(comment.uploadTime).format("MM/DD/YYYY")}</p>
                      </div>
                    </div>
                  </div>
                  <p
                    className={classNames(
                      "w-3/4 px-2 py-1 mt-2 text-xs font-semibold text-center text-black border rounded-3xl",
                      sentiment_tag === "NEG"
                        ? "border-red-600"
                        : sentiment_tag === "POS"
                        ? "border-green-600"
                        : "border-gray-400"
                    )}
                  >
                    {sentiment_tag === "NEG"
                      ? "Negative"
                      : sentiment_tag === "POS"
                      ? "Positive"
                      : "Un-decided"}
                  </p>
                </div>
                <div className="col-span-4 text-xs leading-normal text-left text-gray-400">
                  <p>{comment.userReview}</p>
                </div>
              </div>
            );
          })
        )}
        {comments.length === 0 &&
        satisfaction.status !== "loading" &&
        !satisfaction.isRefetching ? (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-xl text-gray-400">
              {filter === 1
                ? "No Review found"
                : "You don't have any history yet"}
            </p>
          </div>
        ) : null}
        {/* {comments.length > 0 && (
          <div className="hidden p-2 text-center bg-white">
            <button
              onClick={() => satisfaction.fetchNextPage()}
              disabled={
                !satisfaction.hasNextPage || satisfaction.isFetchingNextPage
              }
              className="text-blue-500"
            >
              {satisfaction.isFetchingNextPage
                ? "Loading more..."
                : satisfaction.hasNextPage
                ? "Load More"
                : "That's all we got!"}
            </button>
          </div>
        )} */}
      </div>
      {comments.length > 0 && (
        <div className="">
          <Link
            to="/smart-feedback/customer-review/all"
            className="absolute z-0 w-40 px-3 -ml-24 text-sm font-semibold text-center capitalize rounded-md text-primary bottom-2 left-1/2"
          >
            See All...
          </Link>
        </div>
      )}
    </div>
  );
};

export default ReviewUpdates;
