import { Fragment, useEffect, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { MdMoreVert } from "react-icons/md";
import moment from "moment";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { classNames } from "../../../../../utils";
import {
  getMessages,
  loadSidebarMessages,
} from "../../../../../api/settings";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import Modal from "../../../../../components/Dashboard/Modal";
import { BiChevronLeft } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";

enum ButtonActive {
  SMS = "SMSs",
  Email = "Emails",
}

const MessagesPage = () => {
  const { recipientPhoneNumber } = useParams();
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeName = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const queryKey = user.queryKey;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState<ButtonActive>(ButtonActive.Email);
  const [callEmail, setCallEmail] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedObject, setSelectedObject] = useState({
    recipientEmail: "",
    eventTimeStamp: "",
    messageSubject: "",
    bodyText: "",
    messageId: "",
  });
  const [dropdown, setDropdown] = useState(false);
  const [activeEmail, setActiveEmail] = useState("");
  const [mobile, setMobile] = useState(1);

  const toggleActive = (value: ButtonActive) => {
    setActive(value);
  };

  useEffect(() => {
    if (recipientPhoneNumber) {
      setShowModal(true);
    }
  }, []);

  let messages: any[] = [];
  let sidebarMessagesArr: any[] = [];

  const clientMessages = useInfiniteQuery<any, Error>({
    queryKey: ["getClientMessages", callEmail],
    queryFn: async ({ pageParam = "" }) => {
      const pageKey = pageParam;
      const response = await getMessages(queryKey, pageKey, callEmail);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.LastEvaluatedKey === "") {
        return false;
      }
      return lastPage.LastEvaluatedKey;
    },
  });

  const sidebarMessages = useInfiniteQuery<any, Error>({
    queryKey: ["getSidebarMessages"],
    queryFn: async ({ pageParam = "" }) => {
      const payload = {
        platformKey,
        paginationKey: pageParam,
      };
      const response = await loadSidebarMessages(payload);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.LastEvaluatedKey === "") {
        return false;
      }
      return lastPage.LastEvaluatedKey;
    },
  });

  if (clientMessages.data) {
    if ("pages" in clientMessages.data) {
      clientMessages.data.pages.forEach((page) => {
        if ("Messages" in page) {
          messages = [...messages, ...page.Messages.Items];
        }
      });
    }
  }

  if (sidebarMessages.data) {
    if ("pages" in sidebarMessages.data) {
      sidebarMessages.data.pages.forEach((page) => {
        if ("Message_Payload" in page) {
          sidebarMessagesArr = [...sidebarMessagesArr, ...page.Message_Payload];
        }
      });
    }
  }

  return (
    <>
      <Modal
        show={showSuccessModal}
        onToggle={() => setShowSuccessModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-28"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              Message Successfully Sent!
            </h1>
          </div>
        </div>
      </Modal>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[200]"
          onClose={() => {
            setShowModal(false);
            // navigate(-1);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[380px] md:w-[460px] lg:w-[520px] max-w-2xl transform text-center overflow-hidden rounded-md bg-white px-6 align-middle shadow-xl transition-all">
                  <div className="text-center my-14">
                    <h4 className="text-2xl font-semibold">
                      Need To Validate Some Ideas?
                    </h4>
                    <p className="mt-2 text-sm font-medium leading-tight text-gray-700">
                      You are currently on the free plan but getting more
                      features is easy
                    </p>
                    <Link
                      to="/settings/subscriptions"
                      className="inline-block px-8 py-3 mt-6 text-lg font-semibold text-center text-white rounded-md bg-primary"
                    >
                      Request An Upgrade
                    </Link>
                    <p className="mt-3 text-sm font-medium leading-tight text-gray-700">
                      Learn about our plans and pricing{" "}
                      <Link
                        to="/settings/subscriptions"
                        className="underline text-primary underline-offset-1"
                      >
                        here
                      </Link>
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Tab and Desktop Screen */}
      <div className="hidden space-y-1 sm:block">
        <div className="flex items-center justify-between py-1">
          <h4 className="text-base text-gray-400 capitalize">Messages</h4>
          {/* <button
            onClick={() =>
              navigate(
                callEmail === ""
                  ? "/smart-feedback/messages/new-message"
                  : `/smart-feedback/messages/new-message/${callEmail}`
              )
            }
            className="inline-flex items-center justify-between px-3 py-2 text-xs font-medium text-white truncate bg-blue-600 border rounded-md shadow-sm"
          >
            <span>New Message</span>
          </button> */}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className="inline-flex items-center justify-center w-full gap-3 px-4 py-2 text-xs font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700"
                
              >
                New Message <FiChevronDown />
              </Menu.Button>
            </div>
            <Menu.Items className="absolute right-0 z-20 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-28">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                      onClick={() =>
                        navigate(
                          callEmail === ""
                            ? "/smart-feedback/messages/new-message"
                            : `/smart-feedback/messages/new-message/${callEmail}`
                        )
                      }
                    >
                      Email
                    </button>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                      onClick={() =>
                        navigate("/smart-feedback/messages/new-message/sms")
                      }
                    >
                      SMS
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
        <div className="grid grid-cols-5">
          <div className="h-[80vh] bg-white py-2  sm:col-span-2 lg:col-span-2 border-r-2 border-gray-100 relative">
            <div
              className="grid grid-cols-2 mx-3 mb-2 text-xs font-semibold border rounded-md"
              role="group"
            >
              <button
                type="button"
                onClick={() => {
                  setStep(0);
                  toggleActive(ButtonActive.Email);
                }}
                className={
                  active === ButtonActive.Email
                    ? "px-2 py-2 text-primary border-r-[1px] border-gray-400"
                    : "px-2 py-2 text-gray-300 border-r-[1px] border-gray-400"
                }
              >
                Email
              </button>
              <button
                type="button"
                onClick={() => {
                  // setShowModal(true);
                  navigate("/smart-feedback/messages/new-message/sms");
                }}
                className={
                  active === ButtonActive.SMS
                    ? "px-2 py-2 text-primary "
                    : "px-2 py-2 text-gray-400 "
                }
              >
                SMS
              </button>
            </div>
            {sidebarMessages.status === "loading" && (
              <div className="flex items-center justify-start py-5">
                <ImSpinner3
                  size={18}
                  className="mx-auto animate-spin fill-primary"
                />
              </div>
            )}

            {sidebarMessages.status === "success" &&
              sidebarMessagesArr.length === 0 && (
                <p className="mt-8 text-xs font-semibold text-center text-gray-400">
                  No Messages
                </p>
              )}

            {sidebarMessages.status === "error" && (
              <p className="mt-8 text-xs font-semibold text-center text-gray-400">
                {sidebarMessages.error.message}
              </p>
            )}

            <div className="h-[58vh] overflow-y-auto">
              {sidebarMessagesArr.map((message) => (
                <div
                  key={message.customer}
                  className={classNames(
                    "sm: px-1 lg:px-3 py-3 border-b-[1px] border-gray-100 cursor-pointer",
                    activeEmail === message.customer
                      ? "bg-slate-50"
                      : "bg-white"
                  )}
                  onClick={() => {
                    navigate(`/smart-feedback/messages/${message.customer}`);
                    setCallEmail(message.customer);
                    setStep(1);
                    setActiveEmail(message.customer);
                  }}
                >
                  <div className="flex items-center justify-between w-full gap-4 px-2">
                    <div className="w-[15%] hidden lg:block">
                      <div className="font-semibold text-base text-white rounded-full w-[40px] h-[40px] p-1 flex items-center justify-center uppercase bg-red-600">
                        <p>{message.customer?.charAt(0)}</p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-1">
                      <div className="flex items-center justify-between">
                        <p
                          className={classNames(
                            "text-xs truncate",
                            activeEmail === message.customer
                              ? "font-semibold"
                              : "font-medium"
                          )}
                        >
                          {message.customer.length < 15
                            ? message.customer
                            : message.customer.substring(0, 15) + "..."}
                        </p>
                        <p className="hidden text-xs lg:block">
                          {message.mailing_history[0].recent_message.length > 0
                            ? moment(
                                message.mailing_history[0].recent_message[0]
                                  .eventTime
                              ).format("MM/DD/YYYY")
                            : null}
                        </p>
                      </div>
                      <div className="flex items-baseline justify-between gap-1">
                        <p className="text-xs text-gray-700 text-ellipsis">
                          {message.mailing_history[0].recent_message.length > 0
                            ? message.mailing_history[0].recent_message[0].bodyText.substring(
                                0,
                                40
                              )
                            : "No recent Message"}
                        </p>
                        <div className="h-[22px] w-[25px] flex items-center justify-center bg-blue-600 text-white text-xs rounded">
                          <p>
                            {message.mailing_history[0].message_list.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="absolute left-0 right-0 flex items-center justify-center bottom-2">
              <button
                onClick={() => sidebarMessages.fetchNextPage()}
                disabled={
                  !sidebarMessages.hasNextPage ||
                  sidebarMessages.isFetchingNextPage
                }
                className="inline-flex items-center gap-4 px-4 py-2 text-xs cursor-pointer text-primary"
              >
                <span>
                  {sidebarMessages.isFetchingNextPage
                    ? "Loading more..."
                    : sidebarMessages.hasNextPage
                    ? "Load More"
                    : "That's all we got!"}
                </span>
              </button>
            </div>
          </div>
          {step === 0 ? (
            <div className="h-[80vh] px-4 py-2 bg-white sm:col-span-3 lg:col-span-3 relative sm:flex flex-col overflow-y-auto"></div>
          ) : step === 1 ? (
            <div className="h-[80vh] bg-white sm:col-span-3 lg:col-span-3relative flex flex-col ">
              <div className="h-full py-2">
                {clientMessages.status === "loading" ? (
                  <div className="flex items-center justify-start h-full py-5">
                    <ImSpinner3
                      size={18}
                      className="mx-auto animate-spin fill-primary"
                    />
                  </div>
                ) : clientMessages.status === "error" ? (
                  <p className="mt-8 font-semibold text-center text-gray-400">
                    {clientMessages.error.message}
                  </p>
                ) : messages.length > 0 ? (
                  <div className="relative h-full">
                    <div className="h-[90%] px-4 py-2 overflow-y-auto">
                      {messages.map((message, index) => (
                        <div
                          key={message.messageId}
                          className="pb-2 my-2 relative border-b-[1px] border-gray-200 mb-4 cursor-pointer"
                          onClick={() => {
                            setSelectedObject(message);
                            setStep(2);
                          }}
                        >
                          <div className="w-full text-sm">
                            <div className="flex items-center justify-between text-base">
                              <h4 className="text-sm font-semibold">
                                {message.recipientEmail}
                              </h4>
                              <p className="text-gray-900 font-medium text-[13px]">
                                {moment(message.eventTimeStamp).format(
                                  "MM/DD/YYYY"
                                )}
                              </p>
                            </div>
                            <h4 className="font-medium text-[13px]">
                              {message.messageSubject}
                            </h4>
                            <p className="text-gray-800 font-medium text-xs mb-1.5">
                              {message.bodyText}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="absolute left-0 right-0 flex items-center justify-center bottom-2">
                      <button
                        onClick={() => clientMessages.fetchNextPage()}
                        disabled={
                          !clientMessages.hasNextPage ||
                          clientMessages.isFetchingNextPage
                        }
                        className="inline-flex items-center gap-4 px-4 py-2 text-xs font-medium cursor-pointer text-primary"
                      >
                        <span>
                          {clientMessages.isFetchingNextPage
                            ? "Loading more..."
                            : clientMessages.hasNextPage
                            ? "Load More"
                            : "That's all we got!"}
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <p className="mt-8 text-sm font-medium text-center text-gray-400">
                      No messages sent yet. Send a message to this customer to
                      begin a conversation.
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="h-[80vh] px-4 py-2 bg-white sm:col-span-3 lg:col-span-3 relative sm:flex flex-col overflow-y-auto">
              <div className="">
                <div className="flex items-center justify-between my-2 mb-6">
                  <BiChevronLeft
                    size={24}
                    onClick={() => setStep(1)}
                    className="cursor-pointer"
                  />
                  <div className="relative">
                    <MdMoreVert
                      size={22}
                      onClick={() => {
                        setDropdown((prevState) => !prevState);
                      }}
                      className="cursor-pointer"
                    />
                    {dropdown && (
                      <div className="py-2 px-1 absolute border top-8 right-0 z-10 w-[100px] bg-white rounded-md text-center">
                        <p
                          className="p-2 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                          onClick={() => null}
                        >
                          Delete
                        </p>
                        <p
                          className="p-2 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                          onClick={() =>
                            navigate(
                              `/smart-feedback/messages/forward/${selectedObject.messageId}`
                            )
                          }
                        >
                          Forward
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative pb-2 my-2 mb-4">
                  <div className="relative">
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <h4 className="text-base font-semibold text-gray-800">
                          {selectedObject.recipientEmail}
                        </h4>
                        <p className="font-semibold text-gray-800">
                          {moment(selectedObject.eventTimeStamp).format(
                            "MM/DD/YYYY"
                          )}
                        </p>
                      </div>
                      <h4 className="mb-2 text-base font-semibold">
                        {selectedObject.messageSubject}
                      </h4>
                      <p className="text-gray-800 font-medium text-xs my-1.5">
                        {selectedObject.bodyText}
                      </p>
                      <div className="text-xs text-gray-800">
                        <p className="">Regards</p>
                        <p className="font-semibold">{storeName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile */}
      <div className="space-y-1 sm:hidden">
        <div className="flex items-center justify-between py-1">
          <h4 className="text-xl text-gray-400 capitalize">Messages</h4>
          <button
            onClick={() =>
              navigate(
                callEmail === ""
                  ? "/smart-feedback/messages/new-message"
                  : `/smart-feedback/messages/new-message/${callEmail}`
              )
            }
            className="inline-flex items-center justify-between px-3 py-2 text-xs font-medium text-white truncate bg-blue-600 border rounded-md shadow-sm"
          >
            <span>New Message</span>
          </button>
        </div>

        {mobile === 1 && (
          <div className="h-[80vh] bg-white py-2 col-span-5 sm:col-span-2 border-r-2 border-gray-100 relative">
            <div
              className="grid grid-cols-2 mx-3 mb-2 text-xs font-semibold border rounded-md"
              role="group"
            >
              <button
                type="button"
                onClick={() => {
                  setStep(0);
                  toggleActive(ButtonActive.Email);
                }}
                className={
                  active === ButtonActive.Email
                    ? "px-2 py-2 text-primary border-r-[1px] border-gray-400"
                    : "px-2 py-2 text-gray-300 border-r-[1px] border-gray-400"
                }
              >
                Email
              </button>
              <button
                type="button"
                onClick={() => {
                  // setShowModal(true);
                  navigate("/smart-feedback/messages/new-message/sms");
                }}
                className={
                  active === ButtonActive.SMS
                    ? "px-2 py-2 text-primary "
                    : "px-2 py-2 text-gray-400 "
                }
              >
                SMS
              </button>
            </div>
            {sidebarMessages.status === "loading" && (
              <div className="flex items-center justify-start py-5">
                <ImSpinner3
                  size={18}
                  className="mx-auto animate-spin fill-primary"
                />
              </div>
            )}

            {sidebarMessages.status === "success" &&
              sidebarMessagesArr.length === 0 && (
                <p className="mt-8 font-semibold text-center text-gray-400">
                  No Messages
                </p>
              )}

            {sidebarMessages.status === "error" && (
              <p className="mt-8 font-semibold text-center text-gray-400">
                {sidebarMessages.error.message}
              </p>
            )}

            <div className="h-[58vh] overflow-y-auto">
              {sidebarMessagesArr.map((message) => (
                <div
                  key={message.customer}
                  className={classNames(
                    "px-2 py-3 border-b-[1px] border-gray-100 cursor-pointer",
                    activeEmail === message.customer
                      ? "bg-slate-50"
                      : "bg-white"
                  )}
                  onClick={() => {
                    navigate(`/smart-feedback/messages/${message.customer}`);
                    setCallEmail(message.customer);
                    setStep(1);
                    setMobile(2);
                    setActiveEmail(message.customer);
                  }}
                >
                  <div className="flex items-center justify-between w-full gap-4 px-2">
                    <div className="w-[15%]">
                      <div className="font-semibold text-base text-white rounded-full w-[40px] h-[40px] p-1 flex items-center justify-center uppercase bg-red-600">
                        <p>{message.customer?.charAt(0)}</p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-1">
                      <div className="flex items-center justify-between">
                        <p
                          className={classNames(
                            "text-xs",
                            activeEmail === message.customer
                              ? "font-semibold"
                              : "font-medium"
                          )}
                        >
                          {message.customer.length < 15
                            ? message.customer
                            : message.customer.substring(0, 15) + "..."}
                        </p>
                        <p className="text-xs">
                          {message.mailing_history[0].recent_message.length > 0
                            ? moment(
                                message.mailing_history[0].recent_message[0]
                                  .eventTime
                              ).format("MM/DD/YYYY")
                            : null}
                        </p>
                      </div>
                      <div className="flex items-baseline justify-between gap-1">
                        <p className="text-xs text-gray-700 truncate">
                          {message.mailing_history[0].recent_message.length > 0
                            ? message.mailing_history[0].recent_message[0].bodyText.substring(
                                0,
                                25
                              )
                            : "No recent Message"}
                        </p>
                        <div className="h-[22px] w-[25px] flex items-center justify-center bg-blue-600 text-white text-xs rounded">
                          <p>
                            {message.mailing_history[0].message_list.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="absolute left-0 right-0 flex items-center justify-center bottom-2">
              <button
                onClick={() => sidebarMessages.fetchNextPage()}
                disabled={
                  !sidebarMessages.hasNextPage ||
                  sidebarMessages.isFetchingNextPage
                }
                className="inline-flex items-center gap-4 px-4 py-2 text-xs cursor-pointer text-primary"
              >
                <span>
                  {sidebarMessages.isFetchingNextPage
                    ? "Loading more..."
                    : sidebarMessages.hasNextPage
                    ? "Load More"
                    : "That's all we got!"}
                </span>
              </button>
            </div>
          </div>
        )}

        {mobile === 2 && (
          <div className="h-[80vh] bg-white col-span-3 relative flex flex-col ">
            <div className="flex items-center justify-between my-2">
              <div className="flex items-center gap-2 ml-2">
                <BiChevronLeft
                  size={24}
                  onClick={() => {
                    setStep(0);
                    setMobile(1);
                  }}
                  className="cursor-pointer"
                />
                <p className="text-xs font-semibold">Back</p>
              </div>
            </div>
            <div className="h-full pb-2">
              {clientMessages.status === "loading" ? (
                <div className="flex items-center justify-start h-full py-5">
                  <ImSpinner3
                    size={18}
                    className="mx-auto animate-spin fill-primary"
                  />
                </div>
              ) : clientMessages.status === "error" ? (
                <p className="mt-8 font-semibold text-center text-gray-400">
                  {clientMessages.error.message}
                </p>
              ) : messages.length > 0 ? (
                <div className="relative h-full">
                  <div className="h-[90%] px-4 py-2 overflow-y-auto">
                    {messages.map((message, index) => (
                      <div
                        key={message.messageId}
                        className="pb-2 my-2 relative border-b-[1px] border-gray-200 mb-4 cursor-pointer"
                        onClick={() => {
                          setSelectedObject(message);
                          setStep(2);
                          setMobile(3);
                        }}
                      >
                        <div className="w-full text-sm">
                          <div className="flex items-center justify-between text-base">
                            <h4 className="font-semibold">
                              {message.recipientEmail}
                            </h4>
                            <p className="text-sm font-semibold text-gray-900">
                              {moment(message.eventTimeStamp).format(
                                "MM/DD/YYYY"
                              )}
                            </p>
                          </div>
                          <h4 className="text-xs font-semibold">
                            {message.messageSubject}
                          </h4>
                          <p className="text-gray-800 font-medium text-xs mb-1.5">
                            {message.bodyText}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="absolute left-0 right-0 flex items-center justify-center bottom-2">
                    <button
                      onClick={() => clientMessages.fetchNextPage()}
                      disabled={
                        !clientMessages.hasNextPage ||
                        clientMessages.isFetchingNextPage
                      }
                      className="inline-flex items-center gap-4 px-4 py-2 text-xs font-medium cursor-pointer text-primary"
                    >
                      <span>
                        {clientMessages.isFetchingNextPage
                          ? "Loading more..."
                          : clientMessages.hasNextPage
                          ? "Load More"
                          : "That's all we got!"}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="mt-8 font-medium text-center text-gray-400 texxt-sm">
                    No messages sent yet. Send a message to this customer to
                    begin a conversation.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {mobile === 3 && (
          <div className="h-[80vh] px-4 py-2 bg-white col-span-3 relative flex flex-col overflow-y-auto sm:hidden">
            <div className="">
              <div className="flex items-center justify-between my-2 mb-6">
                <div className="flex items-center gap-2">
                  <BiChevronLeft
                    size={24}
                    onClick={() => {
                      setStep(1);
                      setMobile(2);
                    }}
                    className="cursor-pointer"
                  />
                  <p className="text-xs font-semibold">Back</p>
                </div>
                <div className="relative">
                  <MdMoreVert
                    size={22}
                    onClick={() => {
                      setDropdown((prevState) => !prevState);
                    }}
                    className="cursor-pointer"
                  />
                  {dropdown && (
                    <div className="py-2 px-1 absolute border top-8 right-0 z-10 w-[100px] bg-white rounded-md text-center">
                      <p
                        className="p-2 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                        onClick={() => null}
                      >
                        Delete
                      </p>
                      <p
                        className="p-2 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                        onClick={() =>
                          navigate(
                            `/smart-feedback/messages/forward/${selectedObject.messageId}`
                          )
                        }
                      >
                        Forward
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="relative pb-2 my-2 mb-4">
                <div className="relative">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <h4 className="text-base font-semibold text-gray-800">
                        {selectedObject.recipientEmail}
                      </h4>
                      <p className="font-semibold text-gray-800">
                        {moment(selectedObject.eventTimeStamp).format(
                          "MM/DD/YYYY"
                        )}
                      </p>
                    </div>
                    <h4 className="text-base font-semibold">
                      {selectedObject.messageSubject}
                    </h4>
                    <p className="text-gray-800 font-medium text-xs my-1.5">
                      {selectedObject.bodyText}
                    </p>
                    <div className="text-xs text-gray-800">
                      <p className="">Regards</p>
                      <p className="font-semibold">{storeName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MessagesPage;
