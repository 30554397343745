import { useState } from "react";
import { BiChevronRight, BiComment, BiSearch } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { APP_PERMISSIONS, classNames } from "../../../../utils";

const CreateClassesAccessPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [level, setLevel] = useState(0);
  const [active, setActive] = useState(0);

  return (
    <>
      <div className="flex bg-white rounded-md h-[85vh] space-y-4 flex-col">
        <div className="flex items-center space-x-0.5 text-sm text-gray-400 font-medium">
          <button onClick={() => navigate(-1)}>Classes</button>
          <span>
            <BiChevronRight size={14} />
          </span>
          <span className="text-black">New class</span>
        </div>
        <div className="">
          <h4 className="text-base font-semibold text-black">
            Create a new class
          </h4>
          <p className="text-[13px] font-normal text-[#303030]">
            Create a new class you can assign to a user or a group.
          </p>
        </div>
        <div className="space-y-0.5">
          <div className="relative w-full sm:w-3/5 lg:w-3/5">
            <label className="text-[12.5px] font-medium text-black">
              Class name
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                // if (e.target.value.length > 3) {
                setSearchQuery(e.target.value);
                // }
              }}
              placeholder="Enter a name to identify the role"
              className="w-full px-4 py-3 mt-0.5 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={21}
              className="absolute top-9 right-2.5 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
          <div className="relative w-full sm:w-3/5 lg:w-3/5 flex flex-col">
            <label className="text-[12.5px] font-medium text-black">
              Decription
            </label>
            <textarea
              name=""
              id=""
              className="h-[100px] border border-gray-200 rounded text-xs p-3"
              placeholder="Enter your class description"
            ></textarea>
          </div>
        </div>
        <div>
          <div className="relative flex-1 h-full space-y-6">
            <div>
              <h4 className="mb-2 text-sm font-semibold text-black">
                <input
                  type="checkbox"
                  checked
                  className="w-3 h-3 border border-black rounded accent-primary mr-2"
                />
                Mobile application permissions
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                {APP_PERMISSIONS.MOBILE.map((item: string, index: number) => (
                  <div key={index}>
                    <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                      <input
                        type="checkbox"
                        checked
                        className="w-3 h-3 border border-black rounded accent-primary"
                      />
                      <span className="whitespace-nowrap">{item}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="mb-2 text-sm font-semibold text-black">
                <input
                  type="checkbox"
                  checked
                  className="w-3 h-3 border border-black rounded accent-primary mr-2"
                />{" "}
                Feedback permissions
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                {APP_PERMISSIONS.FEEDBACK.map((item: string, index: number) => (
                  <div key={index}>
                    <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                      <input
                        type="checkbox"
                        checked
                        className="w-3 h-3 border border-black rounded accent-primary"
                      />
                      <span className="whitespace-nowrap">{item}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="mb-2 text-sm font-semibold text-black">
                <input
                  type="checkbox"
                  checked
                  className="w-3 h-3 border border-black rounded accent-primary mr-2"
                />
                Customer reward permissions
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                {APP_PERMISSIONS.REWARD.map((item: string, index: number) => (
                  <div key={index}>
                    <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                      <input
                        type="checkbox"
                        checked
                        className="w-3 h-3 border border-black rounded accent-primary"
                      />
                      <span className="whitespace-nowrap">{item}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="mb-2 text-sm font-semibold text-black">
                <input
                  type="checkbox"
                  checked
                  className="w-3 h-3 border border-black rounded accent-primary mr-2"
                />
                Message permissions
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                {APP_PERMISSIONS.MESSAGE.map((item: string, index: number) => (
                  <div key={index}>
                    <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                      <input
                        type="checkbox"
                        checked
                        className="w-3 h-3 border border-black rounded accent-primary"
                      />
                      <span className="whitespace-nowrap">{item}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2.5">
          <button
            onClick={() => {}}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-primary border border-primary capitalize rounded-md bg-transparent disabled:opacity-60"
          >
            back
          </button>
          <button
            onClick={() => {}}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            {"finish"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateClassesAccessPage;
