import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./Routes";
import * as _redux from "./setup";

import axios from "axios";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./setup/redux/Store";
import { Amplify, Auth } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
import { initMessageListener } from "redux-state-sync";
import { injectStore } from "./setup/axios/paymentSetup";

Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-2:ecec666e-90e3-4d15-b4c8-2ec1d853b513",
    region: "eu-west-2",
    userPoolId: "eu-west-2_Cq4FAMhty",
    userPoolWebClientId: "oefdv3qu30vvfhhvijk6ggna4",
    oauth: {
      domain: "cloudpactai-customer-echo-saas.auth.eu-west-2.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/customer-satisfaction",
      // redirectSignOut: "http://localhost:3000/login/",
      redirectSignIn:
        "https://apps.customersecho.com/smart-feedback/customer-review",
      redirectSignOut: "https://apps.customersecho.com/login/",
      responseType: "token",
    },
  },
});

export const currentConfig = Auth.configure();




const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 1000,
      refetchInterval: 20 * 30 * 1000,
    },
  },
});

_redux.setupAxios(axios, store);

initMessageListener(store);
injectStore(store)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HelmetProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
);

reportWebVitals();
