import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import Modal from "../../../../components/Dashboard/Modal";
import { classNames } from "../../../../utils";
import { MdClose } from "react-icons/md";

const CampaignManagerAudienceManager = () => {
  const navigate = useNavigate();
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <>
      <Modal
        size="medium"
        show={showAddContactModal}
        onToggle={() => {
          setShowAddContactModal(false);
          setStep(1);
        }}
      >
        <div className="p-4 text-gray-800">
          {step === 1 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-sm">Add contacts</p>
                <button
                  onClick={() => {
                    setShowAddContactModal(false);
                  }}
                >
                  <MdClose size={18} className="text-gray-700" />
                </button>
              </div>
              <div className="space-y-6">
                <div className="flex flex-col gap-0.5">
                  <label htmlFor="" className="text-xs text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    className="py-1.5 px-3 rounded border border-gray-300 w-full outline-none"
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <label htmlFor="" className="text-xs text-gray-700">
                    Team members
                  </label>
                  <input
                    type="text"
                    className="py-1.5 px-3 rounded border border-gray-300 w-full outline-none"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => setStep((prev) => prev + 1)}
                  className="text-white bg-blue-500 rounded py-2 px-4 text-xs"
                >
                  Add contact
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-sm">Add contacts</p>
                <button
                  onClick={() => {
                    setShowAddContactModal(false);
                  }}
                >
                  <MdClose size={18} className="text-gray-700" />
                </button>
              </div>
              <div className="space-y-6">
                <div className="flex flex-col gap-0.5">
                  <label htmlFor="" className="text-xs text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    className="py-1.5 px-3 rounded border border-gray-300 w-full outline-none"
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <label htmlFor="" className="text-xs text-gray-700">
                    Team members
                  </label>
                  <div className="border border-gray-200 rounded h-[300px] p-2.5">
                    <div className="flex items-center gap-2 flex-wrap">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index: number) => (
                        <p
                          key={index}
                          className="bg-blue-300 text-gray-600 rounded text-[11px] px-1 py-0.5"
                        >
                          Tosinsanmi@gmail.com
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => setStep((prev) => prev + 1)}
                  className="text-white bg-blue-500 rounded py-2 px-4 text-xs"
                >
                  Add contact
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-sm">Contacts</p>
                <button
                  onClick={() => {
                    setShowAddContactModal(false);
                  }}
                >
                  <MdClose size={18} className="text-gray-700" />
                </button>
              </div>
              <div className="space-y-2 text-sm">
                <div className="flex items-center gap-2">
                  <input type="radio" name="" id="" />
                  <p>Select all</p>
                </div>
                {[1, 2, 3, 4, , 5, 6, 7, 8].map((index) => (
                  <div className="flex items-center gap-2" key={index}>
                    <input type="radio" name="" id="" />
                    <p>+234567890123</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => setStep((prev) => prev + 1)}
                  className="text-white bg-blue-500 rounded py-2 px-4 text-xs"
                >
                  Select
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-sm">Contacts</p>
                <button
                  onClick={() => {
                    setShowAddContactModal(false);
                  }}
                >
                  <MdClose size={18} className="text-gray-700" />
                </button>
              </div>
              <div className="space-y-2 text-sm">
                <div className="flex items-center gap-2">
                  <input type="radio" name="" id="" />
                  <p>Select all</p>
                </div>
                {[1, 2, 3, 4, , 5, 6, 7, 8].map((index) => (
                  <div className="flex items-center gap-2" key={index}>
                    <input type="radio" name="" id="" />
                    <p>john@gmail.com</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <button className="text-white bg-blue-500 rounded py-2 px-4 text-xs">
                  Select
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div className="overflow-y-auto overflow-x-hidden relative space-y-4 ">
        <div className="flex items-center justify-between gap-1">
          <button className="text-[#171717] font-semibold text-sm inline-flex items-center gap-1">
            <BsArrowLeft
              onClick={() => navigate(-1)}
              className="text-gray-700"
              size={18}
            />
            Audience management
          </button>
          <button
            onClick={() => {
              setShowAddContactModal(true);
            }}
            className="text-xs text-white font-medium rounded bg-blue-600 py-2 px-4"
          >
            Add contacts
          </button>
        </div>
        <div className="relative flex-1 h-full overflow-y-auto overflow-x-hidden">
          <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
            <thead className="text-gray-700 capitalize bg-gray-100">
              <tr className="text-xs text-left capitalize">
                <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                  Platform
                </th>
                <th scope="col" className="px-2 py-3 sm:px-6">
                  List name
                </th>
                <th scope="col" className="px-2 py-3 sm:px-6">
                  Contact
                </th>
                <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                  Option
                </th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {[1, 2, 3, 4, 5, 6].map((review: any, index: number) => (
                <tr className={classNames("border-b")} key={index}>
                  <td className="hidden px-6 py-3 sm:table-cell">
                    <p className="text-xs">{"Email"}</p>
                  </td>
                  <td className="relative px-2 py-3 cursor-pointer sm:px-6 group">
                    <div className="text-xs cursor-pointer">
                      {"2024 sales lead"}
                    </div>
                  </td>
                  <td className="relative px-2 py-3 cursor-pointer sm:px-6 group">
                    <div className="text-xs cursor-pointer">
                      {
                        "simisolaobi@gmail.com, john@gmail.com. jane@gmail.com, joshua@gmai.com + 20 others"
                      }
                    </div>
                  </td>
                  <td className="hidden px-6 py-3 border-r sm:table-cell text-blue-500 underline">
                    <button className="">{"Details"}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-3 gap-4 hidden">
          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
            <div
              className="p-3 py-4 text-xs space-y-1.5 border border-gray-200 rounded text-gray-700"
              key={index}
            >
              <p className="font-semibold text-sm text-gray-700">Emails</p>
              <div className="flex items-center gap-2.5 border-b-[1px] border-gray-200 py-1 pb-2.5">
                <div className="rounded-full">
                  <img
                    src="/media/image/cmLogo1.png"
                    className="h-[40px] w-[40px] "
                  />
                </div>
                <div className="space-y-1">
                  <p className="font-semibold">johndoe@gmail.com</p>
                  <p>Cusecho by Cloudpact AI</p>
                </div>
                <button className="ml-auto">
                  <HiOutlineTrash size={20} className="text-gray-800" />
                </button>
              </div>
              <div className="flex items-center gap-2.5 border-b-[1px] border-gray-200 py-1 pb-2.5">
                <div className="rounded-full">
                  <img
                    src="/media/image/cmLogo2.png"
                    className="h-[40px] w-[40px] "
                  />
                </div>
                <div className="space-y-1">
                  <p className="font-semibold">johndoe@gmail.com</p>
                  <p>Cloudpact AI</p>
                </div>
                <button className="ml-auto">
                  <HiOutlineTrash size={20} className="text-gray-700" />
                </button>
              </div>
              <div className="pt-3">
                <button
                  className="text-gray-800 font-medium"
                  onClick={() => {
                    setShowAddContactModal(true);
                  }}
                >
                  + Add account
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CampaignManagerAudienceManager;
