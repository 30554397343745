// @ts-nocheck

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  archiveOrders,
  cancelSingleOrder,
  deleteOrders,
  getSingleItem,
  getSingleOrder,
  updateSingleOrderStatus,
} from "../../../../api/Menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ImSpinner3 } from "react-icons/im";
import { BiChevronDown, BiInfoCircle } from "react-icons/bi";
import { classNames } from "../../../../utils";
import {
  MdClear,
  MdDeleteOutline,
  MdKeyboardBackspace,
  MdOutlineCancel,
} from "react-icons/md";
import Modal from "../../../../components/Dashboard/Modal";
import { BsArchive } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";

const OrderStatus = [
  { status: "Pending" },
  { status: "Active" },
  { status: "Completed" },
  { status: "Cancelled" },
];


interface OrderProps {
  showCheckbox: boolean;
  setShowCheckbox: any;
}

const SingleOrderPage = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  // console.log(user)
  const { id } = useParams();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const navigate = useNavigate();
  const [openStatus, setOpenStatus] = useState(-1);
  const [openSingleItem, setOpenSingleItem] = useState<number | null>(-1);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState(-1)
 

  let order: any;

  const singleOrder = useQuery<any, Error>({
    queryKey: ["getSingleOrder"],
    queryFn: async () => {
      const response = await getSingleOrder(id);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const handleOrderStatusUpdate = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderNumber: data.orderNumber,
        orderSpecificId: data.orderId,
        oldStatus: "Pending",
        status: data.orderStatus,
      };

      return await updateSingleOrderStatus(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"] });
      setOpenStatus(-1);
    },
  });

  const handleCancelOrder = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderIds: [order.orderId],
      };

      return await deleteOrders(payload);
    },
    onSuccess: () => {
      navigate(-1);
      setShowDeleteModal(false);
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"] });
      queryClient.invalidateQueries({ queryKey: ["getCompletedOrders"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveOrders"] });
      setOpenStatus(-1);
    },
  });

  const handleArchiveOrder = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        objectId: order.orderId,
        platformRouter: user.queryKey?.replace("%26","&"),
        objectLoad: order,
        creationTimestamp: order.creationTimestamp.toString(),
      };

      return await archiveOrders(payload);
    },
    onSuccess: () => {
      navigate(-1);
      setShowDeleteModal(false);
      toast.success("Order saved to archives")
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"]});
      queryClient.invalidateQueries({ queryKey: ["getCompletedOrders"]});
      queryClient.invalidateQueries({ queryKey: ["getActiveOrders"]});
      setOpenStatus(-1);
    },
  });

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", selectedItemId],
    queryFn: async () => {
      const response = await getSingleItem(`${selectedItemId}`);
      return response.data;
    },
    enabled: !!selectedItemId,
    onSuccess: (data) => {
      setSelectedItem(data.Menu_Item);
    },
    onError: (err) => {},
  });

  if (singleOrder.data) {
    order = singleOrder.data.Order;
    console.log(order)
  }

  return (
    <>
      <Modal
        // size="medium"
        show={showDeleteModal}
        onToggle={() => {
          setShowDeleteModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 space-y-2">
          <p className="text-sm font-semibold">Delete order</p>
          <p className="text-[12.5px] ">
            Are you sure you want to delete selected order.
          </p>
          <div className="flex items-center justify-start gap-4">
            <button
              className="px-1 text-sm"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              No
            </button>
            <button
              className="px-1 text-sm"
              onClick={() => {
                handleCancelOrder.mutate();
              }}
            >
              {handleCancelOrder.isLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        // size="medium"
        show={showArchiveModal}
        onToggle={() => {
          setShowArchiveModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 space-y-2">
          <p className="text-sm font-semibold">Archive order</p>
          <p className="text-[12.5px] ">
            Are you sure you want to archive selected order.
          </p>
          <div className="flex items-center justify-start gap-4">
            <button
              className="px-1 text-sm"
              onClick={() => {
                setShowArchiveModal(false);
              }}
            >
              No
            </button>
            <button
              className="px-1 text-sm"
              onClick={() => {
                handleArchiveOrder.mutate();
              }}
            >
              {handleArchiveOrder.isLoading ? "Archiving..." : "Archive"}
            </button>
          </div>
        </div>
      </Modal>
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <MdKeyboardBackspace />
        <p className="font-semibold text-left">Back</p>
      </div>
      <div className="mb-3 flex items-center justify-between gap-2">
        <div>
          <p>Order No: {order?.orderNumber || ""}</p>
        </div>
        <div className="flex items-center gap-4">
          <button
            className="py-2 px-3 rounded bg-gray-100 text-[13px] inline-flex items-center gap-2.5"
            onClick={() => {
              setShowArchiveModal(true);
            }}
          >
            <BsArchive size={19} />
            Archive order
          </button>
          <button
            className="py-2 px-3 rounded bg-gray-100 text-[13px] inline-flex items-center gap-2.5"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <MdDeleteOutline size={20} />
            Cancel order
          </button>
        </div>
      </div>
      <div>
        {singleOrder.status === "loading" ||
        handleOrderStatusUpdate.isLoading ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : singleOrder.status === "error" ? (
          <p className="mt-8 text-sm font-semibold text-center text-gray-400">
            {singleOrder.error.message}
          </p>
        ) : order ? (
          <div className="relative">
            <>
              <div className="my-3 border border-b-0 border-gray-400 rounded ">
                <div className="">
                  <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400 font-semibold">
                    <div className="flex col-span-6">
                      <p className="hidden sm:block w-[50px] text-[10px] md:text-xs text-center p-1.5">
                        S/N
                      </p>
                      <p className="text-[10px] md:text-xs w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400">
                        ITEMS
                      </p>
                    </div>
                    <p className="text-[10px] md:text-xs col-span-1 p-1.5 text-center">
                      QTY
                    </p>
                  </div>
                  {order?.orderPayload.map((orderItem: any, index: any) => (
                    <div className="relative" key={index}>
                      <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400">
                        <div className="flex col-span-6 text-xs items-center justify-center">
                          <p className="hidden sm:block w-[50px] text-center p-1.5">
                            {index + 1}
                          </p>
                          <div className=" w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400 flex items-center font-medium gap-3">
                            <span className="">
                              {orderItem.itemName.replace(/_/g, " ")}
                            </span>
                            <span
                              className={classNames(
                                orderItem.itemSize === "" ||
                                  orderItem.itemSize === undefined
                                  ? "hidden"
                                  : ""
                              )}
                            >
                              -&nbsp;&nbsp;{orderItem.itemSize}
                            </span>
                            <span
                              className={classNames(
                                orderItem.itemSize === "" ||
                                  orderItem.itemSize === undefined
                                  ? "hidden"
                                  : ""
                              )}
                            >
                              -&nbsp;&nbsp;{orderItem.itemColor}
                            </span>
                            <BiInfoCircle
                              size={20}
                              className={classNames(
                                "cursor-pointer",
                                selectedItemId === orderItem.itemId &&
                                  openSingleItem === orderItem.itemId
                                  ? "text-blue-600"
                                  : ""
                              )}
                              onClick={() => {
                                if (!orderItem.itemId) {
                                  return;
                                }
                                setSelectedItemId(orderItem.itemId);
                                setOpenSingleItem(orderItem.itemId);
                                setSelectedIndex(index);
                              }}
                            />
                            <div className="relative ml-auto bg-white">
                              <button
                                className={classNames(
                                  "rounded-md text-xs font-semibold  py-1 px-3 flex items-center gap-1.5 justify-between border",
                                  orderItem.orderStatus === "Pending"
                                    ? "bg-[#F5F5F5] text-[#555555] border-[#555555]"
                                    : orderItem.orderStatus === "Active"
                                    ? "bg-[#F4FAFF] text-[#4056A6] border-[#4056A6]"
                                    : orderItem.orderStatus === "Completed"
                                    ? "bg-[#C4E8A9] text-[#2C921C] border-[#2C921C]"
                                    : "bg-[#FAE2A7] text-[#7D5901] border-[#7D5901]"
                                )}
                                onClick={() => {
                                  if (openStatus === orderItem.itemId) {
                                    setOpenStatus(-1);
                                    return;
                                  }
                                  setOpenStatus(orderItem.itemId);
                                  setSelectedItemId(null);
                                  setOpenSingleItem(null);
                                }}
                              >
                                {/* <span>{order.orderStatus}</span> */}
                                <span>{orderItem.orderStatus}</span>
                                <BiChevronDown size={25} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <p className=" col-span-1 p-1.5 text-center text-xs flex items-center justify-center">
                          {orderItem.quantityOrder}
                        </p>
                      </div>
                      {openSingleItem === orderItem.itemId && index === selectedIndex && (
                        <div className="p-3 absolute w-[300px] md:w-[400px] border rounded-md z-10 bottom-8 bg-white left-[5%] md:left-[20%] mx-auto shadow-md ">
                          {loadSingleItem.status === "loading" ||
                          loadSingleItem.isRefetching ? (
                            <div className="flex items-center justify-start py-5">
                              <ImSpinner3
                                size={18}
                                className="mx-auto animate-spin fill-primary"
                              />
                            </div>
                          ) : loadSingleItem.status === "error" ? (
                            <div className="relative flex items-center justify-center py-5">
                              <p className="text-center">Item not found</p>
                              <p className="absolute top-0 right-2">
                                <MdClear
                                  size={22}
                                  className="cursor-pointer hover:fill-blue-800"
                                  onClick={() => {
                                    setOpenSingleItem(-1);
                                    setSelectedIndex(-1);
                                    setSelectedItemId(null);
                                    queryClient.cancelQueries([
                                      "getSingleItem",
                                    ]);
                                  }}
                                />
                              </p>
                            </div>
                          ) : (
                            <>
                              <div className="grid grid-cols-4 gap-2.5">
                                <div className="rounded-md bg-gray-50">
                                  <img
                                    src={
                                      selectedItem?.s3ImageUrl
                                    }
                                    onError={(e) =>
                                      (e.currentTarget.src =
                                        "/media/image/productBg.png")
                                    }
                                    alt="item_image"
                                    className="w-full h-[75px] rounded-md bg-gray-50 object-cover"
                                  />
                                </div>
                                <div className="flex flex-col justify-between text-xs col-span-3 py-2.5">
                                  <div className="flex items-center justify-between gap-2 font-semibold">
                                    <p>{selectedItem?.itemName}</p>
                                    <p className="-mt-2.5 mr-1">
                                      <MdClear
                                        size={22}
                                        className="cursor-pointer hover:fill-blue-800"
                                        onClick={() => {
                                          setOpenSingleItem(-1);
                                          setSelectedIndex(-1);
                                          setSelectedItemId(null);
                                          queryClient.cancelQueries([
                                            "getSingleItem",
                                          ]);
                                        }}
                                      />
                                    </p>
                                  </div>
                                  <p className="">
                                    &#x20A6;{selectedItem?.itemPrice}
                                  </p>
                                  <p className="text-xs ">
                                    {selectedItem?.itemDescription}
                                  </p>
                                </div>
                                <span
                                  className="absolute hidden cursor-pointer top-1 right-1"
                                  onClick={() => {
                                    setOpenSingleItem(-1);
                                    setSelectedIndex(-1);
                                    setSelectedItemId(null);
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                      fill="#5A5A5A"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {openStatus === orderItem.itemId && (
                        <>
                          <div className="py-2 px-1 absolute border top-0 right-4 z-50 w-[120px] bg-white rounded-md">
                            {OrderStatus.map((status: any, index: number) => (
                              <p
                                className="px-3 py-3 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                                onClick={() =>
                                  handleOrderStatusUpdate.mutate({
                                    orderNumber: order.orderNumber,
                                    orderId: orderItem.order_specific_id,
                                    orderStatus: status.status,
                                  })
                                }
                                key={index}
                              >
                                {status.status}
                              </p>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <p className="mb-3 font-medium">Shipping option:</p>
                <div className="text-xs">
                  {order.billingDetail === null ||
                  order.billingDetail === undefined ? (
                    <div className="space-y-.5">
                      <p className="flex items-center gap-0.5">
                        <span className="text-gray-800">Delivery Option: </span>
                        <span className="font-medium">Pickup</span>
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-2.5">
                      <p className="flex items-center gap-0.5">
                        <span className="text-gray-800">Delivery Option: </span>
                        <span className="font-medium">Drop Off</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="">
                <p className="mb-3 font-medium">Shipping information: </p>
                <div className="flex flex-col gap-2.5 text-xs">
                  <p className="flex items-center gap-0.5">
                    <span className="">Name:&nbsp;</span>
                    <span className="">
                      {order.billingDetail === null ||
                      order.billingDetail === undefined
                        ? "No Name"
                        : order?.billingDetail.fullName}
                    </span>
                  </p>
                  <p className="flex items-center gap-0.5">
                    <span className="text-gray-800">Phone: </span>
                    <span className="font-medium">
                      {order.billingDetail.phone}
                    </span>
                  </p>
                  <p className="flex items-center gap-0.5">
                    <span className="text-gray-800">Email: </span>
                    <span className="font-medium">
                      {order.billingDetail.email}
                    </span>
                  </p>
                  {order.billingDetail.address === undefined ? null : (
                    <p className="flex gap-0.5">
                      <span className="text-gray-800">Address: </span>
                      <span className="font-medium">
                        {order.billingDetail.address}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </>
          </div>
        ) : (
          <p className="mt-8 text-xs font-semibold text-center text-gray-800">
            No order have been placed
          </p>
        )}
      </div>
      <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default SingleOrderPage;
