/* eslint-disable array-callback-return */

import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { TbFilter } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { PlatformRatingModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import moment from "moment";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { getPlatformRatings } from "../../../../api/Ratings";
import {
  getCustomerSatisfactionScore,
  updateReviewStatus,
} from "../../../../api/Utils";
import { ImSpinner3 } from "react-icons/im";
import {
  CUSTOMER_ECHO_DATA_SOURCES,
  classNames,
} from "../../../../utils";
import { MoreButton } from "../CustomerReview1";
import { BsEye, BsEyeSlash, BsFillCircleFill } from "react-icons/bs";

const AllCustomerReviewPage1 = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  let comments: any[] = [];
  let dateRange: string = `February 2023 - ${moment().format("MMM YYYY")}`;
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const [commentLabel, setCommentLabel] = useState("No Data");
  const [commentSource, setCommentSource] = useState("NONE");
  const [commentRating, setCommentRating] = useState<any>([]);
  const navigate = useNavigate();
  const [currentReview, setCurrentReview] = useState("");

  const handlefilterCommentLabelChange = (event: any) => {
    setCommentLabel(event.target.value);
  };

  const handlefilterCommentSourceSelect = (value: any) => {
    if (commentSource === "NONE") {
      setCommentSource(value + ",");
      return;
    }

    if (commentSource.includes(value)) {
      setCommentSource((prevState) => prevState.replace(value + ",", ""));
      return;
    }

    setCommentSource((prev) => `${prev + value + ","}`);
  };

  const handlefilterCommentRatingSelect = (value: any) => {
    if (commentRating.length === 0) {
      setCommentRating([]);
      return;
    }

    if (commentRating.includes(value)) {
      setCommentRating((prevState: any) =>
        prevState.filter((item: any) => item !== value)
      );
      return;
    }

    setCommentRating((prevState: any) => [value, ...prevState]);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setCommentSource("NONE");
    setCommentLabel("No Data");
    setCommentRating([]);
    setStartDateTimeStamp("0.000");
    setEndDateTimeStamp(`${new Date(Date.now()).getTime() / 1000}`);
    if (filter === 1) {
      setFilter(0);
      queryclient.refetchQueries(["getCustomerSatisfactionScore"]);
      return;
    }
    setFilter(0);
  };

  const ratings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getPlatformRatings"],
    queryFn: async () => {
      const response = await getPlatformRatings(`${queryKey}`);
      return response.data;
    },
  });

  const satisfaction = useInfiniteQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore", filter],
    queryFn: async ({ pageParam = "" }) => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: pageParam,
        timestamp: [startDateTimeStamp, endDateTimeStamp],
        commentSource: commentSource,
        commentLabel: commentLabel,
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
    onSuccess: (data: any) => {
      // console.log({ data });
      if (data === null || !data) {
        comments = [];
      } else {
        comments = data;
      }
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  const handleReviewToggle = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        commentId: data.commentId,
        statusUpload: data.status,
      };
      return await updateReviewStatus(payload);
    },
    onSuccess: () => {
      queryclient.invalidateQueries({ queryKey: [{ queryKey: ["getCustomerSatisfactionScorePaginated"] }] });
    },
  });


  if (satisfaction.data) {
    if ("pages" in satisfaction.data) {
      // console.log(satisfaction.data);
      satisfaction.data.pages.forEach((page) => {
        if (page !== null) {
          if ("Reviewers_Board" in page) {
            comments = [...comments, ...page.Reviewers_Board];
          }
        }

        dateRange = `${moment(comments[0]?.uploadTime).format(
          "MMMM YYYY"
        )} - ${moment(comments[comments.length - 1]?.uploadTime).format(
          "MMMM YYYY"
        )}`;
      });
    }
  }


  return (
    <div className="h-full min-h-[85vh] sm:h-[85vh] py-3 overflow-hidden space-y-4 flex flex-col">
    <div className="flex items-center justify-between">
      <Link
        to={`/smart-feedback/customer-review/`}
        onClick={(e: any) => {
          e.preventDefault();
          navigate(-1);
        }}
        relative="path"
        className="flex items-center space-x-1 text-sm font-semibold capitalize"
      >
        <BiChevronLeft size={28} className="-ml-2" /> Back
      </Link>
      <div className="relative">
        <button
          onClick={() => setShowDropdown((prevState) => !prevState)}
          className="flex items-center justify-center px-3 py-1 space-x-1.5 text-sm text-black border border-black rounded-md fill-black font-semibold"
        >
          <TbFilter size={20} /> Filter
        </button>
        {showDropdown && (
          <div
            id="dropdown"
            className="absolute text-sm right-0 mt-1 z-20 bg-white rounded-lg shadow w-[280px] overflow-x-hidden mainScrollbar"
          >
            <div className="p-2 py-1 bg-[#eff4fe] text-gray-800 font-semibold">
              Date
            </div>
            <div className="flex items-center p-2 space-x-2 bg-white">
              <div date-rangepicker="true">
                <span className="text-xs text-gray-500">Start Date</span>
                <div className="relative">
                  <input
                    name="start"
                    type="date"
                    className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                    placeholder="Select date"
                    onChange={(e) =>
                      setStartDateTimeStamp(`${new Date(e.target.value).getTime() / 1000}`)
                    }
                  />
                </div>
              </div>
              <div date-rangepicker="true">
                <span className="text-xs text-gray-500">End Date</span>
                <div className="relative">
                  <input
                    name="end"
                    type="date"
                    className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                    placeholder="Select date"
                    onChange={(e) =>
                      setEndDateTimeStamp(`${new Date(e.target.value).getTime() / 1000}`)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="p-2 py-1 bg-[#eff4fe] text-gray-800 font-semibold">
              Review Type
            </div>
            <div className="flex items-center gap-2 p-2 text-gray-400 bg-white">
              <label className="flex items-center h-5 space-x-1 cursor-pointer">
                <input
                  type="radio"
                  value="NEG"
                  checked={commentLabel === "NEG"}
                  onChange={handlefilterCommentLabelChange}
                  className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                />
                <span className="text-xs font-medium text-gray-600">
                  Positive
                </span>
              </label>
              <label className="flex items-center h-5 space-x-1 cursor-pointer">
                <input
                  type="radio"
                  value="POS"
                  checked={commentLabel === "POS"}
                  onChange={handlefilterCommentLabelChange}
                  className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                />
                <span className="text-xs font-medium text-gray-600">
                  Negative
                </span>
              </label>
            </div>
            <div className="p-2 py-1 bg-[#eff4fe] text-gray-800">
              Data Source
            </div>
            <div className="flex flex-col gap-2 py-2 pl-3 text-gray-400 bg-white">
              {CUSTOMER_ECHO_DATA_SOURCES.map(
                (source: any, index: number) => {
                  return (
                    <div key={index} className="h-5">
                      <label
                        className={classNames(
                          "flex items-center space-x-1 text-xs",
                          source.key === "web" || source.key === "qr_code"
                            ? "cursor-pointer"
                            : "cursor-default"
                        )}
                      >
                        <input
                          type="checkbox"
                          value={source.key}
                          className="w-3.5 h-3.5 font-medium text-gray-900 bg-gray-100 border-gray-300 accent-blue-600"
                          checked={commentSource.includes(source.key)}
                          onChange={() =>
                            handlefilterCommentSourceSelect(source.key)
                          }
                          disabled={
                            source.key === "web"
                              ? false
                              : source.key === "qr_code"
                              ? false
                              : true
                          }
                        />{" "}
                        <span>{source.name}</span>
                      </label>
                    </div>
                  );
                }
              )}
            </div>
            <div className="bg-[#eff4fe] flex items-center justify-center p-2 text-xs space-x-2">
              <button
                className="w-1/2 px-4 py-2 font-bold text-gray-700 truncate bg-transparent border rounded"
                onClick={(e) => {
                  handleClearFilter(e);
                  setShowDropdown(false);
                }}
              >
                Cancel
              </button>
              <button
                className="w-1/2 px-4 py-2 font-bold text-white truncate rounded bg-primary"
                onClick={() => {
                  if (filter === 0) {
                    setFilter(1);
                  } else {
                    setFilter(0);
                  }
                  setShowDropdown(false);
                }}
              >
                Show Results
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex items-center justify-between my-3 space-x-4">
        <h4 className="text-base font-bold text-black capitalize">Reviews</h4>
        <button className="p-2 text-xs font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md lg:text-xs lg:px-3 lg:py-2">
          {dateRange}
        </button>
      </div>
      <div className="relative flex-1 h-full pt-2 pb-4 space-y-4 overflow-y-auto mainScrollbar">
        {satisfaction.status === "loading" ||
        (satisfaction.isRefetching && filter === 1) ? (
          <div className="flex items-center justify-start h-full py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : satisfaction.status === "error" ? (
          <span>Error: {satisfaction.error.message}</span>
        ) : (
          comments.length > 0 &&
          comments.map((comment: any, index: number) => {
            const similarity_score: string = "0.00";
            const source = comment.commentSource;
            const sentiment_tag: string = comment.commentLabel;

            return (
              <div
                key={index}
                className={classNames(
                  "space-y-1",
                  handleReviewToggle.isLoading &&
                    currentReview === comment.commentId
                    ? "opacity-40"
                    : "opacity-100"
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center -ml-1 sm:ml-0 space-x-1.5 text-xs font-bold">
                    <p className="hidden text-black sm:block">
                      {comment.customer !== ""
                        ? comment.customer
                        : "Anonymous"}
                    </p>
                    <p className="text-black md:hidden">
                      {comment.customer !== ""
                        ? comment.customer.substring(0, 15) + "..."
                        : "Anonymous"}
                    </p>
                    <span className="hidden sm:inline">
                      {comment.reviewStatus === "" ||
                      comment.reviewStatus === "public" ? (
                        <BsEye size={14} />
                      ) : (
                        <BsEyeSlash size={14} />
                      )}
                    </span>
                    <BsFillCircleFill
                      size={10}
                      className={classNames(
                        sentiment_tag === "NEG"
                          ? "fill-red-600"
                          : sentiment_tag === "POS"
                          ? "fill-green-700"
                          : "fill-orange-500"
                      )}
                    />
                  </div>
                  <div className="flex items-center md:items-start gap-1 sm:gap-2.5 text-xs font-semibold">
                    <p className="text-black">
                      {moment(comment.uploadTime).format("MM/DD/YYYY")}
                    </p>
                    <MoreButton
                      customer={
                        comment.customer !== ""
                          ? comment.customer
                          : "Anonymous"
                      }
                      public={
                        comment.reviewStatus === "" ||
                        comment.reviewStatus === "public"
                      }
                      toggleStatus={() => {
                        setCurrentReview(comment.commentId);
                        handleReviewToggle.mutate({
                          commentId: comment.commentId,
                          status:
                            comment.reviewStatus === "" ||
                            comment.reviewStatus === "public"
                              ? "private"
                              : "public",
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-10/12 text-xs font-medium text-gray-500">
                  <p>{comment.userReview}</p>
                </div>
                {index === comments.length - 1 && (
                  <div className="pt-2 text-center bg-white">
                    <button
                      onClick={() => satisfaction.fetchNextPage()}
                      disabled={
                        !satisfaction.hasNextPage ||
                        satisfaction.isFetchingNextPage
                      }
                      className="text-xs text-blue-500"
                    >
                      {satisfaction.isFetchingNextPage
                        ? "Loading more..."
                        : satisfaction.hasNextPage
                        ? "Load More"
                        : "That's all we got!"}
                    </button>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  </div>
  );
};

export default AllCustomerReviewPage1;
