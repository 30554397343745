// @ts-nocheck

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Customized,
  Cross,
  AreaChart,
  Area,
  PieChart,
  Pie,
  Cell,
  ComposedChart,
  Bar,
} from "recharts";
import { getRealtimePerceptionScores } from "../../../../../../api/Utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SET_BPT_SCORES } from "../../../../../../redux/Utils";
import { ChartDataInterval } from "../../../../../../enums";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { useEffect, useState } from "react";
import { UserProfileModel } from "../../../../../../models";
import SkeletonLoader from "../../../../../../components/Dashboard/SkeletonLoader";
import { classNames } from "../../../../../../utils";
import { FaRobot } from "react-icons/fa";

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fontSize={11}>
        {payload.value}
      </text>
    </g>
  );
};

const NewBPTComponent = () => {
  const [interval, setInterval] = useState<ChartDataInterval>(
    ChartDataInterval.Monthly
  );
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const scores: number[] = useAppSelector((state) => state.utils.bpt_scores);
  const dispatch = useAppDispatch();
  const [tempScores, setTempScores] = useState<number[]>([]);
  const [randomData, setRandomData] = useState([]);
  const [numToAdd, setNumToAdd] = useState(40);
  let bptScoresArray = [];
  const [selectedInt, setSelectedInt] = useState("daily");

  const toggleChartData = (value: ChartDataInterval) => {
    setInterval(value);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setFilter(0);
    setStartDateTimeStamp("");
    setEndDateTimeStamp("");
    if (filter === 1) {
      dispatch(SET_BPT_SCORES(tempScores));
      queryclient.refetchQueries(["getRealtimePerceptionScores"]);
    }
  };

  const { status, data, error, isRefetching, isFetching } = useQuery<
    any,
    Error
  >({
    queryKey: ["getRealtimePerceptionScores"],
    initialData: scores,
    queryFn: async () => {
      const payload = {
        platformKey: queryKey,
        // timestampData: [startDateTimeStamp, endDateTimeStamp],
      };

      const response = await getRealtimePerceptionScores(payload);
      return response.data;
    },
    select: (data) => {
      // console.log(data);
      if ("Items" in data) {
        if (data.Items.length > 0) {
          return {
            scores: data.Items.map((item: any, i: number) => {
              return item.perceptionScore?.toFixed(0);
            }),
            timestamps: data.Items.map((item: any, i: number) => {
              return item.uploadTime;
            }),
          };
        }
      }

      if ("bpt_index" in data) {
        // const prevScores = queryclient.getQueryData<any>(["getRealtimePerceptionScores"]);

        return {
          // scores: [data.bpt_index.perceptionScore?.toFixed(0), ...scores],
          scores: data.bpt_index.scoreProjections.map(
            (item: any, i: number) => {
              return item.perceptionScore?.toFixed(0);
            }
          ),
        };
      }

      return { scores: [], timestamps: [] };
    },
    onSuccess: (data) => {
      dispatch(SET_BPT_SCORES(data.scores));
    },
  });

  useEffect(() => {
    // Minimum and maximum values for the range
    const minVal = 10;
    const maxVal = 100;

    // Function to generate a random integer within the range
    function getRandomInt(min: any, max: any) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Generate random data using a loop
    const newData = [];
    for (let i = 0; i < numToAdd; i++) {
      newData.push(getRandomInt(minVal, maxVal));
    }

    const data = [...newData].map((d: any) => {
      return {
        score: Number(d),
      };
    });
    setRandomData(data);
    // console.log(data)
  }, [numToAdd]);

  if (!data)
    return <SkeletonLoader className="h-[calc(54vh_-_1.25rem)] rounded-md" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  // console.log(data);
  if (data) {
    bptScoresArray = data.scores.map((d: any) => {
      return {
        score: Number(d),
      };
    });
  }

  const data12 = [
    { name: "Group A", value: 800 },
    { name: "Group B", value: 300 },
    // { name: "Group C", value: 300 },
    // { name: "Group D", value: 200 },
  ];
  const COLORS12 = ["#0088FE", "#FF8042", "#00C49F", "#FFBB28"];

  const data1234 = [
    {
      name: "Mon",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Tue",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Wed",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Thur",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Fri",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Sat",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Sun",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const data12345 = [
    {
      name: "Jan-Feb",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Mar-Apr",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "May-Jun",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Jul-Aug",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Sep-Oct",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Nov-Dec",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    // {
    //   name: "Jan-Feb",
    //   uv: 4000,
    //   pv: 2400,
    //   amt: 2400,
    // },
    // {
    //   name: "Mar-Apr",
    //   uv: 3000,
    //   pv: 1398,
    //   amt: 2210,
    // },
    // {
    //   name: "May-Jun",
    //   uv: 2000,
    //   pv: 9800,
    //   amt: 2290,
    // },
    // {
    //   name: "Jul-Aug",
    //   uv: 2780,
    //   pv: 3908,
    //   amt: 2000,
    // },
    // {
    //   name: "Sep-Oct",
    //   uv: 1890,
    //   pv: 4800,
    //   amt: 2181,
    // },
    // {
    //   name: "Nov-Dec",
    //   uv: 2390,
    //   pv: 3800,
    //   amt: 2500,
    // },
    // {
    //   name: "Sun",
    //   uv: 3490,
    //   pv: 4300,
    //   amt: 2100,
    // },
  ];

  return (
    <div className="w-full h-[340px] bg-tranparent p-3 rounded-lg text-sm border border-gray-200 overflow-hidden">
      <div className="grid grid-cols-5 gap-4">
        <div className="space-y-0.5 col-span-2 flex flex-col gap-2.5 group">
          <p className="font-semibold">Cusecho score</p>
          <div className="flex items-center gap-8">
            <PieChart width={200} height={200}>
              <Pie
                data={data12}
                cx={100}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {data12.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS12[index % COLORS12.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="flex flex-col gap-1 text-[12.5px]">
              <div className="flex items-center gap-1.5">
                <div className="h-3 w-3 bg-blue-500 rounded-full"></div>
                <p className="">Positive</p>
              </div>
              <div className="flex items-center gap-1.5  ">
                <div className="h-3 w-3 bg-orange-500 rounded-full"></div>
                <p className="">Negative</p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p className="font-semibold">
              <span className="text-7xl text-gray-800">7</span>
              <span className="text-2xl text-gray-600 -ml-2">/10</span>
            </p>
            <p className="text-xs inline-flex gap-0.5 justify-between">
              Your cusecho score is healthy and your customers are very happy
              with you.{" "}
              <span>
                <FaRobot
                  size={16}
                  className="text-[#0072BB]/90 animate-ping cursor-pointer opacity-0 group-hover:opacity-100"
                />
              </span>
            </p>
          </div>
        </div>
        <div className="col-span-3 space-y-0.5 flex flex-col gap-4">
          <div className="flex items-center justify-end gap-24">
            <p className="font-semibold text-center">
              Business perception tracker
            </p>
            <div className="flex items-center gap-2">
              <button
                className={classNames(
                  "px-2 py-0.5 text-[11px] border border-gray-200 rounded",
                  selectedInt === "daily"
                    ? "text-white bg-blue-500"
                    : "text-gray-900 bg-white"
                )}
                onClick={() => {
                  setSelectedInt("daily");
                }}
              >
                Daily
              </button>
              <button
                className={classNames(
                  "px-2 py-0.5 text-[11px] border border-gray-200 rounded",
                  selectedInt === "monthly"
                    ? "text-white bg-blue-500"
                    : "text-gray-900 bg-white"
                )}
                onClick={() => {
                  setSelectedInt("monthly");
                }}
              >
                Monthly
              </button>
            </div>
          </div>
          <div>
            <ResponsiveContainer width="100%" height={260}>
              <ComposedChart
                data={selectedInt === "daily" ? data1234 : data12345}
              >
                <XAxis
                  dataKey="name"
                  tick={<CustomizedAxisTick fontSize={11} />}
                />
                <YAxis fontSize={11} />
                {/* <Tooltip /> */}
                {/* <Legend /> */}
                <CartesianGrid stroke="#f5f5f5" />
                <Bar
                  dataKey={selectedInt === "daily" ? "pv" : "uv"}
                  barSize={34}
                  fill="#D3D3D3"
                />
                <Line
                  type="monotone"
                  dataKey={selectedInt === "daily" ? "pv" : "uv"}
                  stroke="#5EC153"
                  strokeWidth={1.5}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBPTComponent;
