//@ts-nocheck

import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../utils";
import { MdClose } from "react-icons/md";

interface Props {
  show: boolean;
  size?: "xl" | "large" | "medium" | "normal";
  onToggle?: (value: boolean) => void;
  children?: React.ReactNode;
}

const Modal = (props: Props) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const closeModal = () => {
    props.onToggle(false);
    setShow(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <Transition
      show={show}
      // enter="transition-opacity duration-75"
      // enterFrom="opacity-100"
      // enterTo="opacity-100"
      // leave="transition-opacity duration-75 delay-100"
      // leaveFrom="opacity-100"
      // leaveTo="opacity-0"
    >
      <div
        style={{ zIndex: 1000 }}
        ref={ref}
        onClick={(event) => event.target === ref.current && closeModal()}
        className={classNames(
          "fixed top-0 left-0 bg-black bg-opacity-40 h-screen w-full  flex justify-center"
        )}
      >
        <Transition.Child
          className={classNames(
            "w-10/12 max-h-[90vh] self-center",
            props.size === "large"
              ? "md:w-7/12"
              : props.size === "xl"
              ? "md:w-[95vw]"
              : props.size === "medium"
              ? "md:w-[40vw]"
              : props.size === "normal"
              ? "md:w-[15vw]"
              : "md:w-5/12"
          )}
          // enter="ease-out duration-100  delay-75"
          // enterFrom="opacity-0 scale-50"
          // enterTo="opacity-100 scale-100"
          // leave="ease-out duration-100"
          // leaveFrom="opacity-100 scale-100"
          // leaveTo="opacity-0 scale-50"
        >
          <div className="bg-white rounded-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-end px-8 py-4 md:hidden ">
              <MdClose
                size={18}
                className="inline cursor-pointer md:hidden text-gray-800"
                onClick={closeModal}
              />
              {/* <svg
                
                
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00081 6.99984L12.2438 12.2428M1.75781 12.2428L7.00081 6.99984L1.75781 12.2428ZM12.2438 1.75684L6.99981 6.99984L12.2438 1.75684ZM6.99981 6.99984L1.75781 1.75684L6.99981 6.99984Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}
            </div>

            {props.children}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default Modal;
