import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { getKPIs } from "../../../../api/KPIs";
import SkeletonLoader from "../../SkeletonLoader";
import { METRICS_COLORS, classNames } from "../../../../utils";

const KPI = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getKPIs"],
    queryFn: async () => {
      const response = await getKPIs(queryKey);
      return response.data;
    },
    select: (data) => {
      const saved = data.Item;
      delete saved.kpi_metrics;
      delete saved.platform_key;
      delete saved.kpi_metadata;
      const newData = Object.values(saved);
      return newData
        .filter((metric: any, index: number) => metric[0].status === "1")
        .slice(0, 5)
        .sort((a: any, b: any) => 0.5 - Math.random());
    },
  });

  if (status === "loading")
    return <SkeletonLoader className="rounded-md h-[calc(54vh_-_1.25rem)]" />;

  if (status === "error") return <span>Error: {error.message}</span>;
  return (
    <div className="h-[calc(54vh_-_1.25rem)] bg-white rounded-md shadow-md overflow-hidden flex flex-col">
      <div className="px-4 py-2.5 bg-white sticky top-0">
        <p className="text-xs font-semibold text-gray-500 lg:text-sm">
          Business metrics
        </p>
      </div>
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        {data.length > 0 &&
          data.map((metric: any, index: number) => {
            const tagId = Number(
              metric[0].tag_name.replace("Success_Metric_", " ")
            );
            // const newIndex = (tagId > 4) ? 4 : (tagId - 1);
            const newIndex = index;
            return (
              <div key={index} className="mb-2">
                <p
                  className={classNames(
                    "px-4 py-1 text-xs lg:text-xs font-semibold text-gray-500 capitalize"
                  )}
                  style={{ backgroundColor: `${METRICS_COLORS[newIndex]}40` }}
                >
                  {metric[0].metric}
                </p>
                <div
                  className="px-2 py-1 mx-2 my-2 border rounded"
                  style={{
                    borderColor: `${METRICS_COLORS[newIndex]}40`,
                    boxShadow: `0 0 5px ${METRICS_COLORS[newIndex]}40`,
                  }}
                >
                  <p className="text-xs font-medium leading-normal text-gray-400 lg:font-semibold">
                    {metric[0].question !== "" ? metric[0].question : ""}
                  </p>
                  <div className="flex items-center justify-between mt-1">
                    <div className="flex items-center gap-0.5 text-gray-500 lg:gap-2">
                      <span className="text-xs lg:text-xs">
                        Related Reviews :{" "}
                      </span>
                      <span className="text-xs text-gray-500 lg:text-sm">
                        {"related_reviews_count" in metric[0]
                          ? metric[0].related_reviews_count
                          : 0}
                      </span>
                    </div>
                    {/* <Link to={`/smart-feedback/display-board/${metric[0].tag_name.toLowerCase().replace(/\s+/g, '-')}`} className="px-2 py-1 text-sm text-white rounded bg-primary">More Info</Link> */}
                    <Link
                      to={`/smart-feedback/display-board/${tagId}`}
                      className="px-0.5 py-1 text-xs text-white rounded lg:px-2 lg:text-xs bg-primary"
                    >
                      More Info
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        {data.filter((metric: any, index: number) => metric[0].status === "1")
          .length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-lg text-center text-gray-400">
              You don&apos;t have any history yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default KPI;
