import PageHeader from "../../../components/Dashboard/PageHeader";
import { AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { Fragment, useState } from "react";
import { Searchbar } from "../../../components/Dashboard/Navbar";
import NoData from "../../../components/Dashboard/NoData";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

const DataHistoryPage = () => {
  const columns = [
    {
      name: "Week",
      selector: (row: { week: any }) => row.week,
    },
    {
      name: "Total Review",
      selector: (row: { total_review: any }) => row.total_review,
      sortable: true,
    },
    {
      name: "Positive",
      selector: (row: { positive: any }) => row.positive,
      sortable: true,
    },
    {
      name: "Negative",
      selector: (row: { negative: any }) => row.negative,
      sortable: true,
    },
    {
      name: "File Size",
      selector: (row: { fileSize: any }) => row.fileSize,
    },
    {
      name: "Actions",
      button: true,
      cell: (row: any) => (
        <div className="flex items-baseline gap-2">
          <AiOutlineDownload
            className="text-blue-500 hover:text-blue-700"
            size={22}
            onClick={(e) => handleButtonClick(e, row.id)}
          />
          <AiOutlineDelete
            className="text-red-500 hover:text-red-700"
            size={22}
            onClick={(e) => handleButtonClick(e, row.id)}
          />
        </div>
      ),
    },
  ];

  const handleButtonClick = (e: any, id: any) => {
    e.preventDefault();
    console.log("Row Id", id);
  };

  const data = [
    {
      id: 1,
      week: 1,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 2,
      week: 2,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 3,
      week: 3,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 4,
      week: 4,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 5,
      week: 5,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 6,
      week: 6,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 7,
      week: 7,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 8,
      week: 8,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 9,
      week: 9,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 10,
      week: 10,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 11,
      week: 11,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 12,
      week: 12,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 13,
      week: 13,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 14,
      week: 14,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 15,
      week: 15,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 16,
      week: 16,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 17,
      week: 17,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 18,
      week: 18,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 19,
      week: 19,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 20,
      week: 20,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 21,
      week: 21,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
    {
      id: 22,
      week: 22,
      total_review: 1233,
      positive: 1234,
      negative: 455,
      fileSize: "345kb",
    },
    {
      id: 23,
      week: 23,
      total_review: 13423,
      positive: 12344,
      negative: 4575,
      fileSize: "345kb",
    },
    {
      id: 24,
      week: 24,
      total_review: 16723,
      positive: 12134,
      negative: 4525,
      fileSize: "345kb",
    },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const handleFilter = () => {};
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      {/* <div className="space-y-1">
        <PageHeader title="Data History" />
        {filteredData.length < 1 ? (
          <NoData />
        ) : (
          <div className="h-[80vh] bg-white rounded-md shadow-md text-2xl overflow-x-hidden">
            <div className="flex items-center justify-between p-2">
              <div className="w-2/3 text-sm lg:w-1/3">
                <Searchbar />
              </div>
              <p className="p-2 text-sm text-white rounded-md bg-primary hover:bg-blue-700">
                Download .xlsx
              </p>
            </div>
            <DataTable
              className="text-base"
              // @ts-ignore
              columns={columns}
              data={filteredData}
              selectableRows
              fixedHeader
              pagination
            />
          </div>
        )}
      </div> */}
      <div className="space-y-1">
        <PageHeader title="Data History" />
        <div className="h-[80vh] bg-white rounded-md shadow-md text-2xl overflow-x-hidden">
          <div className="flex items-center justify-between p-2">
            <div className="w-2/3 text-sm lg:w-1/3">
              <Searchbar />
            </div>
            <p className="p-2 text-sm text-white rounded-md bg-primary hover:bg-blue-700">
              Download .xlsx
            </p>
          </div>
          <DataTable
            className="text-base"
            // @ts-ignore
            columns={columns}
            data={filteredData}
            selectableRows
            fixedHeader
            pagination
          />
        </div>
      </div>
      {/* <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            // setShowModal(false);
            navigate(-1);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[380px] md:w-[460px] lg:w-[520px] max-w-2xl transform text-center overflow-hidden rounded-md bg-white px-6 align-middle shadow-xl transition-all">
                  <div className="text-center my-14">
                      <h4 className="text-2xl font-semibold">Your Data History is Important</h4>
                      <p className="mt-2 text-sm font-medium leading-tight text-gray-700">
                          You are currently on the free plan but getting more features is easy
                      </p>
                      <Link to="/settings/subscriptions" className="inline-block px-8 py-3 mt-6 text-lg font-semibold text-center text-white rounded-md bg-primary">Request An Upgrade</Link>
                      <p className="hidden mt-3 text-base font-medium leading-tight text-gray-700">
                          Learn about our plans and pricing <Link to="https://www.cusecho.com/pricing" target="_blank" className="underline text-primary underline-offset-1">here</Link>
                      </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}
    </>
  );
};

export default DataHistoryPage;
