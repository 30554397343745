import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../../components/Auth/Forms/Input";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import UserPool from "../../../setup/userpool/index";
import { LOGIN, SET_TOKEN } from "../../../redux/Auth";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { AppDispatch } from "../../../setup/redux/Store";
import { SET_USER } from "../../../redux/User";
import Alert from "../../../components/Auth/Forms/Alert/indext";
import { StoreModel, UserProfileModel } from "../../../models";
import moment from "moment";
import { SESSION_TIME_KEY } from "../../../utils";
import { getAttentionTrigger } from "../../../api/settings";
import { ImSpinner2 } from "react-icons/im";
import { createSessionId, generateAuthToken } from "../../../api/Auth";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import SocialButton from "../../../components/Auth/Forms/SocialButton";

const initialValues = {
  email: "",
  password: "",
};

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  password: Yup.string()
    .min(8, "Minimum 8 letters")
    .max(20, "Maximum 20 letters")
    .required("Password is required"),
});

const LoginPage1 = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const userAuth = useAppSelector((state) => state.auth.userData);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      // setSubmitting(true);
      setLoading(true);
      setError("");
      return await new Promise((resolve, reject) => {
        const Username = values.email;
        const Password = values.password;
        const user = new CognitoUser({ Username, Pool: UserPool });

        const authDetails = new AuthenticationDetails({
          Username,
          Password,
        });

        var loginTimeOfUser = new CognitoUserAttribute({
          Name: "gender",
          // @ts-ignore
          Value: new Date(),
        });

        let attributeList: CognitoUserAttribute[] = [];
        attributeList.push(loginTimeOfUser);

        user.authenticateUser(authDetails, {
          onSuccess: (data: any) => {
            // console.log("test",data);
            resolve(data);
            const jwt = data.idToken.jwtToken;
            const sessionId = data.accessToken.payload.event_id;

            localStorage.setItem("tokenAPI_KEY", jwt);

            user.getUserAttributes(async function (err, result) {
              let data = result as CognitoUserAttribute[];

              if (err) {
                setError(err.message);
                // setSubmitting(false);
                return;
              }

              let _attributes: any = {};

              data.forEach((attr) => (_attributes[attr.Name] = attr.Value));
              let attentionVariable;

              try {
                const response = await getAttentionTrigger(
                  _attributes["profile"] + "%26" + _attributes["email"]
                );
                attentionVariable = response.data?.Attention_Variable;
              } catch (error) {
                console.log(error);
              }

              try {
                const response = await createSessionId(Username, sessionId);
                // console.log(response);
                
              } catch (error) {
                console.log(error);
              }

              const userData: UserProfileModel = {
                username: _attributes["sub"],
                fullName: _attributes["name"],
                emailVerified:
                  _attributes["email_verified"] === "true" ? true : false,
                email: _attributes["email"],
                address:
                  _attributes["address"] === "address"
                    ? "N/A"
                    : _attributes["address"],
                country: _attributes["locale"],
                preferredUsername: _attributes["preferred_username"],
                platformAccountId: _attributes["profile"],
                queryKey: _attributes["profile"] + "%26" + _attributes["email"],
                attentionTrigger: attentionVariable ?? "5",
                sessionId,
              };

              const storeData: StoreModel = {
                name: _attributes["given_name"],
                address:
                  _attributes["address"] === "address"
                    ? "N/A"
                    : _attributes["address"],
                country: _attributes["locale"],
                currency: _attributes["custom:currency"],
                phoneNumber:
                  _attributes["phone_number"] === "+11111111111"
                    ? "N/A"
                    : _attributes["phone_number"],
                employeeNumber: _attributes["custom:employee"],
              };

              if (
                _attributes["address"] === "address" ||
                _attributes["address"] === undefined
              ) {
                localStorage.setItem(SESSION_TIME_KEY, moment().format());
                dispatch(
                  LOGIN({
                    username: userAuth.username,
                    password: userAuth.password,
                  })
                );

                user.updateAttributes(attributeList, (err, result) => {
                  console.log(result);
                  if (result) {
                    dispatch(SET_USER({ profile: userData, store: storeData }));
                    localStorage.setItem("completeRegistration", "1");
                    setLoading(false);
                    navigate("/register");
                    // setSubmitting(false);
                    return;
                  }
                });
              }

              user.updateAttributes(attributeList, (err, result) => {
                console.log(result);
                if (result) {
                  localStorage.setItem(SESSION_TIME_KEY, moment().format());
                  dispatch(
                    LOGIN({
                      username: userAuth.username,
                      password: userAuth.password,
                    })
                  );

                  dispatch(SET_USER({ profile: userData, store: storeData }));
                  setLoading(false);
                  navigate("/smart-feedback/customer-review");
                  return;
                }
              });
            });
          },
          onFailure: (err) => {
            console.error(err);
            // console.log(err.message)
            // user.resendConfirmationCode((err, result) => {
              
            //   if (err) {
            //     console.error('Error resending confirmation code:', err);
            //   } else {
            //     console.log('Confirmation code resent successfully');
            //     alert("Complete your sign up, a code has been sent to your email")
            //     navigate(`/complete-registration/${Username}/${encode(Password)}`)
            //   }
            // })
            reject(err);
            setError(err.message);
            setLoading(false);
            // setSubmitting(false);
          },
          newPasswordRequired: (data) => {
            // console.log("PR", data);
            resolve(data);
            setLoading(false);
            // setSubmitting(false);
          },
        });
      });
    },
  });

  const authenticateWithGoogle = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    }).then(async (data) => {
      const response = await generateAuthToken();
      if (response) {
        dispatch(SET_TOKEN({ token: response.access_token }));
      }
      localStorage.setItem(SESSION_TIME_KEY, moment().format());
      dispatch(
        LOGIN({ username: userAuth.username, password: userAuth.password })
      );
    });
  };

  return (
    <div className="flex items-center justify-center w-full h-screen px-6 py-12">
      <div className=" absolute top-4 left-[4%] flex">
        <Link to="https://www.cusecho.com/" target="_blank">
          <img
            src="media/image/cusecho-onboarding-logo.png"
            alt=""
            className="w-[130px]"
          />
        </Link>
      </div>
      <div className="absolute top-4 right-[4%] flex">
        <button
          className="text-sm py-2 px-6 font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]"
          onClick={() => navigate("/register")}
        >
          Sign Up
        </button>
      </div>
      <div className="w-[500px] max-w-[500px] mx-auto">
        <div className="">
          <h3 className="text-left font-semibold text-xl text-[#263C6B]">
            Sign In
          </h3>
        </div>
        <div className="mt-4">
          {error && <Alert type="error" message={error} />}
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full">
              <Input
                formik={formik}
                name="email"
                label="Email"
                type="text"
                placeHolder="Enter your email address"
              />
              {formik.errors.email && (
                <InputMessage message={formik.errors.email} />
              )}
            </div>
            <div className="w-full mt-4">
              <div className="relative">
                <Input
                  formik={formik}
                  name="password"
                  label="Password"
                  type={passwordIsVisible ? "text" : "password"}
                  placeHolder="Please enter a strong password"
                />

                <button
                  type="button"
                  onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                  className="absolute outline-none text-[#263C6B] right-4 inset-y-7"
                >
                  {passwordIsVisible ? (
                    <AiOutlineEye size={25} />
                  ) : (
                    <AiOutlineEyeInvisible size={25} />
                  )}
                </button>
              </div>
              {formik.errors.password && (
                <InputMessage message={formik.errors.password} />
              )}
            </div>
            <div className="w-full mt-6">
              <button
                type="submit"
                disabled={loading}
                className="bg-[#263C6B] w-full py-2 text-lg font-semibold text-center text-white outline-none rounded-md"
              >
                {loading ? (
                  <ImSpinner2 size={28} className="mx-auto animate-spin" />
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="flex items-center justify-end w-full mt-1 text-sm">
              <Link
                to="/forgot-password"
                className="text-sm font-normal text-gray-900"
              >
                Forgot password?
              </Link>
            </div>
          </form>
        </div>
        <p className="hidden mt-2 text-base font-normal text-center text-gray-900">
          Don't have a Cusecho account?
          <Link
            to="/register"
            className="ml-1 text-blue-700 hover:text-blue-800 md:text-primary"
          >
            Create Now
          </Link>
        </p>
        <div className="flex items-center justify-center hidden" >
          <SocialButton
            icon="google"
            value="Continue with Google"
            // disabled={true}
            onClick={authenticateWithGoogle}
          />
        </div>

      </div>
    </div>
  );
};

export default LoginPage1;
