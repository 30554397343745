import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import Modal from "../../../../components/Dashboard/Modal";

const arr = [
  {
    title: "Emails",
  },
  {
    title: "SMS",
  },
  {
    title: "X/Twitter",
  },
  {
    title: "Facebook",
  },
  {
    title: "Instagram",
  },
  {
    title: "Linkedin",
  },
  {
    title: "Tiktok",
  },
];

const CampaignManagerAccountSettings = () => {
  const navigate = useNavigate();
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [step, setStep] = useState(0);

  return (
    <>
      <Modal
        size="medium"
        show={showAddAccountModal}
        onToggle={() => {
          setShowAddAccountModal(false);
          setStep(0);
        }}
      >
        {step === 1 ? (
          <div className="p-4 text-gray-800 hidden">
            <div className="space-y-3">
              <p className="font-semibold text-sm">Connect account</p>
              <p className="text-gray-800 text-xs">
                You will be redirected to your email account. Please ensure your
                have your email open. Then grant permissions to the Cusecho SBRP
                application to send, delete and track emails.
              </p>
              <div className="flex flex-col gap-0.5">
                <label htmlFor="" className="text-xs text-gray-700">
                  Select email provider
                </label>
                <select
                  name="cars"
                  id="cars"
                  className="py-2 px-3 rounded border border-gray-300 w-full outline-none text-sm"
                >
                  {/* <option value="volvo">Volvo</option>
              <option value="saab">Saab</option>
              <option value="opel">Opel</option>
              <option value="audi">Audi</option> */}
                </select>
              </div>
              <div className="flex justify-end">
                <button className="text-white bg-blue-500 rounded py-2 px-4 text-xs">
                  Continue to email
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4 text-gray-800">
            <div className="space-y-3">
              <p className="font-semibold text-sm">Connect account</p>
              <p className="text-gray-800 text-xs">
                You will be redirected to your X/Twitter account. Please ensure
                your have your X/Twitter account open. Then grant permissions to
                the Cusecho SBRP application to send, delete and track posts.
              </p>
              <div className="flex justify-end">
                <button className="text-white bg-blue-500 rounded py-2 px-4 text-xs">
                  Continue to X/Twitter
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <div className=" overflow-y-auto overflow-x-hidden relative space-y-4 ">
        <div className="flex items-center gap-1">
          <button
            onClick={() => navigate(-1)}
            className="text-[#171717] font-semibold text-sm inline-flex items-center gap-1"
          >
            <BsArrowLeft className="text-gray-700" size={18} />
            Accounts
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {arr.map((item: any, index: number) => (
            <div
              className="p-3 py-4 text-xs space-y-1.5 border border-gray-200 rounded text-gray-700"
              key={index}
            >
              <p className="font-semibold text-sm text-gray-700">
                {item.title}
              </p>
              <div className="flex items-center gap-2.5 border-b-[1px] border-gray-200 py-1 pb-2.5">
                <div className="rounded-full">
                  <img
                    src="/media/image/cmLogo1.png"
                    className="h-[40px] w-[40px] "
                  />
                </div>
                <div className="space-y-1">
                  <p className="font-semibold">johndoe@gmail.com</p>
                  <p>Cusecho by Cloudpact AI</p>
                </div>
                <button className="ml-auto">
                  <HiOutlineTrash size={20} className="text-gray-800" />
                </button>
              </div>
              <div className="flex items-center gap-2.5 border-b-[1px] border-gray-200 py-1 pb-2.5">
                <div className="rounded-full">
                  <img
                    src="/media/image/cmLogo2.png"
                    className="h-[40px] w-[40px] "
                  />
                </div>
                <div className="space-y-1">
                  <p className="font-semibold">johndoe@gmail.com</p>
                  <p>Cloudpact AI</p>
                </div>
                <button className="ml-auto">
                  <HiOutlineTrash size={20} className="text-gray-700" />
                </button>
              </div>
              <div className="pt-3">
                <button
                  className="text-gray-800 font-medium"
                  onClick={() => {
                    setShowAddAccountModal(true);
                  }}
                >
                  + Add account
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CampaignManagerAccountSettings;
