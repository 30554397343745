import React from "react";

const topArr = [
  {
    title: "Followers",
    value: 43,
    desc: "+12% increase from last week",
  },
  {
    title: "Views",
    value: "1.15M",
    desc: "+2% increase from last week",
  },
  {
    title: "Posts",
    value: "12",
    desc: "+2% increase from last week",
  },
  {
    title: "Comments",
    value: "18",
    desc: "+2% increase from last week",
  },
  {
    title: "Likes",
    value: "18,169",
    desc: "+2% increase from last week",
  },
];

const CampaignManagerInstagramAnalytics = () => {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-5 gap-3">
        {topArr.map((item: any, index: number) => (
          <div
            className="text-sm p-2.5 border border-gray-200 rounded space-y-3"
            key={index}
          >
            <div className="space-y-2">
              <p className="text-sm text-gray-500 font-medium">{item.title}</p>
              <p className="text-4xl font-semibold">{item.value}</p>
            </div>
            <p className="text-green-600 text-xs">{item.desc}</p>
          </div>
        ))}
      </div>
      <div className="space-y-2">
        <p className="text-[#171717] font-semibold text-sm">Recent posts</p>
        <div className="grid grid-cols-3 gap-4">
          {[1, 2].map((index) => (
            <div
              className="border border-gray-100 shadow-sm p-2.5 rounded-md flex flex-col gap-2"
              key={index}
            >
              <div className="font-semibold text-gray-700">
                <p className="text-[13px]">@Cusecho_</p>
              </div>
              <div>
                <p className="text-xs text-gray-700 font-medium leading-[18px]">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Perferendis similique eius delectus non laudantium. Iure,
                  debitis nulla! Architecto, dolores quis. Dolore consectetur
                  nam, officia doloribus reiciendis alias. Corrupti voluptates
                  magni...
                </p>
              </div>
              <div className="flex gap-2">
                <img
                  src="/media/image/defaultStoreHeroImage.jpg"
                  alt="image"
                  className="object-cover rounded h-[40px] w-[40px]"
                />
                <img
                  src="/media/image/defaultStoreHeroImage.jpg"
                  alt="image"
                  className="object-cover rounded h-[40px] w-[40px]"
                />
              </div>
              <p className="text-xs text-gray-500 mt-6">
                Likes • 86, Comments • 12
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-2">
        <p className="text-[#171717] font-semibold text-sm">Smart analysis</p>
        <div className="grid grid-cols-3 gap-4">
          {[1, 2].map((index) => (
            <div className="border border-blue-100 shadow-sm p-2.5 rounded-md bg-blue-50/20" key={index} >
              <p className="text-[13px] text-[#1D3674]">
                <span className="text-base">✨ </span>Posts that were made on
                Friday between 1pm to 2pm saw 7% more engagements.
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignManagerInstagramAnalytics;
