// @ts-nocheck
import { ImSpinner3, ImSpinner8 } from "react-icons/im";
import Layout from "../../../components/WebStore/Layout";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CartItem, CartProps, SET_CART } from "../../../redux/Utils";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  confirmPayment,
  initiatePayment,
  placeProductOrder,
} from "../../../api/Utils";
import { getDeliveryDetail, getSiteTitleAndTagLine } from "../../../api/Menu";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import { classNames, formatStoreName } from "../../../utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { RiFileDownloadLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { TbLoader2 } from "react-icons/tb";
import { MdKeyboardBackspace } from "react-icons/md";
import MyPrintComponent from "../print";

const initialValues = {
  fullName: "",
  email: "",
  phone: "",
};

const initialValues2 = {
  fullName: "",
  address: "",
  email: "",
  phone: "",
  city: "",
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  fullName: Yup.string().max(40).required("Full name is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
});

const validationSchema2 = Yup.object().shape({
  phone: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  fullName: Yup.string().max(40).required("Full name is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  address: Yup.string().max(100).required("Address is required"),
  city: Yup.string().max(40).required("Address is required"),
});

const WebStoreCheckoutPage = () => {
  const { salt, slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const queryKey = `/${salt}/${slug}`;
  const storeName = formatStoreName(queryKey);
  const urlParams = new URLSearchParams(location.search);
  const referenceValue = urlParams.get("reference");
  const successParam = urlParams.get("success");
  const payOrderId = urlParams.get("orderId");
  const [heroDetails, setHeroDetails] = useState({
    siteTitle: storeName,
    tagLine: "[SITE_TAGLINE_HERE]",
  });
  const [loading, setLoading] = useState(false);
  const cart: CartProps = useAppSelector((state) => state.utils.cart);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState(0);
  const [orderID, setOrderID] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [paymentOption, setPaymentOption] = useState(0);
  let allDeliveryDetails: any[] = [];

  let storeAddress = "";

  const [paymentDetails, setPaymentDetails] = useState([]);

  const getCart = () => Object.values(cart || {});

  const storeCart = getCart().filter((x) => pathname.includes(x.slugUrl));
  // console.log(storeCart);

  const a = getCart().concat(storeCart);
  const b = a.filter((c) => !pathname.includes(c.slugUrl));

  const totalPrice = storeCart.reduce(
    (accumulator, product) =>
      accumulator + product.selectedPriceForItem * product.qty,
    0
  );

  const cartHasOnlyFreeProduct =
    storeCart.filter(
      (item: CartItem, index: number) => item.freeDelivery === "0"
    ).length === 0;

  const freeDeliveryStatus: string = useAppSelector(
    (state) => state.user.store.freeDelivery
  );

  const [finalTotalPrice, setFinalTotalPrice] = useState(totalPrice);

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
    onSuccess: (data: any) => {
      if (data.Site_Title !== "" || data.Tagline !== "") {
        setHeroDetails({
          siteTitle: data.Site_Title,
          tagLine: data.Tagline,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: deliveryOption === 0 ? initialValues : initialValues2,
    validationSchema:
      deliveryOption === 0 ? validationSchema : validationSchema2,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      let payload: any = {
        queueName: "",
        orderId: "",
        apiKey: "",
        paymentTransactionReferenceNumber: "",
        creationTime: "",
        description:
          paymentOption === 0
            ? "Payment via Bank Transfer."
            : "Payment via Paystack.",
        creationTimestamp: "-1",
        platformRouter: "",
        slugUrl: queryKey,
        orderPayload: [
          ...storeCart.map((item: CartItem, index: number) => {
            // const netIndex = item?.imageId?.indexOf(".net");
            return {
              description: item.description,
              itemName: item.itemName,
              itemPrice: item.itemPrice,
              quantityOrder: item.qty,
              itemId: "itemId" in item ? item.itemId : item.menuItemId,
              itemColor: item.itemColor,
              itemSize: item.itemSize === 0 ? "" : item.itemSize,
              orderStatus: "Pending",
              imageId: item.s3ImageUrl.replace(
                "https://d1ntmcvl0pjnmq.cloudfront.net",
                "/cusecho"
              ),
              variantArray: item.itemVariantArray
                ? item.itemVariantArray.map((i) => {
                    return {
                      variantId: i.variantId,
                      quantitySold: item.qty,
                    };
                  })
                : [],
            };
          }),
        ],
        paymentMap: { paymentType: "transfer", acknowledgement: "pending" },
        transactionPayload: {
          totalAmount: `${totalPrice + Number(deliveryFee)}`,
          discount: {
            discounted: "NO",
            "%Discounted": "1.5",
            amountDiscounted: "0.00",
          },
        },
        metadata: {
          tableName: "",
          loyaltyCardId: "XXXXXXPPP",
        },
        orderStatus: "Pending",
        orderNumber: "",
        totalPrice: `${totalPrice + Number(deliveryFee)}`,
        otherVendorsPaymentTotal: "0.00",
      };

      if (deliveryOption === 1) {
        payload["billingDetail"] = {
          fullName: values.fullName,
          // address: values.address,
          email: values.email,
          phone: values.phone,
          deliveryDetail: {
            address: values.address,
            city: values.city,
            location: deliveryLocation,
            fee: deliveryFee,
          },
        };
      } else {
        payload["billingDetail"] = {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          deliveryDetail: {},
        };
      }
      console.log(payload);

      const response = await placeProductOrder(payload);
      sessionStorage.setItem("orderPayload", JSON.stringify(payload));

      // console.log({response});
      if (response.data.status_code === 200) {
        // console.log(response.data);
        setOrderID(response.data.orderNumber);
        setFinalTotalPrice(totalPrice + Number(deliveryFee));

        if (response.data.paymentDetail.status_code === 200) {
          if (response.data.paymentDetail.Payment_Details.length > 0) {
            setPaymentDetails(response.data.paymentDetail.Payment_Details);
            // console.log(response.data.paymentDetail)
          }
        }
        setSubmitting(false);
        setShowCheckoutModal(false);
        setShowSuccessModal(1);
        // dispatch(SET_CART(b));
        dispatch(SET_CART({}));
        // setDeliveryOption(0);
        // setDeliveryFee(0);
        // setDeliveryLocation("");
        // queryClient.refetchQueries(["getOrders"]);
        // resetForm({});
        // dispatch(SET_CART({}));
        // setPaymentDetails([]);
        // setDeliveryOption(0);
        // setDeliveryFee(0);
        // setDeliveryLocation("");

        return;
      }
      setSubmitting(false);
    },
  });

  let payload1: any = {
    queueName: "",
    orderId: "",
    apiKey: "",
    creationTime: "",
    description:
      paymentOption === 0
        ? "Payment via Bank Transfer."
        : "Payment via Paystack.",
    // creationTimestamp: Number(new Date(Date.now()).getTime() / 1000),
    creationTimestamp: "-1",
    platformRouter: "",
    slugUrl: queryKey,
    paymentMap: {},
    orderPayload: [
      ...storeCart.map((item: CartItem, index: number) => {
        return {
          description: item.description,
          itemName: item.itemName,
          itemPrice: item.itemPrice,
          quantityOrder: item.qty,
          itemId: "itemId" in item ? item.itemId : item.menuItemId,
          itemColor: item.itemColor,
          itemSize: item.itemSize === 0 ? "" : item.itemSize,
          orderStatus: "Pending",
          imageId: item.s3ImageUrl.replace(
            "https://d1ntmcvl0pjnmq.cloudfront.net",
            "/cusecho"
          ),
          variantArray: item.itemVariantArray
            ? item.itemVariantArray.map((i) => {
                return {
                  variantId: i.variantId,
                  quantitySold: item.qty,
                };
              })
            : [],
        };
      }),
    ],
    transactionPayload: {
      totalAmount: `${totalPrice + Number(deliveryFee)}`,
      discount: {
        discounted: "NO",
        "%Discounted": "1.5",
        amountDiscounted: "0.00",
      },
    },
    metadata: {
      tableName: "",
      loyaltyCardId: "XXXXXXPPP",
    },
    orderStatus: "Pending",
    orderNumber: "",
    totalPrice: `${totalPrice + Number(deliveryFee)}`,
    otherVendorsPaymentTotal: "0.00",
  };

  if (deliveryOption === 1) {
    payload1["billingDetail"] = {
      fullName: formik.values.fullName,
      address: formik.values.address,
      email: formik.values.email,
      phone: formik.values.phone,
      deliveryDetail: {
        address: formik.values.address,
        city: formik.values.city,
        location: deliveryLocation,
        fee: Number(deliveryFee),
      },
    };
  } else {
    payload1["billingDetail"] = {
      fullName: formik.values.fullName,
      email: formik.values.email,
      phone: formik.values.phone,
      deliveryDetail: {},
    };
  }

  const proceedToPayment = async () => {
    sessionStorage.setItem("orderPayload", JSON.stringify(payload1));
    setLoading(true);
    const initiatePaymentPayload = {
      fullName: formik.values.fullName,
      amount: totalPrice + Number(deliveryFee),
      currency: "NGN",
      email: formik.values.email,
      callback_url: window.location.href,
      // callback_url: window.location.href + "/callback/payment",
    };

    try {
      const paystackResponse = await initiatePayment(initiatePaymentPayload);
      const redirectUrl = paystackResponse.data.data.data.authorization_url;
      setLoading(false);
      // window?.open(redirectUrl, "_blank");
      window.location.assign(redirectUrl);
    } catch (error) {
      console.log(error);
      toast.error("Error occured while processing your payment");
      setLoading(false);
    }
  };

  const deliveryDetails = useQuery<any, Error>({
    queryKey: ["getDeliveryDetail"],
    queryFn: async () => {
      const response = await getDeliveryDetail(queryKey);
      return response.data;
    },
  });

  if (deliveryDetails.data) {
    storeAddress = deliveryDetails.data.Pickup_Location;
    if ("Location_Tag" in deliveryDetails.data) {
      const newData = Array.from(
        Object.entries(deliveryDetails.data.Location_Tag)
      );
      const realLocations = newData.filter(
        (x) => !x.at(0).includes("dummylocation")
      );

      allDeliveryDetails = realLocations;
    }
  }

  useEffect(() => {
    if (referenceValue) {
      setLoading(true);
      setShowSuccessModal(2);
      (async () => {
        const paystackConfirmationResponse = await confirmPayment(
          referenceValue,
          referenceValue
        );
        if (paystackConfirmationResponse.status === 200) {
          if (storeCart.length > 0 && !successParam === true) {
            toast.success("Payment has been confirmed");
            const storedPayload = JSON.parse(
              sessionStorage.getItem("orderPayload") || "{}"
            );
            const response = await placeProductOrder({
              ...storedPayload,
              paymentTransactionReferenceNumber: referenceValue,
            });
            if (response.data.status_code === 200) {
              setLoading(false);
              // console.log(response.data);
              setOrderID(response.data.orderNumber);

              toast.success("Your order has been placed");
              // sessionStorage.removeItem("orderPayload");
              setFinalTotalPrice(totalPrice);
              window?.location.replace(
                window?.location.href +
                  "&orderId=" +
                  response.data.orderNumber +
                  "&success=true"
              );
              dispatch(SET_CART({}));
              setOrderID(response.data.orderNumber);
            } else {
              setLoading(false);
              console.log("An error occured");
            }
          }
        }
      })();
    }
  }, []);

  const type = JSON.parse(sessionStorage.getItem("orderPayload") || "{}");
  // console.log(type);

  return (
    <Layout>
      <section className="font-dm-sans">
        {showSuccessModal === 0 ? (
          <div className="container px-4 pt-16 pb-20 md:px-14 grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className="lg:col-span-2 lg:border-r-[1px] border-gray-200">
              <div className="w-full lg:w-[90%] mx-auto space-y-4">
                <div className="text-left">
                  <h4 className="text-lg font-semibold text-gray-900">
                    Shipping information
                  </h4>
                </div>
                <div className="mb-3">
                  <div className="flex items-center gap-6">
                    <div className="">
                      <label className="flex items-center space-x-1.5 text-sm font-medium cursor-pointer">
                        <input
                          type="radio"
                          value={0}
                          className="w-3 h-3 md:w-4 md:h-4 accent-blue-500"
                          checked={deliveryOption === 0}
                          onChange={(e) => {
                            setDeliveryOption(0);
                            setDeliveryFee(0);
                            setDeliveryLocation("");
                          }}
                        />
                        <span>Pickup</span>
                      </label>
                    </div>
                    <div className="">
                      <label className="flex items-center space-x-1.5 text-sm font-medium cursor-pointer">
                        <input
                          type="radio"
                          value={1}
                          className="w-3 h-3 md:w-4 md:h-4 accent-blue-500"
                          checked={deliveryOption === 1}
                          onChange={(e) => {
                            if (allDeliveryDetails.length < 1) {
                              toast.error(
                                "No delivery location available for this vendor"
                              );
                              return;
                            }
                            setDeliveryOption(1);
                            // if (
                            //   freeDeliveryStatus === "1" &&
                            //   cartHasOnlyFreeProduct
                            // ) {
                            //   setDeliveryFee(0);
                            // } else {
                            //   setDeliveryFee(allDeliveryDetails[0][1]);
                            // }
                            setDeliveryFee(allDeliveryDetails[0][1]);
                            setDeliveryLocation(allDeliveryDetails[0][0]);
                          }}
                        />
                        <span>Delivery</span>
                      </label>
                    </div>
                  </div>
                </div>
                {deliveryDetails.status === "loading" ? (
                  <div className="flex items-center justify-start py-5">
                    <ImSpinner3
                      size={18}
                      className="mx-auto animate-spin fill-primary"
                    />
                  </div>
                ) : deliveryDetails.status === "error" ? (
                  <div className="flex items-center justify-start py-5">
                    Error: {deliveryDetails.error.message}
                  </div>
                ) : (
                  deliveryOption === 1 && (
                    <div className="mb-4">
                      <div className="hidden">
                        <div className="grid grid-cols-1 gap-2.5 md:grid-cols-3 lg:grid-cols-3">
                          {allDeliveryDetails.length > 0 &&
                            allDeliveryDetails.map(
                              (item: any, index: number) => {
                                return (
                                  <div key={index} className="">
                                    <label className="flex items-center space-x-1.5 text-base md:text-sm lg:text-base font-medium text-gray-800 cursor-pointer">
                                      <input
                                        type="radio"
                                        value={0}
                                        className="w-4 h-4 accent-blue-500"
                                        checked={
                                          deliveryFee === Number(item[1]) &&
                                          deliveryLocation === item[0]
                                        }
                                        onChange={(e) => {
                                          setDeliveryLocation(item[0]);
                                          if (
                                            freeDeliveryStatus === "1" &&
                                            cartHasOnlyFreeProduct
                                          ) {
                                            setDeliveryFee(0);
                                          } else {
                                            setDeliveryFee(Number(item[1]));
                                          }
                                        }}
                                      />
                                      <span>
                                        {item[0]} - &#8358; {item[1]}
                                        {/* {freeDeliveryStatus === "1" &&
                                        cartHasOnlyFreeProduct
                                          ? 0.0
                                          : item[1]} */}
                                      </span>
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          {allDeliveryDetails.length === 0 && (
                            <div>
                              <p className="hidden text-sm text-gray-600 capitalize">
                                No delivery location available
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="text-left text-gray-800">
                          <label className="block mb-0.5 text-xs font-medium">
                            Address
                          </label>
                          <input
                            name="address"
                            type="text"
                            {...formik.getFieldProps("address")}
                            className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-300"
                          />
                          {formik.errors.address ? (
                            <div className="text-xs">
                              <InputMessage message={formik.errors.address} />
                            </div>
                          ) : null}
                        </div>
                        <div className="flex flex-col items-start w-full mt-2 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                          <div className="w-full text-left text-gray-800">
                            <label className="block mb-0.5 text-xs font-medium">
                              City
                            </label>
                            <input
                              name="city"
                              type="text"
                              {...formik.getFieldProps("city")}
                              className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-400"
                            />
                            {formik.errors.phone ? (
                              <div className="text-xs">
                                <InputMessage message={formik.errors.city} />
                              </div>
                            ) : null}
                          </div>
                          <div className="w-full text-left text-gray-800">
                            <label className="block mb-0.5 text-xs font-medium">
                              Delivery locations
                            </label>
                            <select
                              name="delivery_locations"
                              id="delivery_locations"
                              onChange={(e) => {
                                const item = e.target.value.split(",");
                                setDeliveryLocation(item[0]);
                                setDeliveryFee(Number(item[1]));
                              }}
                              className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-300"
                            >
                              {allDeliveryDetails.length > 0 &&
                                allDeliveryDetails.map(
                                  (item: any, index: number) => {
                                    // console.log(item);
                                    return (
                                      <option key={index} value={item}>
                                        {item[0]} - &#8358; {item[1]}
                                        {/* {freeDeliveryStatus === "1" &&
                                        cartHasOnlyFreeProduct
                                          ? 0.0
                                          : item[1]} */}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        </div>
                        {allDeliveryDetails.length === 0 && (
                          <div>
                            <p className="hidden text-sm text-gray-600 capitalize">
                              No delivery location available
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
                <form onSubmit={formik.handleSubmit} className="space-y-4">
                  {deliveryDetails.status === "loading" ? (
                    <div className="flex items-center justify-start py-5">
                      <ImSpinner3
                        size={18}
                        className="mx-auto animate-spin fill-primary"
                      />
                    </div>
                  ) : deliveryDetails.status === "error" ? (
                    <div className="flex items-center justify-start py-5">
                      Error: {deliveryDetails.error.message}
                    </div>
                  ) : (
                    deliveryOption === 0 && (
                      <div className="px-2.5 py-6 space-y-2 font-normal text-[#263C6B] bg-[#EFF4FF] border-[1px] border-[#263C6B] rounded">
                        <h6 className="text-sm font-semibold">
                          Pickup address
                        </h6>
                        <p className="text-[13px]">{`${
                          storeAddress === "" ? "not available" : storeAddress
                        }`}</p>
                      </div>
                    )
                  )}
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900 mt-[30px]">
                      Contact information
                    </h4>
                    <div className="flex flex-col w-full mt-2 space-y-2.5">
                      <div className="text-left text-gray-800">
                        <label className="block mb-0.5 text-xs font-medium">
                          Full name
                        </label>
                        <input
                          name="fullName"
                          type="text"
                          {...formik.getFieldProps("fullName")}
                          className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-300"
                        />
                        {formik.errors.fullName ? (
                          <div className="text-xs">
                            <InputMessage message={formik.errors.fullName} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col items-start w-full mt-2 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                      <div className="w-full text-left text-gray-800">
                        <label className="block mb-0.5 text-xs font-medium">
                          Phone
                        </label>
                        <input
                          name="phone"
                          type="text"
                          placeholder="+XXXXXXXXXXX"
                          {...formik.getFieldProps("phone")}
                          className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-400"
                        />
                        {formik.errors.phone ? (
                          <div className="text-xs">
                            <InputMessage message={formik.errors.phone} />
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full text-left text-gray-800">
                        <label className="block mb-0.5 text-xs font-medium">
                          Email address
                        </label>
                        <input
                          name="email"
                          type="text"
                          {...formik.getFieldProps("email")}
                          className="block w-full p-2 py-2.5 text-gray-900 transition duration-300 ease-linear border border-gray-400 rounded outline-none focus:border-primary placeholder:text-gray-300"
                        />
                        {formik.errors.email ? (
                          <div className="text-xs">
                            <InputMessage message={formik.errors.email} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 space-y-1.5">
                    <h5 className="text-base font-semibold text-gray-800 md:text-lg mt-[30px]">
                      Payment method
                    </h5>
                    <div className="border border-gray-300 rounded-md">
                      <label
                        className={classNames(
                          "flex items-start space-x-2 p-3 border-b-[1px] border-gray-300"
                        )}
                      >
                        <input
                          type="radio"
                          checked={paymentOption === 0}
                          // disabled={index === 1}
                          onChange={() => setPaymentOption(0)}
                          className="mt-1 lg:mt-1.5 accent-blue-500"
                        />
                        <div className="space-y-1 text-left">
                          <div
                            className={classNames(
                              "flex items-center space-x-1.5 text-sm"
                            )}
                          >
                            <span className="font-medium">
                              Bank transfer via invoice
                            </span>
                          </div>
                          <p className="text-[13px] italic text-gray-800">
                            You will be presented with an invoice with the
                            vendors account details.
                          </p>
                        </div>
                      </label>

                      <div>
                        <label
                          className={classNames(
                            "flex items-start space-x-2 p-3"
                          )}
                        >
                          <input
                            type="radio"
                            checked={paymentOption === 1}
                            // disabled={index === 1}
                            onChange={() => setPaymentOption(1)}
                            className="mt-1 lg:mt-1.5 accent-blue-500"
                          />
                          <div className="space-y-1 text-left">
                            <div
                              className={classNames(
                                "flex items-center space-x-1.5 text-sm"
                              )}
                            >
                              <span className="font-medium">
                                Pay with cards, bank transfer and USSD
                              </span>
                            </div>
                            <p className="text-[13px] italic text-gray-800">
                              You will be redirected to a secure checkout page.
                            </p>
                          </div>
                        </label>

                        <div className="flex items-center justify-between px-4 py-3 gap-1.5 flex-wrap">
                          <div className="flex items-center gap-2">
                            <p className="text-[12.5px] font-semibold">
                              Payment partners:
                            </p>
                            <div className="flex items-center gap-1.5">
                              <img
                                src="/media/svg/paystack-logo.svg"
                                alt="paystack"
                              />
                            </div>
                          </div>

                          <div className="flex items-center gap-2">
                            <p className="text-[12.5px] font-semibold">
                              We accept:
                            </p>
                            <div className="flex items-center gap-1.5">
                              <img src="/media/svg/visa-logo.svg" alt="visa" />
                              <img
                                src="/media/svg/mastercard-logo.svg"
                                alt="mastercard"
                              />
                              <img src="/media/svg/bank-icon.svg" alt="bank" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 hidden">
                      {[0, 1].map((item: number, index: number) => {
                        return (
                          <label
                            key={index}
                            className={classNames(
                              "flex items-start space-x-2",
                              index === 0 ? "cursor-pointer" : "cursor-default"
                            )}
                          >
                            <input
                              type="radio"
                              checked={paymentOption === index}
                              // disabled={index === 1}
                              onChange={() => setPaymentOption(index)}
                              className="mt-1 lg:mt-1.5 accent-blue-500"
                            />
                            <div className="space-y-1 text-left">
                              <div
                                className={classNames(
                                  "flex items-center space-x-1.5 text-sm"
                                )}
                              >
                                <span className="font-medium">
                                  {index === 0
                                    ? "Direct bank transfer"
                                    : "Paystack"}
                                </span>
                              </div>
                              <p className="text-sm italic text-gray-800 hidden">
                                {index === 0
                                  ? "Make your payment directly into our bank account. please use your order ID as the payment reference. Your order will not be shipped until funds have cleared in our account."
                                  : "Make payment via paystack link."}
                              </p>
                            </div>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="lg:w-[98%]">
              <div className="w-full space-y-4">
                <h4 className="text-base font-semibold text-gray-800 md:text-lg">
                  Order summary
                </h4>
                <div className="flex items-center justify-between">
                  <h6 className="text-sm font-medium">Sub-total</h6>
                  <h6 className="text-base font-medium">
                    &#8358; {totalPrice}
                  </h6>
                </div>
                <div className="flex items-center justify-between">
                  <h6 className="text-sm">{`Delivery - (${
                    deliveryFee > 0 ? deliveryLocation : "Pickup"
                  })`}</h6>
                  <h6 className="text-base">&#8358; {deliveryFee}</h6>
                </div>
                <div className="mx-auto border-t-[1px] md:border-y my-2 border-gray-200"></div>
                <div className="flex items-center justify-between">
                  <h6 className="text-base font-medium">Total</h6>
                  <h6 className="text-base font-semibold">
                    &#8358; {totalPrice + Number(deliveryFee)}
                  </h6>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    onClick={
                      paymentOption === 0
                        ? formik.handleSubmit
                        : proceedToPayment
                    }
                    className="py-3 text-center text-white rounded w-full text-sm bg-blue-600 disabled:opacity-60 mt-8 lg:mt-0"
                    disabled={formik.isSubmitting || storeCart.length < 1}
                  >
                    {formik.isSubmitting || loading ? (
                      <ImSpinner8
                        size={24}
                        className="mx-auto animate-spin fill-white"
                      />
                    ) : (
                      "Place Order"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : showSuccessModal === 1 ? (
          <div className="font-dm-sans px-4">
            <div className="pt-12 pb-6">
              <div className="flex flex-col gap-1 items-center justify-center mb-6">
                <img
                  src="/media/svg/check.svg"
                  alt="success-filled"
                  width={90}
                  height={90}
                  className="w-[90px] h-[90px]"
                />
                <p className="font-medium text-gray-800">
                  Thank you your order has been placed.
                </p>
              </div>
              <div className="border border-gray-300 rounded max-w-[600px] mx-auto text-gray-800">
                <div className="py-3 px-4 bg-slate-100 flex items-center justify-between gap-4 font-medium flex-wrap">
                  <p>Order I.D: {orderID}</p>
                  <p>Order date: {new Date().toDateString()}</p>
                </div>
                <div className="p-4">
                  <p className="font-semibold text-xl text-gray-900">
                    Payment information
                  </p>
                  <div className="border mt-3 border-gray-300 rounded p-3">
                    <p className="font-medium mb-2">
                      <span className="font-semibold">Payment method:</span>{" "}
                      Bank transfer
                    </p>
                    <div>
                      <p className="font-semibold">Bank details:</p>
                      <div className="grid gap-4 mt-2.5">
                        {paymentDetails
                          .slice(0, 1)
                          .map((detail: any, index: number) => {
                            return (
                              <div key={index} className="w-full space-y-1.5">
                                <div className="flex items-start space-x-2 font-medium text-gray-800">
                                  <h6 className="font-semibold">Name:</h6>
                                  <h6 className="grow">{detail.accountName}</h6>
                                </div>
                                <div className="flex items-start space-x-2 font-medium text-gray-800">
                                  <h6 className="font-semibold">Bank:</h6>
                                  <h6 className="grow">{detail.bankName}</h6>
                                </div>
                                <div className="flex items-start space-x-2 font-medium text-gray-800">
                                  <h6 className="whitespace-nowrap font-semibold">
                                    Account Number:
                                  </h6>
                                  <h6 className="">{detail.accountNumber}</h6>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <p className="text-sm font-medium text-gray-800 mt-2">
                        Use the order ID as the payment reference. Your order
                        will not be shipped until funds have cleared in our
                        account
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <p className="font-semibold text-xl text-gray-900">
                    Order summary
                  </p>
                  <div className="border mt-1.5 border-gray-300 rounded py-3">
                    <div className="">
                      {type?.orderPayload?.map((item: any, index: number) => (
                        <div
                          className="px-3 border-b-[1px] border-gray-400"
                          key={item.menuItemId || item.itemid}
                        >
                          <div className="flex gap-2 py-2 items-center ">
                            <div className="">
                              <img
                                // src={item.s3ImageUrl}
                                src={item.imageId.replace(
                                  "/cusecho",
                                  "https://d1ntmcvl0pjnmq.cloudfront.net"
                                )}
                                alt=""
                                className="w-20 h-20 rounded-md"
                              />
                            </div>
                            <div className="flex flex-col gap-1 text-sm font-medium">
                              <p className="font-semibold">
                                {item.itemName} x{item.quantityOrder}
                              </p>
                              <p className="">{item.description}</p>
                              {/* <p>Size: EU: 44</p>
                              <p>Color: Red</p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="space-y-2 border-b-[1px] pb-3 mb-2 border-gray-400 m-3">
                      <div className="flex items-center justify-between gap-4">
                        <p>Subtotal</p>
                        <p>&#8358; {finalTotalPrice - Number(deliveryFee)}</p>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <p>Shipping</p>
                        <p>&#8358; {deliveryFee || "0.00"}</p>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <div>
                          <p>Coupon</p>
                        </div>
                        <p>&#8358; 0.00</p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 p-3">
                      <p>Total</p>
                      <p>&#8358; {finalTotalPrice}</p>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <p className="font-semibold text-xl text-gray-900">
                    Delivery details
                  </p>
                  <div className="border mt-1.5 border-gray-300 rounded p-3">
                    <div className="flex items-center gap-6">
                      <p className="w-[50px]">Name:</p>
                      <p>{formik.values.fullName}</p>
                    </div>
                    {formik.values.address === "" && (
                      <div className="flex items-center gap-6">
                        <p className="w-[50px]">Address:</p>
                        <p>{formik.values.address}</p>
                      </div>
                    )}

                    <div className="flex items-center gap-6">
                      <p className="w-[50px]">Phone:</p>
                      <p>{formik.values.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
              <MyPrintComponent
                orderID={orderID}
                finalTotalPrice={finalTotalPrice}
                deliveryFee={deliveryFee}
                name={formik.values.fullName}
                address={formik.values.address}
                phone={formik.values.phone}
                email={formik.values.email}
                paymentDetails={paymentDetails}
                orderPayload={type}
                referenceValue={referenceValue}
              />
            </div>
          </div>
        ) : showSuccessModal === 2 ? (
          <div className="font-dm-sans">
            <div className="bg-gray-100 min-h-screen text-gray-800">
              <div>
                {/* loading && !successParam  */}
                {loading && !successParam ? (
                  <div className="h-screen flex items-center justify-center">
                    <div className="flex flex-col gap-2 items-center justify-center mb-4">
                      <TbLoader2 size={40} className="animate-spin" />
                      <p className="font-medium text-gray-800">
                        Processing your payment and order ...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="py-12 pb-6">
                    <div className="m-6 max-w-[800px] mx-auto flex items-center justify-center lg:block">
                      <button
                        className="text-gray-900 text-sm font-medium inline-flex items-center gap-1.5"
                        onClick={() => navigate(`/store/${salt}/${slug}`)}
                      >
                        <MdKeyboardBackspace />
                        Continue shopping
                      </button>
                    </div>
                    <div className="max-w-[600px] mx-auto">
                      <div className="flex flex-col gap-1 items-center justify-center mb-6">
                        <img
                          src="/media/svg/check.svg"
                          alt="success-filled"
                          width={90}
                          height={90}
                          className="w-[90px] h-[90px]"
                        />
                        <p className="font-medium text-gray-800 text-center px-3 ">
                          Thank you your order has been placed.
                        </p>
                      </div>
                      <div className="mx-4">
                        <div className="border-[1px] border-gray-300 rounded  text-gray-800">
                          <div className="py-3 px-4 bg-gray-200 flex items-center justify-between gap-4 font-medium rounded flex-wrap">
                            <p>Order I.D: {orderID || payOrderId}</p>
                            <p>Order date: {new Date().toDateString()}</p>
                          </div>
                          <div className="p-4">
                            <p className="font-semibold text-xl text-gray-900">
                              Payment information
                            </p>
                            <div className="border mt-3 border-gray-300 rounded p-3">
                              <p className="font-medium mb-2">
                                <span className="">Payment method:</span> Paid
                                via Paystack.
                              </p>
                              <p className="font-medium mb-2 hidden">
                                <span className="">Transaction reference:</span>{" "}
                                {referenceValue}
                              </p>
                            </div>
                          </div>
                          <div className="p-3">
                            <p className="font-semibold text-xl text-gray-900">
                              Order summary
                            </p>
                            <div className="border mt-1.5 border-gray-300 rounded pt-0 pb-3">
                              <div className="">
                                {type.orderPayload.map(
                                  (item: any, index: number) => (
                                    <div
                                      className="px-3 border-b-[1px] border-gray-400"
                                      key={item.menuItemId || item.itemid}
                                    >
                                      <div className="flex gap-2 py-2 items-center ">
                                        <div className="">
                                          <img
                                            src={item.imageId.replace(
                                              "/cusecho",
                                              "https://d1ntmcvl0pjnmq.cloudfront.net"
                                            )}
                                            alt=""
                                            className="w-20 h-20 rounded-md object-cover"
                                          />
                                        </div>
                                        <div className="flex flex-col gap-1 text-sm font-medium">
                                          <p className="font-semibold">
                                            {item.itemName} x
                                            {item.quantityOrder}
                                          </p>
                                          <p className="">
                                            &#8358; {item.itemPrice}
                                          </p>
                                          {/* <p>Size: EU: 44</p>
                              <p>Color: Red</p> */}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="space-y-2 border-b-[1px] pb-3 mb-2 border-gray-400 m-3 ">
                                <div className="flex items-center justify-between gap-4">
                                  <p>Subtotal</p>
                                  {type?.billingDetail?.deliveryDetail?.fee ? (
                                    <p>
                                      &#8358;{" "}
                                      {type?.transactionPayload?.totalAmount -
                                        type?.billingDetail?.deliveryDetail
                                          ?.fee}
                                    </p>
                                  ) : (
                                    <p>
                                      &#8358;{" "}
                                      {type?.transactionPayload?.totalAmount}
                                    </p>
                                  )}
                                </div>
                                <div className="flex items-center justify-between gap-4">
                                  <p>Shipping</p>
                                  <p>
                                    &#8358;{" "}
                                    {type?.billingDetail?.deliveryDetail?.fee ||
                                      "0.00"}
                                  </p>
                                </div>
                                <div className="flex items-center justify-between gap-4">
                                  <div>
                                    <p>Coupon</p>
                                  </div>
                                  <p>&#8358; 0.00</p>
                                </div>
                              </div>
                              <div className="flex items-center justify-between gap-4 p-3 py-0">
                                <p>Total</p>
                                <p>
                                  &#8358; {type.transactionPayload.totalAmount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="p-3">
                            <p className="font-semibold text-xl text-gray-900">
                              Delivery details
                            </p>
                            <div className="border mt-1.5 border-gray-300 rounded p-3 space-y-1">
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Name:</p>
                                <p className="truncate">
                                  {type.billingDetail.fullName}
                                </p>
                              </div>
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Address:</p>
                                <p className="truncate">
                                  {type?.billingDetail?.deliveryDetail
                                    ?.address || type?.billingDetail?.email}
                                </p>
                              </div>
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Phone:</p>
                                <p className="truncate">
                                  {type.billingDetail.phone}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <MyPrintComponent
                          orderID={payOrderId}
                          finalTotalPrice={finalTotalPrice}
                          deliveryFee={type?.billingDetail?.deliveryDetail?.fee}
                          name={type.billingDetail.fullName}
                          address={type?.billingDetail?.deliveryDetail?.address}
                          phone={type.billingDetail.phone}
                          email={type?.billingDetail?.email}
                          paymentDetails={paymentDetails}
                          orderPayload={type}
                          referenceValue={referenceValue}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {showSuccessModal === 1 && (
          <div className="font-dm-sans px-4 hidden">
            <div className="py-12">
              <div className="flex flex-col gap-1 items-center justify-center mb-6">
                <img
                  src="/media/svg/check.svg"
                  alt="success-filled"
                  width={90}
                  height={90}
                  className="w-[90px] h-[90px]"
                />
                <p className="font-medium text-gray-800">
                  Thank you your order has been placed.
                </p>
              </div>
              <div className="border border-gray-300 rounded max-w-[600px] mx-auto text-gray-800">
                <div className="py-3 px-4 bg-slate-100 flex items-center justify-between gap-4 font-medium flex-wrap">
                  <p>Order I.D: {orderID}</p>
                  <p>Order date: {new Date().toDateString()}</p>
                </div>
                <div className="p-4">
                  <p className="font-semibold text-xl text-gray-900">
                    Payment information
                  </p>
                  <div className="border mt-3 border-gray-300 rounded p-3">
                    <p className="font-medium mb-2">
                      <span className="font-semibold">Payment method:</span>{" "}
                      Bank transfer
                    </p>
                    <div>
                      <p className="font-semibold">Bank details:</p>
                      <div className="grid gap-4 mt-2.5">
                        {paymentDetails.map((detail: any, index: number) => {
                          return (
                            <div key={index} className="w-full space-y-1.5">
                              <div className="flex items-start space-x-2 font-medium text-gray-800">
                                <h6 className="font-semibold">Name:</h6>
                                <h6 className="grow">{detail.accountName}</h6>
                              </div>
                              <div className="flex items-start space-x-2 font-medium text-gray-800">
                                <h6 className="font-semibold">Bank:</h6>
                                <h6 className="grow">{detail.bankName}</h6>
                              </div>
                              <div className="flex items-start space-x-2 font-medium text-gray-800">
                                <h6 className="whitespace-nowrap font-semibold">
                                  Account Number:
                                </h6>
                                <h6 className="">{detail.accountNumber}</h6>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <p className="text-sm font-medium text-gray-800 mt-2">
                        Use the order ID as the payment reference. Your order
                        will not be shipped until funds have cleared in our
                        account
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <p className="font-semibold text-xl text-gray-900">
                    Order summary
                  </p>
                  <div className="border mt-1.5 border-gray-300 rounded py-3">
                    <div className="">
                      {type?.orderPayload?.map((item: any, index: number) => (
                        <div
                          className="px-3 border-b-[1px] border-gray-400"
                          key={item.menuItemId || item.itemid}
                        >
                          <div className="flex gap-2 py-2 items-center ">
                            <div className="">
                              <img
                                // src={item.s3ImageUrl}
                                src={item.imageId.replace(
                                  "/cusecho",
                                  "https://d1ntmcvl0pjnmq.cloudfront.net"
                                )}
                                alt=""
                                className="w-20 h-20 rounded-md"
                              />
                            </div>
                            <div className="flex flex-col gap-1 text-sm font-medium">
                              <p className="font-semibold">
                                {item.itemName} x{item.quantityOrder}
                              </p>
                              <p className="">{item.description}</p>
                              {/* <p>Size: EU: 44</p>
                              <p>Color: Red</p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="space-y-2 border-b-[1px] pb-3 mb-2 border-gray-400 m-3">
                      <div className="flex items-center justify-between gap-4">
                        <p>Subtotal</p>
                        <p>&#8358; {finalTotalPrice - Number(deliveryFee)}</p>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <p>Shipping</p>
                        <p>&#8358; {deliveryFee || "0.00"}</p>
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <div>
                          <p>Coupon</p>
                        </div>
                        <p>&#8358; 0.00</p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 p-3">
                      <p>Total</p>
                      <p>&#8358; {finalTotalPrice}</p>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <p className="font-semibold text-xl text-gray-900">
                    Delivery details
                  </p>
                  <div className="border mt-1.5 border-gray-300 rounded p-3">
                    <div className="flex items-center gap-6">
                      <p className="w-[50px]">Name:</p>
                      <p>{formik.values.fullName}</p>
                    </div>
                    <div className="flex items-center gap-6">
                      <p className="w-[50px]">Address:</p>
                      <p>{formik.values.address}</p>
                    </div>
                    <div className="flex items-center gap-6">
                      <p className="w-[50px]">Phone:</p>
                      <p>{formik.values.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
              <MyPrintComponent
                orderID={orderID}
                finalTotalPrice={finalTotalPrice}
                deliveryFee={deliveryFee}
                name={formik.values.fullName}
                address={formik.values.address}
                phone={formik.values.phone}
                email={formik.values.email}
                paymentDetails={paymentDetails}
                orderPayload={type}
                referenceValue={referenceValue}
              />
            </div>
          </div>
        )}

        {showSuccessModal === 2 && (
          <div className="font-dm-sans hidden">
            <div className="bg-gray-100 min-h-screen text-gray-800">
              <div>
                {/* loading && !successParam  */}
                {loading && !successParam ? (
                  <div className="h-screen flex items-center justify-center">
                    <div className="flex flex-col gap-2 items-center justify-center mb-4">
                      <TbLoader2 size={40} className="animate-spin" />
                      <p className="font-medium text-gray-800">
                        Processing your payment and order ...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="py-12">
                    <div className="m-6 max-w-[800px] mx-auto">
                      <button
                        className="text-gray-900 text-sm font-medium inline-flex items-center gap-1.5"
                        onClick={() => navigate(`/store/${salt}/${slug}`)}
                      >
                        <MdKeyboardBackspace />
                        Continue shopping
                      </button>
                    </div>
                    <div className="max-w-[600px] mx-auto">
                      <div className="flex flex-col gap-1 items-center justify-center mb-6">
                        <img
                          src="/media/svg/check.svg"
                          alt="success-filled"
                          width={90}
                          height={90}
                          className="w-[90px] h-[90px]"
                        />
                        <p className="font-medium text-gray-800">
                          Thank you your order has been placed.
                        </p>
                      </div>
                      <div className="mx-4">
                        <div className="border-[1px] border-gray-300 rounded  text-gray-800">
                          <div className="py-3 px-4 bg-gray-200 flex items-center justify-between gap-4 font-medium rounded flex-wrap">
                            <p>Order I.D: {orderID || payOrderId}</p>
                            <p>Order date: {new Date().toDateString()}</p>
                          </div>
                          <div className="p-4">
                            <p className="font-semibold text-xl text-gray-900">
                              Payment information
                            </p>
                            <div className="border mt-3 border-gray-300 rounded p-3">
                              <p className="font-medium mb-2">
                                <span className="">Payment method:</span> Paid
                                via Paystack.
                              </p>
                              <p className="font-medium mb-2 hidden">
                                <span className="">Transaction reference:</span>{" "}
                                {referenceValue}
                              </p>
                            </div>
                          </div>
                          <div className="p-3">
                            <p className="font-semibold text-xl text-gray-900">
                              Order summary
                            </p>
                            <div className="border mt-1.5 border-gray-300 rounded pt-0 pb-3">
                              <div className="">
                                {type.orderPayload.map(
                                  (item: any, index: number) => (
                                    <div
                                      className="px-3 border-b-[1px] border-gray-400"
                                      key={item.menuItemId || item.itemid}
                                    >
                                      <div className="flex gap-2 py-2 items-center ">
                                        <div className="">
                                          <img
                                            src={item.imageId.replace(
                                              "/cusecho",
                                              "https://d1ntmcvl0pjnmq.cloudfront.net"
                                            )}
                                            alt=""
                                            className="w-20 h-20 rounded-md object-cover"
                                          />
                                        </div>
                                        <div className="flex flex-col gap-1 text-sm font-medium">
                                          <p className="font-semibold">
                                            {item.itemName} x
                                            {item.quantityOrder}
                                          </p>
                                          <p className="">
                                            &#8358; {item.itemPrice}
                                          </p>
                                          {/* <p>Size: EU: 44</p>
                              <p>Color: Red</p> */}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="space-y-2 border-b-[1px] pb-3 mb-2 border-gray-400 m-3 ">
                                <div className="flex items-center justify-between gap-4">
                                  <p>Subtotal</p>
                                  {type?.billingDetail?.deliveryDetail?.fee ? (
                                    <p>
                                      &#8358;{" "}
                                      {type?.transactionPayload?.totalAmount -
                                        type?.billingDetail?.deliveryDetail
                                          ?.fee}
                                    </p>
                                  ) : (
                                    <p>
                                      &#8358;{" "}
                                      {type?.transactionPayload?.totalAmount}
                                    </p>
                                  )}
                                </div>
                                <div className="flex items-center justify-between gap-4">
                                  <p>Shipping</p>
                                  <p>
                                    &#8358;{" "}
                                    {type?.billingDetail?.deliveryDetail?.fee ||
                                      "0.00"}
                                  </p>
                                </div>
                                <div className="flex items-center justify-between gap-4">
                                  <div>
                                    <p>Coupon</p>
                                  </div>
                                  <p>&#8358; 0.00</p>
                                </div>
                              </div>
                              <div className="flex items-center justify-between gap-4 p-3 py-0">
                                <p>Total</p>
                                <p>
                                  &#8358; {type.transactionPayload.totalAmount}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="p-3">
                            <p className="font-semibold text-xl text-gray-900">
                              Delivery details
                            </p>
                            <div className="border mt-1.5 border-gray-300 rounded p-3 space-y-1">
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Name:</p>
                                <p>{type.billingDetail.fullName}</p>
                              </div>
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Address:</p>
                                <p>
                                  {type?.billingDetail?.deliveryDetail
                                    ?.address || type?.billingDetail?.email}
                                </p>
                              </div>
                              <div className="flex items-center gap-6">
                                <p className="w-[60px]">Phone:</p>
                                <p>{type.billingDetail.phone}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <MyPrintComponent
                          orderID={payOrderId}
                          finalTotalPrice={finalTotalPrice}
                          deliveryFee={type?.billingDetail?.deliveryDetail?.fee}
                          name={type.billingDetail.fullName}
                          address={type?.billingDetail?.deliveryDetail?.address}
                          phone={type.billingDetail.phone}
                          email={type?.billingDetail?.email}
                          paymentDetails={paymentDetails}
                          orderPayload={type}
                          referenceValue={referenceValue}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default WebStoreCheckoutPage;
