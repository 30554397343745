/* eslint-disable array-callback-return */
//@ts-nocheck

import { useEffect, useState, useRef, Fragment } from "react";
import PageHeader from "../../../components/Dashboard/PageHeader";
import { METRICS_COLORS, classNames } from "../../../utils";
import { AiFillCaretDown } from "react-icons/ai";
import { useAppSelector } from "../../../hooks";
import { UserProfileModel } from "../../../models";
import { TbChevronsRight, TbChevronRight, TbSearch } from "react-icons/tb";
import ApexCharts from "apexcharts";
import SkeletonLoader from "../../../components/Dashboard/SkeletonLoader";
import { useMutation, useQuery } from "@tanstack/react-query";
import SourceIcon from "../../../components/Dashboard/SourceIcon";
import moment from "moment";
import { getTraceBack } from "../../../api/KPIs";
import { ImSpinner3 } from "react-icons/im";
import SearchReviewsEmptyState from "./SearchReviewsEmptyState";
import { FiInfo } from "react-icons/fi";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "../../../components/Dashboard/Modal";
import { Link, useNavigate } from "react-router-dom";

interface ChartProps {
  data: any[];
}

interface ChartProps {
  data: any[];
}

const Chart1 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);

  //   const total_negative = data[0]?.NEG;
  //   const total_positive = data[0]?.POS;

  const newArray = data.map((x) => {
    const y = x.commentLabel;
    return y;
  });

  var total_positive = newArray.filter((str) => str.includes("POS")).length;
  var total_negative = newArray.filter((str) => str.includes("NEG")).length;

  useEffect(() => {
    let options = {
      chart: {
        type: "pie",
        width: "100%",
        height: 200,
        // offsetY: -20,
      },
      title: {
        text: "Overall Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors: ["#3DB832", "#CD151E"],
      series: [total_positive, total_negative],
      labels: ["Positive", "Negative"],
      legend: {
        position: "left",
        offsetY: 70,
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, total_positive, total_negative]);

  return <div ref={chartRef} id="oa-chart" className=""></div>;
};

const Chart2 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);

  const t = data.map(({ commentLabel, similarityScore }) => {
    return {
      commentLabel,
      similarityScore: Number(similarityScore.slice(0, -1)),
    };
  });

  const class1P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 0 &&
      x.similarityScore < 25
  ).length;
  const class2P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 25 &&
      x.similarityScore < 50
  ).length;
  const class3P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 50 &&
      x.similarityScore < 75
  ).length;
  const class4P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 75 &&
      x.similarityScore < 100
  ).length;

  const class1N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 0 &&
      x.similarityScore < 25
  ).length;
  const class2N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 25 &&
      x.similarityScore < 50
  ).length;
  const class3N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 50 &&
      x.similarityScore < 75
  ).length;
  const class4N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 75 &&
      x.similarityScore < 100
  ).length;

  useEffect(() => {
    let options = {
      chart: {
        type: "bar",
        width: "100%",
        height: 200,
        toolbar: {
          show: false,
        },
        // offsetY: -20,
      },
      title: {
        text: "Similarity Score Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors: ["#3DB832", "#CD151E"],
      series: [
        {
          name: "Positive",
          data: [class1P, class2P, class3P, class4P],
        },
        {
          name: "Negative",
          data: [class1N, class2N, class3N, class4N],
        },
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["0% - 25%", "25% - 50%", "50% - 75%", "75% - 100%"],
        labels: {
          style: {
            fontSize: "8px",
            fontWeight: 500,
          },
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div ref={chartRef} id="ssc-chart" className=""></div>;
};

const SearchReviewsPage = () => {
  let tracebacks: any[] = [];

  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const platformKey = queryKey.replace("%26", "&");
  const [fireSearch, setFireSearch] = useState(1);
  const [startDateTimeStamp, setStartDateTimeStamp] =
    useState("1683210109.78196");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState("1684278316.93325");
  const [scoreLevel, setScoreLevel] = useState(50);
  const [searchInput, setSearchInput] = useState("");
  const reviewByRow = 5;
  const [next, setNext] = useState(reviewByRow);
  const handleMoreReviews = () => {
    setNext(next + reviewByRow);
  };
  const [showModal, setShowModal] = useState(true);

  const navigate = useNavigate();

  const traceback = useQuery<any, Error>({
    queryKey: ["getTraceback", fireSearch],
    queryFn: async () => {
      const data = {
        timestamp: [startDateTimeStamp, endDateTimeStamp],
        platformKey,
        // business_thought: [searchInput],
        business_thought: ["marketing"],
        commentTag: "POS",
        scoreLevel: scoreLevel,
      };
      const response = await getTraceBack(data);
      return response.data;
    },
  });

  // const handleSearchTraceback = useMutation({
  //   mutationFn: async (data) => {
  //     const data = {
  //       timestamp: [startDateTimeStamp, endDateTimeStamp],
  //       platformKey,
  //       // business_thought: [searchInput],
  //       business_thought: ["marketing"],
  //       commentTag: "POS",
  //       scoreLevel: scoreLevel,
  //     };
  //     const response = await getTraceBack(data);
  //     return response.data;
  //   },
  //   onSuccess: () => {
  //     setShowSearchDropDown(false)
  //   },
  // });

  if (traceback.data) {
    tracebacks = traceback.data.Mapped_Reviews;
  }

  // console.log(tracebacks)

  // if (traceback.status === "error")
  //   return <span>Error: {traceback.error.message}</span>;

  const trest = [1, 2];

  return (
    <>
      <div className="space-y-1">
        <div className="h-[85vh] bg-white rounded-md shadow-md py-3 px-4 border-l-[10px] overflow-y-hidden ">
          <div className="bg-white -top-2">
            <div className="flex items-center justify-between px-4 py-2 border border-gray-200 rounded-md">
              <div className="relative w-2/4">
                <form className="">
                  <div className="relative w-full ">
                    <input
                      type="text"
                      placeholder="Search your thoughts here..."
                      className="w-full px-10 py-2 text-gray-800 transition duration-300 ease-linear border border-gray-300 outline-none cursor-pointer bg-gray-50 rounded-xl placeholder:text-gray-300 focus:border-gray-800"
                      onClick={() =>
                        setShowSearchDropDown((prevState) => !prevState)
                      }
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <TbSearch
                      size={20}
                      strokeWidth={2}
                      className="absolute text-gray-300 inset-y-3 left-3"
                    />
                  </div>
                </form>
                {showSearchDropDown && (
                  <div className="absolute top-16 w-[560px] grid grid-cols-2 gap-6 shadow-md border py-2 px-3 z-20 rounded bg-white">
                    <div className="space-y-7">
                      <div className="text-gray-500">
                        <p className="mb-2">Date</p>
                        <div className="flex items-center space-x-2 bg-white">
                          <div date-rangepicker="true">
                            <span className="text-sm text-gray-500">
                              Start Date
                            </span>
                            <div className="relative">
                              <input
                                name="start"
                                type="date"
                                className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                                placeholder="Select date"
                              />
                            </div>
                          </div>
                          <div date-rangepicker="true">
                            <span className="text-sm text-gray-500">
                              End Date
                            </span>
                            <div className="relative">
                              <input
                                name="end"
                                type="date"
                                onChange={(e) =>
                                  setEndDateTimeStamp(
                                    new Date(e.target.value).getTime() / 1000
                                  )
                                }
                                className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                                placeholder="Select date"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-300 bg-transparent border rounded-md"
                          disabled
                          onClick={() =>
                            setCategoryDropdown((prevState) => !prevState)
                          }
                          type="button"
                        >
                          Category
                          <svg
                            className="w-4 h-4 ml-4"
                            aria-hidden="true"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </button>
                        {categoryDropdown && (
                          <div
                            id="dropdown"
                            className={`absolute top-6 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                          >
                            <ul
                              className="py-2 text-sm text-gray-700"
                              aria-labelledby="dropdownDefaultButton"
                            >
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                >
                                  Marketing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                >
                                  Settings
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                >
                                  Earnings
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="default-range"
                          className="block text-sm font-medium text-gray-300"
                        >
                          Weight
                        </label>
                        <input
                          id="default-range"
                          type="range"
                          value={scoreLevel}
                          min="0"
                          max="100"
                          step="25"
                          onChange={(e) => setScoreLevel(e.target.value)}
                          className="w-3/5 h-1 bg-gray-100 rounded-md appearance-none cursor-pointer"
                        />
                        <p className="-mt-1 text-sm text-gray-200 mb-14"></p>
                      </div>
                      <p className="text-sm font-semibold text-gray-600">
                        Total Filtered Reviews
                      </p>
                    </div>

                    <div className="text-gray-500">
                      <p className="mb-2">Source</p>
                      <div className="p-3 space-y-3 border rounded-md">
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-700">
                            QR Code Scan
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            App & Web integration plugins. (0 results)
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            Facebook (0 results)
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            Twitter (0 results)
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            Voice Integration Support
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            Instagram (0 results)
                          </label>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            disabled
                            id="disabled-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          />
                          <label className="ml-2 text-sm font-medium text-gray-400">
                            SMS Survey (0 results)
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center justify-center mt-2 space-x-2 text-sm">
                        <button
                          className="w-1/2 px-4 py-2 font-bold text-gray-700 truncate bg-transparent border rounded"
                          onClick={() => setShowSearchDropDown(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="w-1/2 px-4 py-2 font-bold text-white truncate rounded bg-primary"
                          onClick={() => {
                            if (fireSearch === 1) {
                              setFireSearch(2);
                            } else setFireSearch(1);
                            setShowSearchDropDown(false);
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {tracebacks.length < 1 || searchInput === "" ? (
            <SearchReviewsEmptyState />
          ) : (
            <div>
              {traceback.status === "loading" && (
                <div className="flex items-center justify-start py-5">
                  <ImSpinner3
                    size={18}
                    className="mx-auto animate-spin fill-primary"
                  />
                </div>
              )}

              {traceback.status === "error" && (
                <div className="flex items-center justify-center py-5">
                  <span>Error: {traceback.error.message}</span>
                </div>
              )}

              {tracebacks.length > 0 && (
                <div className="sticky space-y-2 bg-white top-10 ">
                  <div className="grid grid-cols-3 gap-x-2 h-[40%] overflow-hidden mt-2">
                    <div className="p-4 overflow-hidden border border-gray-200 rounded-md">
                      {tracebacks && <Chart1 data={tracebacks} />}
                    </div>
                    <div className="p-4 overflow-hidden border border-gray-200 rounded-md">
                      {tracebacks && <Chart2 data={tracebacks} />}
                    </div>
                    <div className="p-2 border border-gray-200 rounded-md">
                      <p className="mb-4 text-lg font-semibold text-gray-500">
                        Keyword Analytics
                      </p>
                      <div className="relative overflow-x-auto">
                        <table className="w-full overflow-x-auto text-sm text-left text-gray-500 rounded-lg">
                          <thead className="font-semibold text-gray-800 border-t border-b">
                            <tr className="grid grid-cols-4 text-base text-center">
                              <th
                                scope="col"
                                className="flex items-center justify-center p-1 text-xs border-r"
                              >
                                <p> Keyword</p>
                              </th>
                              <th
                                scope="col"
                                className="flex items-center justify-center p-1 text-xs border-r"
                              >
                                <p>Occurence count</p>
                              </th>
                              <th
                                scope="col"
                                className="flex items-center justify-center col-span-2 p-1 text-xs"
                              >
                                <p>Sources</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {trest.map((fetched, index) => (
                              <tr
                                className="grid grid-cols-4 text-xs bg-white border-b"
                                key={index}
                              >
                                <td className="flex items-center justify-center px-2 py-2.5 border-x">
                                  <p>Promotion</p>
                                </td>
                                <td className="flex items-center justify-center px-2 border-r">
                                  <p>10</p>
                                </td>
                                <td className="col-span-2 p-2">
                                  <div className="flex flex-wrap gap-1">
                                    <span className="p-1 text-xs font-semibold text-gray-900 rounded bg-secondary-2">
                                      Instagram
                                    </span>
                                    <span className="p-1 text-xs font-semibold text-gray-900 rounded bg-secondary-2">
                                      Voice
                                    </span>
                                    <span className="p-1 text-xs font-semibold text-gray-900 rounded bg-secondary-2">
                                      SMS
                                    </span>
                                    <span className="p-1 text-xs font-semibold text-gray-900 rounded bg-secondary-2">
                                      Facebook
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 pb-2">
                    <p className="text-sm text-gray-400">
                      Reviews That Match Your Search:
                    </p>
                  </div>
                </div>
              )}

              {tracebacks.length > 0 && (
                <div className="h-[50%] overflow-y-scroll relative">
                  {tracebacks
                    ?.slice(0, next)
                    ?.map((comment: any, index: number) => {
                      const similarity_score: string = comment.similarityScore;
                      const source = comment.commentSource;
                      const sentiment_tag: string = comment.commentLabel;
                      return (
                        <div
                          key={index}
                          className={classNames(
                            "flex mb-2 space-x-1 border-none rounded-xl p-2"
                          )}
                        >
                          <div className="flex flex-col items-center justify-center w-12 h-12 border border-gray-300 rounded-full text-primary">
                            <SourceIcon source={source} />
                          </div>
                          <div className="flex flex-col flex-1 gap-1 mt-4">
                            <h2 className="text-base font-medium text-black">
                              Anonymous{" "}
                              <span className="ml-2 text-sm text-gray-400">
                                {moment(comment.uploadTime)
                                  .startOf("hour")
                                  .fromNow()}
                              </span>
                            </h2>
                            <p
                              className={classNames(
                                "text-sm font-normal leading-normal text-gray-500"
                              )}
                            >
                              {comment.userReview}
                            </p>
                            <div className="flex items-center justify-between px-5 py-2 bg-gray-100 rounded-full w-[21rem]">
                              <p className="text-base font-normal text-gray-400">
                                Similarity Score{" "}
                                <span
                                  className={classNames(
                                    "ml-2 font-semibold text-black"
                                  )}
                                >
                                  {similarity_score}
                                </span>
                              </p>
                              <div className="inline-flex items-center">
                                <p className="text-base font-normal text-gray-400">
                                  Sentiment Tag{" "}
                                </p>
                                <div
                                  className={classNames(
                                    "h-4 w-4 ml-2 font-semibold text-black rounded-full",
                                    sentiment_tag === "NEG"
                                      ? "bg-red-600"
                                      : sentiment_tag === "POS"
                                      ? "bg-green-600"
                                      : "bg-gray-400"
                                  )}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}

              {tracebacks.length === 0 && traceback.status !== "loading" && (
                <div className="mt-8 text-center">
                  <p className="text-xl text-gray-400">
                    You don&apos;t have any history yet
                  </p>
                </div>
              )}
            </div>
          )}

        </div>
        <p className="bg-white text-sm text-gray-800 font-medium max-w-[600px] mx-auto  py-1 px-2 flex gap-3 items-center rounded my-1">
          <FiInfo size={20} />{" "}
          <span>
            Please bear in mind that as we continue to optimize our platform,
            there may be some inconsistencies in the information provided.
          </span>
        </p>
      </div>
      
      {/* <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[200]"
            onClose={() => {
              // setShowModal(false);
              navigate(-1);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[380px] md:w-[460px] lg:w-[520px] max-w-2xl transform text-center overflow-hidden rounded-md bg-white px-6 align-middle shadow-xl transition-all">
                    <div className="text-center my-14">
                      <h4 className="text-2xl font-semibold">Need To Validate Some Ideas?</h4>
                      <p className="mt-2 text-sm font-medium leading-tight text-gray-700">
                          You are currently on the free plan but getting more features is easy
                      </p>
                      <Link to="https://www.cusecho.com/pricing" target="_blank" className="inline-block px-8 py-3 mt-6 text-lg font-semibold text-center text-white rounded-md bg-primary">Request An Upgrade</Link>
                      <p className="hidden mt-3 text-sm font-medium leading-tight text-gray-700">
                          Learn about our plans and pricing <Link to="/settings/subscriptions" className="underline text-primary underline-offset-1">here</Link>
                      </p>
                  </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
      </Transition> */}
    </>
  );
};

export default SearchReviewsPage;
