import React from "react";
import { UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { downloadNPSResponses } from "../../../../../../api/Utils";
import SkeletonLoader from "../../../../../../components/Dashboard/SkeletonLoader";
import { FaRobot } from "react-icons/fa";

const CommentTracker = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.platformAccountId}%26${user.email}`;
  let nps: any;
  let width: any;

  const npsResponses = useQuery<any, Error>({
    queryKey: ["getNPSResponses"],
    queryFn: async () => {
      const response = await downloadNPSResponses(queryKey);
      // 1002820710%26admin@cloudpactai.com
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  if (npsResponses.data) {
    nps = npsResponses.data;
  }

  if (npsResponses.status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (npsResponses.status === "error")
    return <span>Error: {npsResponses.error.message}</span>;

  return (
    <div>
      <div className="h-full p-2.5 bg-gray-50/70  rounded-lg text-sm border border-gray-100 flex flex-col group">
        <div className="space-y-0.5">
          <p className="font-semibold">Net promoter score</p>
          <p className="text-2xl font-semibold">
            {nps.NPS_Score.toFixed(1) || ""}
          </p>
          <p className="text-xs">+2 since last month.</p>
        </div>
        <div className="my-2">
          <div className="h-[75px] w-full flex items-center">
            <div className="w-[50%] h-full relative">
              <div className="w-full bg-gray-200/80 h-[calc(100%-16px)] my-2">
                {nps.NPS_Score.toFixed(1) < 0 && (
                  <div
                    style={{ width: Math.abs(nps.NPS_Score.toFixed()) + "%" }}
                    className={`ml-auto h-full bg-orange-500`}
                  ></div>
                )}
              </div>
            </div>
            <div className="w-[50%] h-full border-l-[1px] border-gray-300 relative">
              <div className="w-full bg-gray-200/80 h-[calc(100%-16px)] my-2">
                {nps.NPS_Score.toFixed(1) > 0 && (
                  <div
                    style={{ width: Math.abs(nps.NPS_Score.toFixed()) + "%" }}
                    className={`h-full bg-blue-600 overflow-hidden`}
                  ></div>
                )}
              </div>
              <p className="absolute top-[26%] bottom-0 left-4 text-2xl font-medium text-white hidden">
                40
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between text-[12px] mt-2">
            <span>-100</span>
            <span>+100</span>
          </div>
        </div>
        <p className="text-[11px] mt-auto inline-flex gap-0.5 justify-between">
          Net Promoters Score is {nps.NPS_Score.toFixed(1)}. This indicates a
          very low level of customer satisfaction and loyalty.
          <span>
            <FaRobot
              size={16}
              className="text-[#0072BB]/90 animate-ping cursor-pointer opacity-0 group-hover:opacity-100"
            />
          </span>
        </p>
      </div>
    </div>
  );
};

export default CommentTracker;
