import React, { useState } from "react";
import { FaRobot } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import {
  MdChatBubbleOutline,
  MdOutlineChat,
  MdOutlineClose,
} from "react-icons/md";
import { classNames } from "../../../utils";

const CusechoChat = () => {
  const [showChat, setShowChat] = useState(false);
  return (
    <div className="absolute z-100 right-4 bottom-4 flex flex-col gap-2.5 items-end">
      {showChat ? (
        <div
          className={classNames(
            "h-[78vh] w-[400px] bg-white rounded-xl shadow flex flex-col transition-opacity duration-500 ease-in-out",
            showChat ? "opacity-100" : "opacity-0"
          )}
        >
          <div className="bg-[#2960BF] p-4 rounded-t-xl flex items-center justify-between">
            <p className="text-gray-100 text-sm">
              Cusecho Assistant Bot -{" "}
              <span className="text-base font-semibold">PADI</span>
            </p>
            <MdOutlineClose
              size={20}
              className="text-amber-50 hover:text-amber-200 cursor-pointer"
              onClick={() => setShowChat(false)}
            />
          </div>
          <div className=" flex-1 flex flex-col gap-3 ">
            <div className="flex flex-col py-8 px-6 hidden">
              <div className="text-center">
                <div className="flex items-center gap-4 ">
                  <div
                    className=" h-10 w-10 flex items-center justify-center bg-[#2960BF] rounded-full"
                    onClick={() => {
                      setShowChat((prev) => !prev);
                    }}
                  >
                    <MdChatBubbleOutline size={19} className="text-amber-50" />
                  </div>
                  <p className="text-[#2960BF] text-sm font-medium ">
                    Ask Padi anything !
                  </p>
                </div>

                <p className="text-[12.5px] text-gray-700 mt-3 hidden">
                  Tired of sifting through endless customer reviews? PADI, our
                  advanced AI chatbot, is here to streamline the process. PADI
                  automatically analyzes and categorizes reviews, extracting key
                  insights and sentiment to help you understand your customers'
                  experiences.
                </p>
              </div>
            </div>
            <div className="h-[435px] overflow-y-auto p-3 chatBotScrollbar" >
              {[1, 2, 3, 4, 5].map((index: number) => (
                <div key={index} className="flex flex-col gap-3 mb-3">
                  <div className="flex gap-2">
                    <FaRobot size={20} className="text-[#0072BB]/90 mt-3" />
                    <div className="bg-[#0072BB]/5 shadow rounded-lg p-3 w-[80%]">
                      <p className="text-gray-800 text-[12px] font-medium">
                        Hello! How can I help you?
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="p-3 w-[80%] bg-[#2960BF] rounded-lg">
                      <p className="text-gray-100 text-[11.5px]">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Non dicta assumenda, aliquid aut quam magni!
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-auto w-full p-3 py-2 border-t-[1px] border-gray-200 relative">
              <input
                type="text"
                className="p-3 py-1.5 bg-white border-[1.5px] border-[#2960BF]/80 outline-[1.5px] outline-[#2960BF]/90 w-full rounded-md"
              />
              <IoMdSend
                size={20}
                className="text-[#2960BF]/60 hover:text-[#2960BF]/80 cursor-pointer absolute right-5 top-4 -rotate-45"
              />
            </div>
          </div>
        </div>
      ) : null}

      <div
        className=" h-10 w-10 flex items-center justify-center bg-[#2960BF] rounded-lg cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 hover:bg-blue-600 duration-300"
        onClick={() => {
          setShowChat((prev) => !prev);
        }}
      >
        {showChat ? (
          <MdOutlineClose size={24} className="text-amber-50" />
        ) : (
          <MdChatBubbleOutline size={20} className="text-amber-50" />
        )}
      </div>
    </div>
  );
};

export default CusechoChat;
