// @ts-nocheck

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState, Fragment } from "react";
import {
  confirmPasscode,
  downloadNotifications,
  getOrders,
  getSingleItem,
  handleReadNotification,
  updateOrderStatus,
  updatePaymentAcknowledgementStatus,
  updatePaymentAcknowledgementStatus1,
  updateSingleOrderStatus,
} from "../../../../api/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";
import { BiChevronDown, BiChevronUp, BiInfoCircle } from "react-icons/bi";
import { classNames, getRelativeTime } from "../../../../utils";
import { MdClear, MdKeyboardBackspace, MdOutlineCancel } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import { IoMdMore } from "react-icons/io";
import Modal from "../../../../components/Dashboard/Modal";
import { boolean } from "yup";
import OTPInput from "../../../../components/Auth/Forms/OTPInput";
import toast, { Toaster } from "react-hot-toast";

const OrderStatus = [
  { status: "Pending" },
  { status: "Active" },
  { status: "Completed" },
  { status: "Cancelled" },
];

interface OrderProps {
  showCheckbox: boolean;
  setShowCheckbox: any;
  setOrdersIdArray: any;
  ordersIdArray: any;
}

const PendingOrders = ({
  showCheckbox,
  setShowCheckbox,
  setOrdersIdArray,
  ordersIdArray,
}: OrderProps) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const apiKey: string = useAppSelector((state) => state.auth.apiKey);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const text = `${window.location.origin}/store${storeKey}`;
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const navigate = useNavigate();
  const [open, setOpen] = useState(-1);
  const [openStatus, setOpenStatus] = useState(-1);
  const [openSingleItem, setOpenSingleItem] = useState<number | null>(-1);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [selectedItemModal, setSelectedItemModal] = useState({
    amount: "",
    name: "",
    address: "",
    phone: "",
    orderId: "",
  });
  let orderNotifications: any[] = [];
  let queueIds: any[] = [];

  let orders: any = [];

  const allOrders = useQuery<any, Error>({
    queryKey: ["getPendingOrders"],
    queryFn: async () => {
      const paginationKey = "";
      const response = await getOrders(queryKey, paginationKey, "Pending");
      return response.data;
    },
  });

  const handleOrderStatusUpdate = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderNumber: data.orderNumber,
        orderSpecificId: data.orderId,
        oldStatus: "Pending",
        status: data.orderStatus,
      };

      return await updateSingleOrderStatus(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"] });
      setOpenStatus(-1);
    },
  });

  const handlePaymentAcknowledgementUpdate = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderId: selectedItemModal.orderId,
        switchMode: "1",
      };

      return await updatePaymentAcknowledgementStatus1(payload, apiKey);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"] });
      setOpenStatus(-1);
      setTimeout(() => {
        setSelectedItemModal({
          amount: "",
          name: "",
          address: "",
          phone: "",
        });
        setStep(1);
        setShowModal(false);
      }, 1000);
    },
  });

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", selectedItemId],
    queryFn: async () => {
      const response = await getSingleItem(`${selectedItemId}`);
      return response.data;
    },
    enabled: !!selectedItemId,
    onSuccess: (data) => {
      setSelectedItem(data.Menu_Item);
      // console.log(data.Menu_Item);
    },
    onError: (err) => {},
  });

  const getStoreNotifications = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreNotifications"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadNotifications(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (getStoreNotifications.data) {
    if ("pages" in getStoreNotifications.data) {
      getStoreNotifications.data.pages.forEach((page) => {
        if ("Notification_Catalog" in page) {
          if ("Order_Notification" in page.Notification_Catalog) {
            orderNotifications = [
              ...orderNotifications,
              ...page.Notification_Catalog.Order_Notification,
            ];
            if (orderNotifications.length > 0) {
              queueIds = orderNotifications.map((n) => n.queueId);
            }
          }
        }
      });
    }
  }

  const handleClearNotification = useQuery<any, Error>({
    queryKey: ["clearNotifications", orderNotifications, queueIds],
    queryFn: async (data) => {
      const payload = {
        platformKey,
        notificationSubject: "Order_Notification",
        queueId: queueIds,
      };
      return await handleReadNotification(payload);
    },
    enabled: !!getStoreNotifications.data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getNotificationCount"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreNotifications"] });
    },
  });

  if (allOrders.data) {
    const nonCompletedOrders = allOrders.data.Order_Catalog.filter(
      (c: any) => c.orderStatus !== "Completed"
    );

    const nonCancelled = nonCompletedOrders.filter(
      (c: any) => c.orderStatus !== "Cancelled"
    );
    orders = allOrders.data.Order_Catalog;
    // console.log(orders);
  }

  const handleChange = (value: string) => {
    setCode(value);
  };

  const handleConfirmPasscode1 = useMutation({
    mutationFn: async (data) => {
      return await confirmPasscode(queryKey, code);
    },
    onSuccess: (data) => {
      // console.log(data.data);
      if (data.data.request_challenge === "failed") {
        // alert("Wrong passcode");
        toast.error("Wrong passcode");
        // setStep(1);
      }
      if (data.data.request_challenge === "success") {
        handlePaymentAcknowledgementUpdate.mutate();
        setStep(2);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setStep(1);
          setCode("");
        }}
      >
        {step === 1 ? (
          <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8">
            <div className="flex items-center justify-between">
              <p className="font-semibold text-lg">
                Have you received payment for this order?
              </p>
              <MdOutlineCancel
                size={22}
                className="text-gray-700"
                onClick={() => {
                  setShowModal(false);
                  setSelectedItemModal({
                    amount: "",
                    name: "",
                    address: "",
                    phone: "",
                  });
                }}
              />
            </div>
            <div className="mt-4">
              <p className="text-[13px] text-gray-700">
                This order was completed via bank transfer. Please confirm
                payment in your banking app before viewing the order.
              </p>
            </div>
            <div className="mt-3">
              <p className="mb-1 text-sm">Details</p>
              <div className="border border-gray-200 px-2.5 py-3 rounded space-y-1.5 text-gray-800">
                {selectedItemModal?.amount && (
                  <div className="text-[13px] flex items-center gap-4 ">
                    <p className="w-[50px]">Amount:</p>
                    <p>&#8358; {selectedItemModal?.amount.toLocaleString("en")}</p>
                  </div>
                )}
                <div className="text-sm flex items-center gap-4 ">
                  <p className="w-[50px]">Name:</p>
                  <p>{selectedItemModal?.name}</p>
                </div>
                {selectedItemModal?.address && (
                  <div className="text-sm flex items-center gap-4 ">
                    <p className="w-[50px]">Address:</p>
                    <p>{selectedItemModal?.address}</p>
                  </div>
                )}
                {selectedItemModal?.phone && (
                  <div className="text-sm flex items-center gap-4 ">
                    <p className="w-[50px]">Phone:</p>
                    <p>{selectedItemModal?.phone}</p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <p className="text-xs text-gray-600 mt-2.5">
                Kindly Note: A 1.5% fee applies to all transactions via bank
                transfer and Paystack.
              </p>
            </div>
            <div className="flex justify-end">
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={(e) => {
                    setShowModal(false);
                    setSelectedItemModal({
                      amount: "",
                      name: "",
                      address: "",
                      phone: "",
                    });
                  }}
                  className={classNames(
                    "mt-4 p-2 px-4 text-sm font-medium text-center text-gray-800 bg-transparent rounded-md transition duration-200 ease-linear border border-gray-200"
                  )}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    setStep(2);
                  }}
                  className={classNames(
                    "mt-4 p-2 px-4 text-sm font-medium text-center text-white bg-blue-600 hover:bg-blue-700 rounded-md transition duration-200 ease-linear"
                  )}
                >
                  {/* {handlePaymentAcknowledgementUpdate.isLoading
                    ? "Loading..."
                    : "Yes, Payment received"} */}
                  Yes, Payment received
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8">
            <div className="col-span-3 h-full bg-white p-3">
              <div className="h-full flex items-center justify-center">
                <div className="p-6 pt-3 text-sm space-y-1 max-w-[500px] mx-auto">
                  <p className="font-semibold text-gray-900 hidden">
                    Enter a 6 digit code
                  </p>
                  <div>
                    <OTPInput
                      label="Enter your 6-digit encrypted passcode"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      type="submit"
                      disabled={code.length < 6}
                      onClick={() => {
                        // alert(1);
                        handleConfirmPasscode1.mutate();
                      }}
                      className="text-xs w-[150px] py-3 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                    >
                      {handleConfirmPasscode1.isLoading ||
                      handlePaymentAcknowledgementUpdate.isLoading ? (
                        <ImSpinner2
                          size={20}
                          className="mx-auto animate-spin"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <div>
        {allOrders.status === "loading" || handleOrderStatusUpdate.isLoading ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : allOrders.status === "error" ? (
          <p className="mt-8 text-sm font-semibold text-center text-gray-400">
            {allOrders.error.message}
          </p>
        ) : orders.length > 0 ? (
          <div className="space-y-2.5">
            {orders?.map((order: any, index: number) => (
              <div className="relative p-2 border rounded" key={order.orderId}>
                <div
                  className="flex gap-2 text-xs md:items-center sm:gap-4 cursor-pointer"
                  onClick={() => {
                    if (
                      order?.paymentMap?.paymentType === "transfer" &&
                      (order?.paymentMap?.acknowledgement === "pending" ||
                        order?.paymentMap?.acknowledgment === "pending")
                    ) {
                      setShowModal(true);
                      setSelectedItemModal({
                        orderId: order?.orderId,
                        amount: order?.totalAmount,
                        name: order?.billingDetail.fullName,
                        address: order.billingDetail.address,
                        phone: order.billingDetail.phone,
                      });
                      return;
                    }

                    if (open === order.orderId) {
                      setOpen(-1);
                      setOpenSingleItem(-1);
                      setOpenStatus(-1);
                      return;
                    }
                    setOpen(order.orderId);
                  }}
                >
                  <div className="flex flex-col gap-0.5 md:flex-row md:items-center md:gap-4">
                    {showCheckbox && (
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (ordersIdArray.includes(order.orderId)) {
                            const a = ordersIdArray.filter(
                              (i) => i !== order.orderId
                            );
                            setOrdersIdArray(a);
                            return;
                          }
                          setOrdersIdArray((prev) => [...prev, order.orderId]);
                        }}
                      />
                    )}
                    <p className="flex flex-col md:flex-row">
                      <span className="hidden md:inline">Name:&nbsp;</span>
                      <span className="font-semibold">
                        {order.billingDetail === null ||
                        order.billingDetail === undefined
                          ? "No Name"
                          : order?.billingDetail.fullName}
                      </span>
                    </p>
                    <p className="flex">
                      <span className="">Order Number:&nbsp;</span>
                      <span className="font-semibold">{order.orderNumber}</span>
                    </p>
                    <p className="flex text-xs font-medium">
                      <span className="hidden sm:inline">
                        &#8226;&nbsp;&nbsp;
                      </span>
                      <span className="text-[#575757] font-medium">
                        {getRelativeTime(order.creationTimestamp)}
                      </span>
                    </p>
                  </div>
                  <div className="relative ml-auto">
                    <BiChevronUp
                      size={25}
                      className={classNames(
                        "cursor-pointer",
                        open === order.orderId ? "rotate-90" : "rotate-180"
                      )}
                      onClick={() => {
                        if (open === order.orderId) {
                          navigate(`/orders/${order.orderId}`);
                          setOpen(-1);
                          setOpenSingleItem(-1);
                          return;
                        }
                        setOpen(order.orderId);
                      }}
                    />
                  </div>
                </div>
                {open === order.orderId && (
                  <>
                    <div className="my-3 border border-b-0 border-gray-400 rounded ">
                      <div className="">
                        <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400 font-semibold">
                          <div className="flex col-span-6">
                            <p className="hidden sm:block w-[50px] text-[10px] md:text-xs text-center p-1.5">
                              S/N
                            </p>
                            <p className="text-[10px] md:text-xs w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400">
                              ITEMS
                            </p>
                          </div>
                          <p className="text-[10px] md:text-xs col-span-1 p-1.5 text-center">
                            QTY
                          </p>
                        </div>
                        {order?.products.map((orderItem: any, index: any) => (
                          <div className="relative" key={index}>
                            <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400">
                              <div className="flex col-span-6 text-xs items-center justify-center">
                                <p className="hidden sm:block w-[50px] text-center p-1.5">
                                  {index + 1}
                                </p>
                                <div className=" w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400 flex items-center font-medium gap-3">
                                  <span className="">
                                    {orderItem.itemName.replace(/_/g, " ")}
                                  </span>
                                  <span
                                    className={classNames(
                                      orderItem.itemSize === "" ||
                                        orderItem.itemSize === undefined
                                        ? "hidden"
                                        : ""
                                    )}
                                  >
                                    -&nbsp;&nbsp;{orderItem.itemSize}
                                  </span>
                                  <span
                                    className={classNames(
                                      orderItem.itemSize === "" ||
                                        orderItem.itemSize === undefined
                                        ? "hidden"
                                        : ""
                                    )}
                                  >
                                    -&nbsp;&nbsp;{orderItem.itemColor}
                                  </span>
                                  <BiInfoCircle
                                    size={20}
                                    className={classNames(
                                      "cursor-pointer",
                                      selectedItemId === orderItem.itemId &&
                                        openSingleItem === orderItem.itemId
                                        ? "text-blue-600"
                                        : ""
                                    )}
                                    onClick={() => {
                                      if (!orderItem.itemId) {
                                        return;
                                      }
                                      setSelectedIndex(index);
                                      setSelectedItemId(orderItem.itemId);
                                      setOpenSingleItem(orderItem.itemId);
                                    }}
                                  />
                                  <div className="relative ml-auto bg-white hidden">
                                    <button
                                      className={classNames(
                                        "rounded-md text-xs font-semibold  py-1 px-3 flex items-center gap-1.5 justify-between border",
                                        orderItem.orderStatus === "Pending"
                                          ? "bg-[#F5F5F5] text-[#555555] border-[#555555]"
                                          : orderItem.orderStatus === "Active"
                                          ? "bg-[#F4FAFF] text-[#4056A6] border-[#4056A6]"
                                          : orderItem.orderStatus ===
                                            "Completed"
                                          ? "bg-[#C4E8A9] text-[#2C921C] border-[#2C921C]"
                                          : "bg-[#FAE2A7] text-[#7D5901] border-[#7D5901]"
                                      )}
                                      onClick={() => {
                                        if (openStatus === orderItem.itemId) {
                                          setOpenStatus(-1);
                                          return;
                                        }
                                        setOpenStatus(orderItem.itemId);
                                        setSelectedItemId(null);
                                        setOpenSingleItem(null);
                                      }}
                                    >
                                      {/* <span>{order.orderStatus}</span> */}
                                      <span>{orderItem.orderStatus}</span>
                                      <BiChevronDown size={25} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <p className=" col-span-1 p-1.5 text-center text-xs flex items-center justify-center">
                                {orderItem.quantityOrder}
                              </p>
                            </div>
                            {openSingleItem === orderItem.itemId && index === selectedIndex && (
                              <div className="p-3 absolute w-[300px] md:w-[400px] border rounded-md z-10 bottom-8 bg-white left-[5%] md:left-[20%] mx-auto shadow-md ">
                                {loadSingleItem.status === "loading" ||
                                loadSingleItem.isRefetching ? (
                                  <div className="flex items-center justify-start py-5">
                                    <ImSpinner3
                                      size={18}
                                      className="mx-auto animate-spin fill-primary"
                                    />
                                  </div>
                                ) : loadSingleItem.status === "error" ? (
                                  <div className="relative flex items-center justify-center py-5">
                                    <p className="text-center">
                                      Item not found
                                    </p>
                                    <p className="absolute top-0 right-2">
                                      <MdClear
                                        size={22}
                                        className="cursor-pointer hover:fill-blue-800"
                                        onClick={() => {
                                          setOpenSingleItem(-1);
                                          setSelectedItemId(null);
                                          setSelectedIndex(-1);
                                          queryClient.cancelQueries([
                                            "getSingleItem",
                                          ]);
                                        }}
                                      />
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    <div className="grid grid-cols-4 gap-2.5">
                                      <div className="rounded-md bg-gray-50">
                                        <img
                                          src={selectedItem?.s3ImageUrl}
                                          onError={(e) =>
                                            (e.currentTarget.src =
                                              "/media/image/productBg.png")
                                          }
                                          alt="item_image"
                                          className="w-full h-[75px] rounded-md bg-gray-50 object-cover"
                                        />
                                      </div>
                                      <div className="flex flex-col justify-between text-xs col-span-3 py-2.5">
                                        <div className="flex items-center justify-between gap-2 font-semibold">
                                          <p>{selectedItem?.itemName}</p>
                                          <p className="-mt-2.5 mr-1">
                                            <MdClear
                                              size={22}
                                              className="cursor-pointer hover:fill-blue-800"
                                              onClick={() => {
                                                setOpenSingleItem(-1);
                                                setSelectedIndex(-1);
                                                setSelectedItemId(null);
                                                queryClient.cancelQueries([
                                                  "getSingleItem",
                                                ]);
                                              }}
                                            />
                                          </p>
                                        </div>
                                        <p className="">
                                          &#x20A6;{selectedItem?.itemPrice}
                                        </p>
                                        <p className="text-xs ">
                                          {selectedItem?.itemDescription}
                                        </p>
                                      </div>
                                      <span
                                        className="absolute hidden cursor-pointer top-1 right-1"
                                        onClick={() => {
                                          setOpenSingleItem(-1);
                                          setSelectedIndex(-1);
                                          setSelectedItemId(null);
                                        }}
                                      >
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                            fill="#5A5A5A"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                            {openStatus === orderItem.itemId && (
                              <>
                                <div className="py-2 px-1 absolute border top-0 right-4 z-50 w-[120px] bg-white rounded-md hidden">
                                  {OrderStatus.map(
                                    (status: any, index: number) => (
                                      <p
                                        className="px-3 py-3 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                                        onClick={() =>
                                          handleOrderStatusUpdate.mutate({
                                            orderNumber: order.orderNumber,
                                            orderId:
                                              orderItem.order_specific_id,
                                            orderStatus: status.status,
                                          })
                                        }
                                        key={index}
                                      >
                                        {status.status}
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="hidden">
                      {order.billingDetail === null ||
                      order.billingDetail === undefined ? (
                        <div className="space-y-1 text-xs">
                          <p className="flex items-center gap-0.5">
                            <span className="text-gray-800">
                              Delivery Option:{" "}
                            </span>
                            <span className="font-medium">Pickup</span>
                          </p>
                        </div>
                      ) : (
                        <div className="space-y-1 text-xs">
                          <p className="flex items-center gap-0.5">
                            <span className="text-gray-800">
                              Delivery Option:{" "}
                            </span>
                            <span className="font-medium">Drop Off</span>
                          </p>
                          <div className="flex flex-col gap-1 md:items-center md:flex-row md:gap-16">
                            <p className="flex items-center gap-0.5">
                              <span className="text-gray-800">Phone: </span>
                              <span className="font-medium">
                                {order.billingDetail.phone}
                              </span>
                            </p>
                            <p className="flex items-center gap-0.5">
                              <span className="text-gray-800">Email: </span>
                              <span className="font-medium">
                                {order.billingDetail.email}
                              </span>
                            </p>
                            {order.billingDetail.address ===
                            undefined ? null : (
                              <p className="flex gap-0.5">
                                <span className="text-gray-800">Address: </span>
                                <span className="font-medium">
                                  {order.billingDetail.address}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-8 text-xs font-semibold text-center text-gray-800">
            No order have been placed
          </p>
        )}
      </div>

      <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default PendingOrders;
