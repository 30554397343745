import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState } from "react";
import {
  downloadNotifications,
  getOrders,
  getSingleItem,
  handleReadNotification,
  updateOrderStatus,
} from "../../../../api/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ImSpinner3 } from "react-icons/im";
import { BiChevronDown, BiChevronUp, BiInfoCircle } from "react-icons/bi";
import { classNames, getRelativeTime } from "../../../../utils";
import { MdClear } from "react-icons/md";

const OrderStatus = [
  { status: "Pending" },
  { status: "Active" },
  { status: "Completed" },
  { status: "Cancelled" },
];

const CompletedOrders = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const location = useLocation();

  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const text = `${window.location.origin}/store${storeKey}`;
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const navigate = useNavigate();
  const [open, setOpen] = useState(-1);
  const [openStatus, setOpenStatus] = useState(-1);
  const [openSingleItem, setOpenSingleItem] = useState<number | null>(-1);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  let orderNotifications: any[] = [];
  let queueIds: any[] = [];

  let orders: any = [];

  const allOrders = useQuery<any, Error>({
    queryKey: ["getCompletedOrders"],
    queryFn: async () => {
      const paginationKey = "";
      const response = await getOrders(queryKey, paginationKey, "Completed");
      return response.data;
    },
  });

  const handleOrderStatusUpdate = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderId: data.orderId,
        orderStatus: data.orderStatus,
      };

      return await updateOrderStatus(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getOrders"]);
      setOpenStatus(-1);
    },
  });

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", selectedItemId],
    queryFn: async () => {
      const response = await getSingleItem(`${selectedItemId}`);
      return response.data;
    },
    enabled: !!selectedItemId,
    onSuccess: (data) => {
      setSelectedItem(data.Menu_Item);
    },
    onError: (err) => {},
  });

  const getStoreNotifications = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreNotifications"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadNotifications(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (getStoreNotifications.data) {
    if ("pages" in getStoreNotifications.data) {
      getStoreNotifications.data.pages.forEach((page) => {
        if ("Notification_Catalog" in page) {
          if ("Order_Notification" in page.Notification_Catalog) {
            orderNotifications = [
              ...orderNotifications,
              ...page.Notification_Catalog.Order_Notification,
            ];
            if (orderNotifications.length > 0) {
              queueIds = orderNotifications.map((n) => n.queueId);
            }
          }
        }
      });
    }
  }

  const handleClearNotification = useQuery<any, Error>({
    queryKey: ["clearNotifications", orderNotifications, queueIds],
    queryFn: async (data) => {
      const payload = {
        platformKey,
        notificationSubject: "Order_Notification",
        queueId: queueIds,
      };
      return await handleReadNotification(payload);
    },
    enabled: !!getStoreNotifications.data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getNotificationCount"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreNotifications"] });
    },
  });

  if (allOrders.data) {
    const nonCompletedOrders = allOrders.data.Order_Catalog.filter(
      (c: any) => c.orderStatus !== "Completed"
    );

    const nonCancelled = nonCompletedOrders.filter(
      (c: any) => c.orderStatus !== "Cancelled"
    );
    orders = allOrders.data.Order_Catalog;
    // console.log(orders);
  }

  return (
    <div>
      {allOrders.status === "loading" || handleOrderStatusUpdate.isLoading ? (
        <div className="flex items-center justify-start py-5">
          <ImSpinner3 size={18} className="mx-auto animate-spin fill-primary" />
        </div>
      ) : allOrders.status === "error" ? (
        <p className="mt-8 text-sm font-semibold text-center text-gray-400">
          {allOrders.error.message}
        </p>
      ) : orders.length > 0 ? (
        <div className="space-y-2.5">
          {orders?.map((order: any, index: number) => (
            <div className="relative p-2 border rounded" key={order.orderId}>
              <div className="flex gap-2 text-xs md:items-center sm:gap-4">
                <BiChevronUp
                  size={25}
                  className={classNames(
                    "cursor-pointer",
                    open === order.orderId ? "rotate-90" : "rotate-180"
                  )}
                  onClick={() => {
                    if (open === order.orderId) {
                      navigate(`/orders/${order.orderId}`);
                      setOpen(-1);
                      setOpenSingleItem(-1);
                      return;
                    }
                    setOpen(order.orderId);
                  }}
                />
                <div className="flex flex-col gap-0.5 md:flex-row md:items-center md:gap-4">
                  <p className="flex flex-col md:flex-row">
                    <span className="hidden md:inline">Name:&nbsp;</span>
                    <span className="font-semibold">
                      {order.billingDetail === null ||
                      order.billingDetail === undefined
                        ? "No Name"
                        : order?.billingDetail.fullName}
                    </span>
                  </p>
                  <p className="flex">
                    <span className="">Order Number:&nbsp;</span>
                    <span className="font-semibold">{order.orderNumber}</span>
                  </p>
                  <p className="flex text-xs font-medium">
                    <span className="hidden sm:inline">
                      &#8226;&nbsp;&nbsp;
                    </span>
                    <span className="text-[#575757] font-medium">
                      {getRelativeTime(order.creationTimestamp)}
                    </span>
                  </p>
                </div>
                <div className="relative ml-auto">
                  <button
                    className={classNames(
                      "rounded-md text-xs font-semibold  py-2 px-3 flex items-center gap-4 justify-between border bg-[#C4E8A9] text-[#35561D] border-[#35561D]"
                    )}
                  >
                    <span>Completed</span>
                    {/* <BiChevronDown size={25} /> */}
                  </button>
                </div>
              </div>
              {open === order.orderId && (
                <>
                  <div className="my-3 border border-b-0 border-gray-400 rounded ">
                    <div className="">
                      <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400 font-semibold">
                        <div className="flex col-span-6">
                          <p className="hidden sm:block w-[50px] text-[10px] md:text-xs text-center p-1.5">
                            S/N
                          </p>
                          <p className="text-[10px] md:text-xs w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400">
                            ITEMS
                          </p>
                        </div>
                        <p className="text-[10px] md:text-xs col-span-1 p-1.5 text-center">
                          QTY
                        </p>
                      </div>
                      {order?.products.map((orderItem: any, index: any) => (
                        <div className="relative" key={index}>
                          <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400">
                            <div className="flex col-span-6 text-xs">
                              <p className="hidden sm:block w-[50px] text-center p-1.5">
                                {index + 1}
                              </p>
                              <p className=" w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400 flex items-center font-medium gap-3">
                                <span className="">
                                  {orderItem.itemName.replace(/_/g, " ")}
                                </span>
                                <span
                                  className={classNames(
                                    orderItem.itemSize === "" ||
                                      orderItem.itemSize === undefined
                                      ? "hidden"
                                      : ""
                                  )}
                                >
                                  -&nbsp;&nbsp;{orderItem.itemSize}
                                </span>
                                <span
                                  className={classNames(
                                    orderItem.itemSize === "" ||
                                      orderItem.itemSize === undefined
                                      ? "hidden"
                                      : ""
                                  )}
                                >
                                  -&nbsp;&nbsp;{orderItem.itemColor}
                                </span>

                                {/* <span className="mr-4">
                                x{orderItem.quantityOrder}
                                </span> */}
                                <BiInfoCircle
                                  size={20}
                                  className={classNames(
                                    "cursor-pointer",
                                    selectedItemId === orderItem.itemId &&
                                      openSingleItem === orderItem.itemId
                                      ? "text-blue-600"
                                      : ""
                                  )}
                                  onClick={() => {
                                    if (!orderItem.itemId) {
                                      return;
                                    }
                                    setSelectedItemId(orderItem.itemId);
                                    setOpenSingleItem(orderItem.itemId);
                                  }}
                                />
                              </p>
                            </div>
                            <p className=" col-span-1 p-1.5 text-center text-xs">
                              {orderItem.quantityOrder}
                            </p>
                          </div>
                          {openSingleItem === orderItem.itemId && (
                            <div className="p-3 absolute w-[300px] md:w-[400px] border rounded-md z-10 bottom-8 bg-white left-[5%] md:left-[20%] mx-auto shadow-md ">
                              {loadSingleItem.status === "loading" ||
                              loadSingleItem.isRefetching ? (
                                <div className="flex items-center justify-start py-5">
                                  <ImSpinner3
                                    size={18}
                                    className="mx-auto animate-spin fill-primary"
                                  />
                                </div>
                              ) : loadSingleItem.status === "error" ? (
                                <div className="relative flex items-center justify-center py-5">
                                  <p className="text-center">Item not found</p>
                                  <p className="absolute top-0 right-2">
                                    <MdClear
                                      size={22}
                                      className="cursor-pointer hover:fill-blue-800"
                                      onClick={() => {
                                        setOpenSingleItem(-1);
                                        setSelectedItemId(null);
                                        queryClient.cancelQueries([
                                          "getSingleItem",
                                        ]);
                                      }}
                                    />
                                  </p>
                                </div>
                              ) : (
                                <>
                                  <div className="grid grid-cols-4 gap-2.5">
                                    <div className="rounded-md bg-gray-50">
                                      <img
                                        src={
                                          selectedItem?.s3ImageUrl
                                        }
                                        onError={(e) =>
                                          (e.currentTarget.src =
                                            "/media/image/productBg.png")
                                        }
                                        alt="item_image"
                                        className="w-full h-[75px] rounded-md bg-gray-50 object-contain"
                                      />
                                    </div>
                                    <div className="flex flex-col justify-between text-xs col-span-3 py-2.5">
                                      <div className="flex items-center justify-between gap-2 font-semibold">
                                        <p>{selectedItem?.itemName}</p>
                                        <p className="-mt-2.5 mr-1">
                                          <MdClear
                                            size={22}
                                            className="cursor-pointer hover:fill-blue-800"
                                            onClick={() => {
                                              setOpenSingleItem(-1);
                                              setSelectedItemId(null);
                                              queryClient.cancelQueries([
                                                "getSingleItem",
                                              ]);
                                            }}
                                          />
                                        </p>
                                      </div>
                                      <p className="">
                                        &#x20A6;{selectedItem?.itemPrice}
                                      </p>
                                      <p className="text-xs ">
                                        {selectedItem?.itemDescription}
                                      </p>
                                    </div>
                                    <span
                                      className="absolute hidden cursor-pointer top-1 right-1"
                                      onClick={() => {
                                        setOpenSingleItem(-1);
                                        setSelectedItemId(null);
                                      }}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                          fill="#5A5A5A"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {order.billingDetail === null ||
                  order.billingDetail === undefined ? (
                    <div className="space-y-1 text-xs">
                      <p className="flex items-center gap-0.5">
                        <span className="text-gray-800">Delivery Option: </span>
                        <span className="font-medium">Pickup</span>
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-1 text-xs">
                      <p className="flex items-center gap-0.5">
                        <span className="text-gray-800">Delivery Option: </span>
                        <span className="font-medium">Drop Off</span>
                      </p>
                      <div className="flex flex-col gap-1 md:items-center md:flex-row md:gap-16">
                        <p className="flex items-center gap-0.5">
                          <span className="text-gray-800">Phone: </span>
                          <span className="font-medium">
                            {order.billingDetail.phone}
                          </span>
                        </p>
                        <p className="flex items-center gap-0.5">
                          <span className="text-gray-800">Email: </span>
                          <span className="font-medium">
                            {order.billingDetail.email}
                          </span>
                        </p>
                        {order.billingDetail.address === undefined ? null : (
                          <p className="flex gap-0.5">
                            <span className="text-gray-800">Address: </span>
                            <span className="font-medium">
                              {order.billingDetail.address}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="mt-8 text-xs font-semibold text-center text-gray-800">
          No order have been placed
        </p>
      )}
    </div>
  );
};

export default CompletedOrders;
