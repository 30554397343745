//@ts-nocheck

import { useEffect, useState } from "react";
import {
  BiComment,
  BiSearch,
} from "react-icons/bi";
import { classNames } from "../../../../utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getReviewersHistory } from "../../../../api/Reviews";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { Link } from "react-router-dom";
import { ImSpinner3 } from "react-icons/im";
import Modal from "../../../../components/Dashboard/Modal";

const ReviewHistory = () => {
  let dateRange: string = "March - Present";
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const queryClient = useQueryClient();
  // const defaultTrigger = queryClient.getQueryData<any>(["getAttentionTrigger"])?.Attention_Variable;
  // let trigger: number = defaultTrigger ?? 0;
  let trigger: number = Number(user.attentionTrigger);
  let reviewArray: any = [];
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });

  // const attentionTrigger = useQuery<any, Error>({
  //   queryKey: ["getAttentionTrigger"],
  //   queryFn: async () => {
  //     const response = await getAttentionTrigger(queryKey);
  //     return response.data;
  //   },
  // });

  // if (attentionTrigger.data) {
  //   trigger = attentionTrigger.data.Attention_Variable;
  // }

  // if (attentionTrigger.status === "error") {
  //   trigger = 5;
  // }

  const getAttention = (negatives: number) => {
    let value;
    if (negatives === 0) {
      value = <p className="py-2 font-medium text-green-600">Good</p>;
    }
    if (negatives > 0 && negatives < trigger) {
      value = <p className="py-2 font-medium text-orange-500">Mild</p>;
    }
    if (negatives >= trigger) {
      value = <p className="py-2 font-medium text-red-500">Very Serious</p>;
    }
    return value;
  };

  const [filters, setFilters] = useState<{ from: string; to: string }>({ from: "", to: "" });

  const handleFilter = (e: any) => {
    e.preventDefault();
    if (e.target.value === "") {
      setFilters({...filters, from: "", to: "" });
      return;
    }

    setFilters({...filters, from: e.target.value, to: e.target.value });
  };

  const reviewHistory = useInfiniteQuery<any[], Error>({
    queryKey: ["getReviewersHistory", filters],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await getReviewersHistory(queryKey, paginationKey);
      return response.data;
    },
    select(data: any) {
      // console.log({data});

      let newResults: any[] = [];

      if ("pages" in data) {
        const customers =
          "Customers" in data.pages[0] ? data.pages[0].Customers : [];
        const result = customers[0];
        newResults = Object.keys(result).map((key) => result[key]);
        return newResults;
      }

      return newResults;
    },
    getNextPageParam: (lastPage: any, pages: any) => {
      // console.log({lastPage});
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  useEffect(() => {
    if (!filters) {
      return;
    }

    if (searchQuery.length < 3) {
      return;
    }
  }, [filters, searchQuery]);

  if (reviewHistory.data) {
    reviewArray = reviewHistory.data;

    if (searchQuery.length > 3) {
      reviewArray = reviewHistory.data.filter((review: any, index: number) =>
        review.customer_email.includes(searchQuery)
      );
    }

    // console.log({reviewArray});

    // dateRange = `${moment(reviewArray[0]?.uploadTime).format(
    //   "MMMM"
    // )} - ${moment(reviewArray[reviewArray.length - 1]?.uploadTime).format(
    //   "MMMM"
    // )}`;
  }

  return (
    <>
      <div className="flex bg-white rounded-md h-[85vh] p-3 space-y-5 sm:space-y-8 overflow-hidden flex-col">
        <h4 className="text-lg font-bold text-black">Review history</h4>
        <div className="flex items-center justify-between">
          <div className="relative w-full sm:w-3/4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                // if (e.target.value.length > 3) {
                setSearchQuery(e.target.value);
                // }
              }}
              placeholder="Search reviews, name, phone"
              className="w-full px-4 py-2 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={24}
              className="absolute top-2 right-4 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
          <div className="hidden sm:block">
            <button className="px-4 py-2 text-xs font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md lg:px-3 lg:py-2">
              {dateRange}
            </button>
          </div>
        </div>
        {reviewHistory.status === "loading" || reviewHistory.isRefetching ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : reviewHistory.status === "error" ? (
          <span>Error: {reviewHistory.error.message}</span>
        ) : reviewArray.length > 0 ? (
          <div className="relative flex-1 h-full overflow-x-auto overflow-y-auto">
            <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
              <thead className="text-gray-800 capitalize bg-gray-50">
                <tr className="text-xs text-center capitalize">
                  <th scope="col" className="py-3 sm:px-6">
                    Email
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Phone
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Tickets
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Reviews
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Attention
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell"></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {reviewArray.map((review: any, index: number) => (
                  <tr className={classNames("border-b")} key={index}>
                    <td scope="row" className="px-2 py-2 sm:px-6">
                      <div className="">
                        <p className="font-medium whitespace-nowrap">
                          {review.customer_email}
                        </p>
                      </div>
                    </td>
                    <td className="hidden px-6 py-2 border-x sm:table-cell">
                      <p className="text-xs text-center">
                        {review.customer_phone === "Null"
                          ? "No phone number"
                          : review.customer_phone}
                      </p>
                    </td>
                    <td className="px-2 py-2 sm:px-6 border-x">
                      <Link
                        to={review.ticket_count > 0 ? `/smart-feedback/direct-ticket/${review.customer_email}` : null}
                        className="block text-center underline underline-offset-2 text-primary"
                      >
                        {review.ticket_count ?? 0}
                      </Link>
                    </td>
                    <td className="px-2 py-2 border-x sm:px-6">
                      <Link
                        to={review.review_activity > 0 ? `/smart-feedback/customer-log/${review.customer_email}` : null}
                        className="block text-center underline underline-offset-2 text-primary"
                      >
                        {review.review_activity ?? 0}
                      </Link>
                    </td>
                    <td className="hidden px-6 py-2 text-center border-r sm:table-cell">
                    {getAttention(
                        review.metadata.label?.filter(
                          (label: any, index: number) => label === "NEG"
                        ).length ?? 0
                      )}
                    </td>
                    <td className="hidden px-6 py-2 border-x sm:table-cell">
                      <div className="flex items-center justify-between">
                        <div>
                          <Link
                            to={`/smart-feedback/messages/new-message/${review.customer_email}`}
                          >
                            <p className="text-center fill-gray-400 flex items-center space-x-1.5 capitalize">
                              <BiComment size={20} className="" />{" "}
                              <span className="hidden -mt-1 sm:inline-block">message</span>
                            </p>
                          </Link>
                        </div>
                        <BsThreeDotsVertical
                          size={22}
                          onClick={() => {
                            setModalObject({
                              email: review.customer_email,
                              phoneNumber:
                                review.customer_phone === "Null"
                                  ? "No phone number"
                                  : review.customer_phone,
                              tickets: review.ticket_count ?? 0,
                              reviews: review.review_activity ?? 0,
                            });
                            setShowModal(true);
                          }}
                          className="text-gray-600 cursor-pointer sm:hidden"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="mt-8 font-semibold text-center text-gray-400">
            {searchQuery === ""
              ? "You don't have any review yet"
              : "No review found"}
          </p>
        )}
        {reviewArray.length > 0 && (
          <div className="flex items-center justify-end space-x-2.5">
            <button
              onClick={() => reviewHistory.fetchPreviousPage()}
              disabled={
                !reviewHistory.hasPreviousPage ||
                reviewHistory.isFetchingPreviousPage
              }
              className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
            >
              prev
            </button>
            <button
              onClick={() => reviewHistory.fetchNextPage()}
              disabled={
                !reviewHistory.hasNextPage || reviewHistory.isFetchingNextPage
              }
              className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
            >
              next
            </button>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal((prevState) => !prevState);
        }}
      >
        <div className="relative flex flex-col p-8 space-y-4 text-xs text-black sm:py-12 sm:px-8 sm:space-y-6">
          <div>
            <p className="text-lg font-semibold">Email</p>
            <p>{modalObject.email}</p>
          </div>
          <div className="">
            <p className="text-lg font-semibold">Phone</p>
            <p>{modalObject.phoneNumber}</p>
          </div>
          <div className="flex items-center space-x-10">
            <div>
              <p className="text-lg font-semibold">Tickets</p>
              <Link
                to={modalObject.tickets > 0 ? `/smart-feedback/direct-ticket/${modalObject.email}` : null}
              >
                <p className="text-center underline underline-offset-2 text-primary">
                  {modalObject.tickets ?? 0}
                </p>
              </Link>
            </div>
            <div>
              <p className="text-lg font-semibold">Reviews</p>
              <Link to={modalObject.reviews > 0 ? `/smart-feedback/customer-log/${modalObject.email}` : null}>
                <p className="text-center underline underline-offset-2 text-primary">
                  {modalObject.reviews ?? 0}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReviewHistory;
