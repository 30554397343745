import React from "react";
import { IoLogoLinkedin } from "react-icons/io";
import { MdOutlineSchedule } from "react-icons/md";

const CampaignManagerBody = () => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className="border border-gray-100 shadow-sm p-2.5 rounded-md space-y-1.5">
      <div className="text-sm flex justify-between">
        <div className="text-green-700 flex items-center gap-0.5">
          <MdOutlineSchedule size={18} className="text-gray-600" />
          <p className="">12:30</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-[#8F8F8F] text-xs">11/10/2024</p>
          {/* <EllipsisVertical
            size={18}
            style={{ marginTop: "2px" }}
            onClick={() => {
              setShowModal(true);
            }}
          /> */}
        </div>
      </div>
      <div className="font-semibold text-gray-700">
        <div>
          <p className="font-semibold text-[13px]">@Cusecho</p>
        </div>
      </div>
      <div>
        <p className="text-xs text-gray-700 font-medium leading-[18px]">
          We are a travels and transport logistics technology provider,
          aggregating logistics companies and their assets into a single
          platform where customers and logistics companies can transact
          business...
        </p>
      </div>
      <div className="flex gap-2">
        <img
          src="/media/image/defaultStoreHeroImage.jpg"
          alt="image"
          className="object-cover rounded h-[50px] w-[50px]"
        />
        <img
          src="/media/image/defaultStoreHeroImage.jpg"
          alt="image"
          className="object-cover rounded h-[50px] w-[50px]"
        />
      </div>
      <div>
        <div className="flex justify-between mt-2">
          <button className="bg-neutral-100 text-gray-600 text-xs font-medium rounded p-2 px-4">
            Scheduled
          </button>
          <IoLogoLinkedin className="text-blue-600" size={22} />
        </div>
      </div>
    </div>
  );
};

const TiktokCampaignManager = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {Array.from({ length: 6 }).map((_, index) => (
        <CampaignManagerBody key={index} />
      ))}
    </div>
  );
};

export default TiktokCampaignManager;
